 import React from 'react';
import { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useModal } from '../../../hooks/useModal';
import Popup from '../../../components/Modals/Popup';
import { getRoles, deleteRole } from '../../../axios/role';
import { getStations } from '../../../axios/station';
import { getLocations } from '../../../axios/location';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import './Roles.css'
import { notify } from '../../../utils/notify';
import { Image } from 'react-bootstrap';
import RoleDetail from './RoleDetail/RoleDetail'
import Pencil from '../../../assets/NavBar Icons/Pencil.svg'
import TrashCan from '../../../assets/NavBar Icons/TrashCan.svg'

const Roles = (props) => {
    const [rolesList, setRolesList] = useState([])
    const [stationList, setStationList] = useState([])
    const [locationList, setLocationList] = useState([])
	const [isOpenModal, openModal, closeModal, data] = useModal()
	const [isLoading, setIsLoading] = useState(false);
	const [currentItem, setCurrentItem] = useState({role:[],action:''});
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const { userType, locationId, businessId } = JSON.parse(localStorage.getItem('user'));

	useEffect(() => {
		getData()
	}, [])
	
	useEffect(() => {
		props.popupOpen(isPopupOpen)
	}, [isPopupOpen])

	const getData = async () => {
		setIsLoading(true)
		await Promise.all([_getRoles(), _getLocations(), _getStations()])
			.then(() => setIsLoading(false))
			.catch(error => {
				notify('Unable to read data from database', 'error')
				console.log(error)
				setIsLoading(false)
			})
	}

	const _getRoles = async () => {
		const response = await getRoles({ userType, locationId, businessId })
		setRolesList(response)
	}
	
    const _getLocations = async () => {
		const response = await getLocations({ businessId });
		
		const locations = {}
		for (const key in response) {
			locations[response[key].id] = response[key].name + ' ';
		}

		setLocationList(locations);
	}
	
    const _getStations = async () => {
        const response = await getStations({ locationId });

        const stations = {}
        for (const key in response) {
            stations[response[key].id] = response[key].name + ' ';
        }

        setStationList(stations);
    }

	const handleEditRole = item => {
		setCurrentItem(item)
		setIsPopupOpen(true)
	}

	const _deleteRole = async data => {
		await deleteRole(data).then(() => {
			closeModal()
			_getRoles();
			notify('Role data deleted successfully', 'success')
		}).catch(error => {
			notify('Error deleting product role data', 'error')
			console.log(error)
		})
	}

    return (
		<>
			<div className="main">
				<Popup
					isOpen={isOpenModal}
					title='Delete role'
					label='Are you sure do you want to delete'
					onCancel={closeModal}
					cancelLabel='Cancel'
					onAccept={() => _deleteRole(data)}
					acceptLabel='Accept'
				/>
				<LoadingSpinner
					isLoading={isLoading}
					label='Loading roles...'
				/>
				{currentItem && isPopupOpen && <RoleDetail
					handleClosePopup={() => setIsPopupOpen(false)}
					role={currentItem.role}
					action={currentItem.action}
					userType={userType}
					isMenuCollapsed={props.isMenuCollapsed}
					response={getData}
				/>}
				<div className="row-header">
					<div>
						<header>Roles</header>
					</div>
					<div>
						<BsPlusCircleFill
							className='plus-icon color-cloche-red'
							onClick={() => handleEditRole({ role: { status: true, businessId, locationId }, action: 'create' })}
						/>
					</div>
				</div>
				<div className='header'>
					<div className='role-name'>NAME</div>
					<div className='role-description'>DESCRIPTION</div>
					<div className='role-location'>LOCATION</div>
					<div className='role-station'>STATIONS</div>
					<div className='role-actions'>ACTIONS</div>
				</div>
				<div className="crud">
					{rolesList.map(role => (
						<div className="crud-row" key={role.id}>
							<div className="role-name">{role.name}</div>
							<div className="role-description">{role.description}</div>
							<div className="role-location">{locationList[role.locationId]}</div>
                            <div className="role-station">{role.station?.map(station => stationList[station])}</div>
							<div className="role-actions">
								<Image
									className='action-icon'
									src={Pencil}
									onClick={() => handleEditRole({role, action:'edit'})}
								/>
								<Image
									className='action-icon color-cloche-red'
									src={TrashCan}
									onClick={() => openModal({ id: role.id })}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Roles;
