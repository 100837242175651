import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import { setOrderOverviewModalState } from '../../Redux/slices/ModalsSlices';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GlobalColors } from '../../assets/GlobalColors';
import  customImages  from '../../utils/customImages'
import { selectIsFoodTruck } from '../../Redux/slices/ProductsSlices';
const OrderOverviewModal = (props) => {
  const dispatch = useDispatch();
  const isMobileS = useMediaQuery('(max-width: 320px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptopL = useMediaQuery('(max-width: 1440px)');
  const isFoodTruck = useSelector(selectIsFoodTruck)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setOrderOverviewModalState(false));
    }, 1500);
    return () => clearTimeout(timer);
  }, [dispatch]);

  return (
    <Modal
      className="thanks-order"
      {...props}
      size={
          isMobileM || isMobileL || isTablet
          ? 'lg'
          : 'sm'
      }
      centered
    >
      <div
        style={{
          height: isMobileS || isMobileM || isLaptopL
            ? '250px'
            : '300px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          borderRadius: '30px',
          backgroundColor: GlobalColors.GRAY_MODAL_BACKGROUND,
       
        }}
      >
        
        
  
        <div
          style={{
            fontSize: '22px',
          }}
          className="text-title"
        >
          Thank you for your order!
        </div>
          <Image 
            src={customImages.clocheYourOrder} 
            style={{
              width: '30%',
            }} 
          />
        <div
          style={{
            fontSize: 'px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
         { !isFoodTruck && <div className="text-normal" style={{fontSize: '16px'}}>Your order has been sent <br/>&nbsp; &nbsp; and is being prepared </div> }
          { isFoodTruck && <div className="text-normal">Proceed to Checkout to fulfill your order</div> }
       
          <img className="mt-3 mb-0" src={customImages.clocheLogoTip} alt="clocheLogo" style={{width: '45%'}}/>
        
        </div>
      </div>
    </Modal>
  );
};

export default OrderOverviewModal;
