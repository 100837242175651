import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import TableContainer from '../../../components/KitchenHome/TableContainer';
import {
  selectNumberOfOrdersToDo,
  setKitchenOrders,
  toggleTableAssistance,
  addEmptyTable,
  setKitchenOrdersByBoard,
  selectKitchenOrdersByBoard
} from '../../../Redux/slices/KitchenSlices';
import { getActiveOrders } from '../../../axios/order';
import { getAssistanceUpdates } from '../../../axios/table';
const BoardComponent = () => {
  const [orders, setOrders] = useState([]);
  const stationList = JSON.parse(localStorage.getItem('stationList'))
  const dispatch = useDispatch();
  const ordersToDo = useSelector(selectKitchenOrdersByBoard);
  let ordersAux = [];
  const numberOfOrdersToDo = useSelector(selectNumberOfOrdersToDo);
  let activeOrders;
  const MINUTE_MS = 15 * 1000;
  const { locationId } = JSON.parse(localStorage.getItem('user'));
  const { stationType } = JSON.parse(localStorage.getItem('station')) ?? { stationType: undefined };
  const hasBar = localStorage.getItem('hasBar') == 'true' ? true : false
  const isAlreadyPresent = (idToEvaluate) => {
    orders.forEach((table) => {
      table.tableOrders.forEach((order) => {
        if (idToEvaluate === order.id) return true;
      });
    });
    return false;
  };
  const isItemInCorrectBoard = (type, isFoodTruck, stationType, stationList) => {
    if (stationType === 'service') return true;
    if (stationType === 'kitchen' && (type === 'plates' || (isFoodTruck && !stationList.find(item => item.type === 'bar')))) return true;
    if (stationType === 'bar' && type === 'drinks') return true;

    return false;
  }

  const setBoardOrders = (tables) => {
    let filteredByBoard = [];
    tables.forEach(table => {
      let newTableOrders = [];
      table.tableOrders.forEach(tableOrder => {
        let newItems = []
        tableOrder.items.forEach(item => {
          if (isItemInCorrectBoard(item.type, tableOrder.isFoodTruck, stationType, stationList)) newItems.push(item);
        })
        if (newItems.length) {
          let tableOrderAux = { ...tableOrder }
          tableOrderAux.items = newItems;
          newTableOrders.push(tableOrderAux)
        }
      })
      if (newTableOrders.length) {
        let tableAux = { ...table }
        tableAux.tableOrders = newTableOrders;
        filteredByBoard.push(tableAux);
      }
    })
    dispatch(setKitchenOrdersByBoard(filteredByBoard));
  }

  const getOrders = async () => {
    ordersAux = [];
    await getActiveOrders({ boardLogic: stationType, locationId, hasBar }).then((result) => {
      activeOrders = result;
      activeOrders.forEach((order) => {
        if (!isAlreadyPresent(order.id)) {
          let index = ordersAux.findIndex((element) => element.tableNumber === order.idTable);
          let newOrder = {
            locationId: order.locationId,
            clientName: order.clientName || '',
            isFoodTruck: order.isFoodTruck,
            done: order.done || false,
            served: false,
            notes: order.notes || ' ',
            items: order.items?.map((item) => {
              return { ...item, redux_itemId: uuid() };
            }),
            timestamp: order.timestamp || order._ts,
            idTable: order.idTable,
            id: order.id,
            needsAssistance: false,
            orderNumber: order.orderNumber
          };
          if (index === -1) {
            let tableAux = {
              tableNumber: order.idTable,
              timestamp: 0,
              tableOrders: [],
            };
            ordersAux.push(tableAux);
            ordersAux[ordersAux.length - 1].tableOrders.push(newOrder);
          } else {
            ordersAux[index].tableOrders.push(newOrder);
          }
        }
      });
      setOrders(ordersAux);
      dispatch(setKitchenOrders(ordersAux));
      setBoardOrders(ordersAux)
    });
  };

  const checkAssistanceUpdates = async () => {
    await getAssistanceUpdates({ locationId: locationId }).then((result) => {
      if (result.length > 0) {
        result.forEach((table) => {
          let index = ordersAux.findIndex((orderTable) => orderTable.tableNumber == table);
          if (index === -1) {
            dispatch(addEmptyTable({ tableNum: table }))
          } else {
            dispatch(toggleTableAssistance({ index: index }))
          }
        });
      }
    });
  };


  useEffect(() => {
    const interval = setInterval(() => {
      getOrders().then((res) => checkAssistanceUpdates());
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [MINUTE_MS, getOrders, checkAssistanceUpdates]);

  useEffect(() => {
    getOrders().then((res) => checkAssistanceUpdates());
  }, [])


  return (
    <>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          height: '100%',
          width: 'auto',
        }}
      >
        <div
          className="text-title"
          style={{
            width: '100%',
            height: '10%',
            fontSize: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div> {stationType?.charAt(0).toUpperCase() + stationType?.slice(1)} Board</div>
            {stationType === "kitchen" &&
              <div className="text-subtitle"
                style={{
                  fontSize: '18px',
                }}>Orders to fulfill ({numberOfOrdersToDo})</div>
            }
          </div>
        </div>

        <div
          style={{
            width: '90%',
            height: '90%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '15px',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
          }}
        >
          {ordersToDo && ordersToDo.length > 0 && ordersToDo.some(orderToDo =>
            orderToDo.tableOrders.some(order => order.isFoodTruck)
          ) ? (
            ordersToDo.map((orderToDo, idx) => {
              return orderToDo.tableOrders.map((order, innerIdx) => {
                return <TableContainer key={innerIdx} orderToDo={order} isFoodTruck={true} />;
              });
            })
          ) : (
            ordersToDo.map((orderToDo, idx) => {
              return <TableContainer key={idx} orderToDo={orderToDo} isFoodTruck={false} />;
            })
          )}
        </div>

      </div>
    </>
  );
};

export default BoardComponent;
