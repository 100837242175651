import React from 'react';
import '../../../components/Modals/Modals.css'
import './OrderDetails.css'
import ItemList from './ItemList';

const OrderDetails = ({ selectedOrder, onClose, backgroundColor, color, formatDate, formatTimeToDo, formatMoney }) => {
    return (
        <div className="isLoading" style={{zIndex: '1010'}} onClick={onClose}>
            <div className="details_dialog" onClick={(e) => e.stopPropagation()}>
                <div className="restaurantInfo">
                    <div className="restaurantName">{selectedOrder.businessName}</div>
                    <div className="closeIcon" onClick={onClose}>
                    </div>
                </div>
                <div style={{ width: '100%', height: '3%', borderTop: '1px #C4C4C4 solid' }}></div>
                <div className="order-info-container">
                    <div className="order-header">
                        <div>
                            <span className="order-title">Order {selectedOrder.orderNumber} </span>
                            <span>({selectedOrder.itemsCount} {selectedOrder.itemsCount === 1 ? 'item' : 'items'})</span>
                        </div>
                        <div style={{
                            backgroundColor: backgroundColor,
                            color: color,
                        }} className="order-status">{selectedOrder.status}</div>
                    </div>
                    <div className="order-details">
                        <div className="order-subtitle">•Table: {selectedOrder.table}</div>
                        <div className="order-subtitle">•Date: {formatDate(selectedOrder.fullfillDate)}</div>
                        <div className="order-subtitle">•Service: {formatTimeToDo(selectedOrder.timeToDo)} min</div>
                        
                    </div>
                    <div className="order-details">
                       
                        <div className="order-subtitle">•Name: {selectedOrder.clientName}</div>
                        <div className="order-subtitle">•Email: {selectedOrder.clientEmail}</div>
                        <div className="order-subtitle">•Phone: {selectedOrder.clientNumber}</div>
                    </div>
                </div>
                <div className="order-section-container">
                    <div className="order-section">
                        <div className="section-header">
                            <div className="section-title">Food</div>
                        </div>
                        <ItemList items={selectedOrder.foodItems} />
                    </div>
                    <div className="order-section">
                        <div className="section-header">
                            <div className="section-title">Drinks</div>
                        </div>
                        <ItemList items={selectedOrder.drinkItems} />
                    </div>
                </div>
                <div className="summary-container">
                    <div className="summary-item">
                        <div className="summary-title">Subtotal</div>
                        <div className="summary-value">{formatMoney(selectedOrder.subTotalAmount)}</div>
                    </div>
                    <div className="summary-item">
                        <div className="summary-title">Tax ({selectedOrder.taxesPercentage}%)</div>
                        <div className="summary-value">{formatMoney(selectedOrder.taxAmount)}</div>
                    </div>
                    <div className="summary-item">
                        <div className="summary-title">Service charge ({selectedOrder.serviceChargePercentage}%)</div>
                        <div className="summary-value">{formatMoney(selectedOrder.serviceChargeAmount)}</div>
                    </div>
                    <div className="summary-item">
                        <div className="summary-title">Server tip ({selectedOrder.tipPercentage}%)</div>
                        <div className="tip-container">
                            <div className="summary-value">{formatMoney(selectedOrder.tipAmount)}</div>
                        </div>
                    </div>
                    <div className="summary-item summary-item-no-border">
                        <div className="summary-title summary-value-bold">Total</div>
                        <div className="summary-value">{formatMoney(selectedOrder.chargeAmount)}</div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default OrderDetails;
