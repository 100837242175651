import React from 'react';
import { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useModal } from '../../../hooks/useModal';
import Popup from '../../../components/Modals/Popup';
import { getUsers, deleteUser } from '../../../axios/user';
import { getRoles } from '../../../axios/role';
import { getLocations } from '../../../axios/location';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import './User.css'
import { notify } from '../../../utils/notify';
import { Image } from 'react-bootstrap';
import UserIcon from '../../../assets/BoardIcons/User.svg'
import Mail from '../../../assets/BoardIcons/Mail.svg'
import UserDetail from './UserDetail/UserDetail';
import Pencil from '../../../assets/NavBar Icons/Pencil.svg'
import TrashCan from '../../../assets/NavBar Icons/TrashCan.svg'

const User = (props) => {
	const [userList, setUserList] = useState([])
	const [roleList, setRoleList] = useState([])
	const [locationList, setLocationList] = useState([])
	const [isOpenModal, openModal, closeModal, data] = useModal()
	const [isLoading, setIsLoading] = useState(false);
	const [currentItem, setCurrentItem] = useState({user:[], action:''});
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const { userType, businessId, locationId } = JSON.parse(localStorage.getItem('user'));

	useEffect(() => {
		getData()
	}, [])

	useEffect(() => {
		props.popupOpen(isPopupOpen)
	}, [isPopupOpen])

	const getData = async () => {
		setIsLoading(true)
		await Promise.all([_getUsers(), _getRoles(), _getLocations()])
			.then(() => {
				setIsLoading(false)
			}).catch(error => {
				notify('Unable to read data from database', 'error')
				console.log(error)
				setIsLoading(false)
			})
	}

	const _getUsers = async () => {
		const response = await getUsers({ userType, locationId, businessId });
		setUserList(response);
	};

	const _getRoles = async () => {
		const response = await getRoles({ userType, businessId, locationId });
		const roles = {};
		for (const key in response) {
			roles[response[key].id] = response[key].name + ' ';
		}
		setRoleList(roles);
	};

	const _getLocations = async () => {
		const response = await getLocations({ businessId });

		const locations = {};
		for (const key in response) {
			locations[response[key].id] = response[key].name + ' ';
		}
		setLocationList(locations);
	};

	const handleEditUser = item => {
		setCurrentItem(item)
		setIsPopupOpen(true)
	}

	const _deleteUser = async _data => {
		deleteUser(_data).then(() => {
			closeModal()
			_getUsers();
			notify('User data deleted successfully', 'success')
		}).catch(error => {
			notify('Error deleting user data', 'error')
			console.log(error)
		})
	}

	return (
		<>
			<div className='main'>
				<Popup
					isOpen={isOpenModal}
					title='Delete user'
					label='Are you sure do you want to delete'
					onCancel={closeModal}
					cancelLabel='Cancel'
					onAccept={() => _deleteUser(data)}
					acceptLabel='Accept'
				/>
				<LoadingSpinner
					isLoading={isLoading}
					label='Loading users...'
				/>
				{currentItem && isPopupOpen && <UserDetail
					handleClosePopup={() => setIsPopupOpen(false)}
					user={currentItem.user}
					action={currentItem.action}
					userType={userType}
					isMenuCollapsed={props.isMenuCollapsed}
					response={getData}
				/>}
				<div className="row-header">
					<div>
						<header>Users</header>
					</div>
					<div>
						<BsPlusCircleFill
							className='plus-icon color-cloche-red'
							onClick={() => handleEditUser({ user: { status: true, businessId, locationId }, action: 'create' })}
						/>
					</div>
				</div>
				<div className='header'>
					<div className='user-name'>NAME</div>
					<div className='user-username'>USERNAME</div>
					<div className='user-role'>ROLE</div>
					<div className='user-location'>LOCATION</div>
					<div className='user-status'>STATUS</div>
					<div className='user-actions'>ACTIONS</div>
				</div>
				<div className='crud'>
					{userList.map(user => (
						<div className='crud-row' key={user.id}>
							<div className='user-name'>
								<Image src={UserIcon} className='crud-icon' />
								{user.name}
							</div>
							<div className='user-username'>
								<Image src={Mail} className='crud-icon' />
								{user.username}
							</div>
							<div className='user-role'>{roleList[user.roleId]}</div>
							<div className='user-location'>{locationList[user.locationId]}</div>
							<div className={`user-status ${!user.status && 'color-cloche-red'}`}>{user.status ? 'ACTIVE' : 'INACTIVE'}</div>
							<div className='user-actions'>
								<Image
									className='action-icon'
									src={Pencil}
									onClick={() => handleEditUser({user, action:'edit'})}
								/>
								<Image
									className='action-icon color-cloche-red'
									src={TrashCan}
									onClick={() => openModal({ id: user.id })}
								/>
							</div>
						</div>
					))}

				</div>
			</div>
		</>
	);
};

export default User;
