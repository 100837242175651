import './Modals.css'

const Popup = ({
  isOpen,
  onAccept,
  acceptLabel,
  onCancel,
  cancelLabel,
  title,
  label,
  children,
  image,
}) => {
  const handleModalDialogClick = e => {
    e.stopPropagation()
  }
  return (
    <div className={`popup ${isOpen && 'popup-open'}`} onClick={onCancel}>
      <div className='popup_dialog' onClick={handleModalDialogClick}>
        <div className='popup-title'>
          <h3>{title}</h3>
        </div>
        <div className='popup-input'>{label}</div>

        {children}
        <div className='popup-input'>
          {cancelLabel ? (
            <button 
              className='btn-sm btn-cancel' 
              onClick={onCancel}>
              {cancelLabel}
            </button>
          ) : (
            <></>
          )}
          <button 
            className='btn-sm btn-confirm'
            onClick={onAccept}>
            {acceptLabel}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Popup
