import React from 'react';
import { useEffect, useState } from 'react'
import { addBusiness, updateBusiness, uploadCoverImage, deleteBusiness } from '../../../../axios/business';
import LoadingSpinner from '../../../../components/Modals/LoadingSpinner';
import DragAndDrop from '../../../../components/General/DragAndDrop';
import PhoneInput from 'react-phone-number-input'
import { REACT_APP_API_URL } from '../../../../utils/envConfig';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { notify } from '../../../../utils/notify';

const BusinessDetail = (props) => {
	const {
		handleClosePopup,
		business,
		action,
		fullScreen,
		isCollapsed,
		isMenuCollapsed,
		response,
	} = props
	const [newBusiness, setNewBusiness] = useState({
		name: '',
		country: '',
		state: '',
		address: '',
		city: '',
		postalCode: '',
		ownerName: '',
		ownerLastName: '',
		phoneNumber: ''
	})
	const [coverImage, setCoverImage] = useState([])
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setNewBusiness({ ...newBusiness, ...business })
		getCoverImage()
	}, [])

	const handleBusinessData = async ({ event, field }) => {
		const exception = ['country', 'state', 'phoneNumber']
		let value = exception.includes(field) ? event : event.target.value
		if (field === 'postalCode') {
			value = (event.target.validity.valid && value >= 0)
				? parseInt(value) || ''
				: newBusiness.postalCode
		}
		setNewBusiness({
			...newBusiness,
			[field]: value,
		});
	}

	const getCoverImage = async () => {
		const lastIndex = REACT_APP_API_URL.lastIndexOf('cloche-');
		const env = REACT_APP_API_URL.substring(lastIndex).replace('-', 'web').replace('testing', 'testin')
		const imageUrl = `https://${env}.blob.core.windows.net/${business.id}/coverImage`;

		const imageExtensions = ['.png'];
		for (const extension of imageExtensions) {
			const imageWithExtension = `${imageUrl}${extension}`;
			if (await imageExists(imageWithExtension)) {
				setCoverImage([imageWithExtension, false])
				break;
			}
		}
	}

	const imageExists = async url => {
		try {
			const res = await fetch(url, { method: 'HEAD' });
			return res.status === 200 ? true : false
		} catch (error) {
			console.log(error)
			return false;
		}
	}

	const validateData = async () => {
		if (Object.values(newBusiness).some(x => x === '')) {
			notify('Please fill all fields', 'warning')
			return;
		}
		return true
	};

	const saveBusiness = async () => {
		if (!(await validateData())) {
			return;
		}

		setIsLoading(true)
		try {
			if (newBusiness.status == 'Cancelled') {
				await deleteBusiness({ id: newBusiness.id });
			} else {
				const businessSaved = await (action === 'create' ? addBusiness : updateBusiness)(newBusiness)
				if (coverImage[1]) await uploadCoverImage({ businessId: businessSaved.id, coverImage: coverImage[0] })
			}
			if (fullScreen) localStorage.setItem('myBusiness', JSON.stringify(newBusiness));
			else {
				response()
				handleClosePopup()
			}
			notify(`Business ${action === 'create' ? 'created' : 'edited'} successfully`, 'success')
			setIsLoading(false)
		} catch (error) {
			notify('Error saving data', 'error')
			console.log(error)
			setIsLoading(false)
		}
	}

	return (
		<>
			<LoadingSpinner
				isLoading={isLoading}
				label='Saving business...'
			/>
			<div className='pop-up-modal'>
				<div className={`${(isCollapsed || isMenuCollapsed) && 'collapsed'} ${fullScreen ? 'full-screen-container' : 'pop-up-container'}`}>
					<div className="pop-up-header">{action === 'create' ? 'New Business' : 'Edit Business'}</div>
					<div className="pop-up-row-wrap">
						<div className="pop-up-col">
							<div className="pop-up-row">Name</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newBusiness.name}
									onChange={event => handleBusinessData({ event, field: 'name' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">Country</div>
							<div className="pop-up-row">
								<CountryDropdown
									className='input'
									value={newBusiness.country}
									onChange={event =>
										handleBusinessData({ event, field: 'country' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">State/Province</div>
							<div className="pop-up-row">
								<RegionDropdown
									className='input'
									value={newBusiness.state}
									country={newBusiness.country}
									onChange={event =>
										handleBusinessData({ event, field: 'state' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">Address</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newBusiness.address}
									onChange={event => handleBusinessData({ event, field: 'address' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">City</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newBusiness.city}
									onChange={event => handleBusinessData({ event, field: 'city' })}
								/>
							</div>
						</div>
						<div className="pop-up-col" style={{ width: '300px' }}>
							<div className="pop-up-row">ZIP/Postal Code</div>
							<div className="pop-up-row">
								<input
									className='input-sm'
									pattern='[0-9]{0,13}'
									maxLength='9'
									value={newBusiness.postalCode}
									onChange={event => handleBusinessData({ event, field: 'postalCode' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">Owner Name</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newBusiness.ownerName}
									onChange={event => handleBusinessData({ event, field: 'ownerName' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">Owner Last Name</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newBusiness.ownerLastName}
									onChange={event => handleBusinessData({ event, field: 'ownerLastName' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">Phone Number</div>
							<div className="pop-up-row">
								<PhoneInput
									className='phoneInput'
									value={newBusiness.phoneNumber}
									defaultCountry="US"
									international={true}
									limitMaxLength="10"
									placeholder='Number'
									onChange={event =>
										handleBusinessData({ event, field: 'phoneNumber' })
									}
								/>
							</div>
						</div>
						{business.status == 'Pending' &&
							<div className="pop-up-col">
								<div className="pop-up-row">Status</div>
								<div className="pop-up-row">
									<select
										className='input'
										value={newBusiness.status}
										onChange={event => handleBusinessData({ event, field: 'status' })}
									>
										<option value="Pending">Pending</option>
										<option value="Approved">Approved</option>
										<option value="Cancelled">Cancelled</option>
									</select>
								</div>
							</div>
						}
					</div>
					<div className="pop-up-row-wrap" style={{ justifyContent: 'center' }}>
						<div className="pop-up-col">
							<div className="pop-up-row">Cover Image</div>
							<div className="pop-up-row">
								<DragAndDrop
									width='620px'
									height='174.5px'
									handlerSetter={setCoverImage}
									image={coverImage[0] || ''}
								/>
							</div>

						</div>
					</div>
					<br />
					<div className={fullScreen ? "full-screen-button" : "pop-up-row-wrap justify-space-between"}>
						<div className="pop-up-col">
							{!fullScreen &&
								<button
									className='btn-lg btn-cancel'
									onClick={handleClosePopup}>
									Cancel
								</button>
							}
						</div>
						<div className="pop-up-col">
							<button
								className='btn-lg btn-confirm'
								onClick={saveBusiness}>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default BusinessDetail;
