import React, { useState, useEffect } from 'react';
import { updateUnsubscribeFlag } from '../../axios/order';
import { useParams } from 'react-router-dom';


function Unsubscribe() {
    const [isUnsubscribed, setIsUnsubscribed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { id } = useParams();

    useEffect(() => {


        if (!id) {
            setErrorMessage('Invalid unsubscribe link.');
            return;
        }

        updateUnsubscribeFlag({ id })
            .then(() => {
                setIsUnsubscribed(true);
            })
            .catch((error) => {
                setErrorMessage(error.message || 'An error occurred while unsubscribing. Please try again later.');
            });
    }, []);

    return (
        <div className="unsubscribe-container">
            {isUnsubscribed ? (
                <div className="success-message">
                    You have been successfully unsubscribed. You can now close this page.
                </div>
            ) : (
                <div className="error-message">{errorMessage}</div>
            )}
        </div>
    );
}

export default Unsubscribe;
