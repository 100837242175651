import React from 'react';
import { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useModal } from '../../../hooks/useModal';
import Popup from '../../../components/Modals/Popup';
import { getStations } from '../../../axios/station';
import { deleteStation } from '../../../axios/station';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import './Station.css'
import { notify } from '../../../utils/notify';
import { Image } from 'react-bootstrap';
import StationDetail from './StationDetail/StationDetail'
import Pencil from '../../../assets/NavBar Icons/Pencil.svg'
import TrashCan from '../../../assets/NavBar Icons/TrashCan.svg'
import Bar from '../../../assets/BoardIcons/Bar.svg'
import Kitchen from '../../../assets/BoardIcons/Kitchen.svg'

const Station = (props) => {
	const [stationList, setStationList] = useState([])
	const [isOpenModal, openModal, closeModal, data] = useModal()
	const [isLoading, setIsLoading] = useState(false);
	const [currentItem, setCurrentItem] = useState({ station: [], action: '' });
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const { locationId } = JSON.parse(localStorage.getItem('user'));

	useEffect(() =>  {
		_getStations();
	},[])

	useEffect(() => {
		props.popupOpen(isPopupOpen)
	}, [isPopupOpen])

	const _getStations = async () => {
		setIsLoading(true)
		await getStations({ locationId }).then(response => {
			setStationList(response);
			setIsLoading(false)
		}).catch(error => {
			notify('Unable to read data from database', 'error')
			console.log(error)
			setIsLoading(false)
		})
	};

	const handleEditStation = item => {
		setCurrentItem(item)
		setIsPopupOpen(true)
	}

	const _deleteStation = async _data => {
		deleteStation(_data).then(() => {
			closeModal()
			_getStations();
			notify('Station data deleted successfully', 'success')
		}).catch(error => {
			notify('Error deleting station data', 'error')
			console.log(error)
		})
	}

	return (
		<>
			<div className='main'>
				<Popup
					isOpen={isOpenModal}
					title='Delete Station'
					label='Are you sure do you want to delete'
					onCancel={closeModal}
					cancelLabel='Cancel'
					onAccept={() => _deleteStation(data)}
					acceptLabel='Accept'
				/>
				<LoadingSpinner
					isLoading={isLoading}
					label='Loading stations...'
				/>
				{currentItem && isPopupOpen && <StationDetail
					handleClosePopup={() => setIsPopupOpen(false)}
					station={currentItem.station}
					action={currentItem.action}
					isMenuCollapsed={props.isMenuCollapsed}
					response={_getStations}
				/>}
				<div className="row-header">
					<div>
						<header>Stations</header>
					</div>
					<div>
						<BsPlusCircleFill
							className='plus-icon color-cloche-red'
							onClick={() => handleEditStation({ station: { status: true, locationId }, action: 'create' })}
						/>
					</div>
				</div>
				<div className='header'>
					<div className='station-name'>NAME</div>
					<div className='station-type'>TYPE</div>
					<div className='station-status'>STATUS</div>
					<div className='station-actions'>ACTIONS</div>
				</div>
				<div className='crud'>
					{stationList.map(station => (
						<div
							className='crud-row' key={station.id}>
							<div className='station-name'>{station.name}</div>
							<div className='station-type'>
								<Image
									src={station.type === 'kitchen' ? Kitchen : station.type === 'bar' ? Bar : ''}
									className='crud-icon'
								/>
								{station.type.charAt(0).toUpperCase() + station.type?.slice(1)}
							</div>
							<div className={`station-status ${!station.status && 'color-cloche-red'}`}>{station.status ? 'ACTIVE' : 'INACTIVE'}</div>
							<div className='station-actions'>
								<Image
									className='action-icon'
									src={Pencil}
									onClick={() => handleEditStation({ station, action: 'edit' })}
								/>
								<Image
									className='action-icon color-cloche-red'
									src={TrashCan}
									onClick={() => openModal({ id: station.id })}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Station;
