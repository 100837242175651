import React from 'react';
import { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useModal } from '../../../hooks/useModal';
import Popup from '../../../components/Modals/Popup';
import { getLocations } from '../../../axios/location';
import { deleteLocation } from '../../../axios/location';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import './Location.css';
import { notify } from '../../../utils/notify';
import { Image } from 'react-bootstrap';
import LocationDetail from './LocationDetail/LocationDetail'
import Pencil from '../../../assets/NavBar Icons/Pencil.svg'
import TrashCan from '../../../assets/NavBar Icons/TrashCan.svg'
import { useLocation } from 'react-router';

const Location = (props) => {
	const _useLocation = useLocation();
	const [locationsList, setLocationsList] = useState([]);
	const [isOpenModal, openModal, closeModal, data] = useModal();
	const [isLoading, setIsLoading] = useState(false);
	const [currentItem, setCurrentItem] = useState({ location: [], action: '' });
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const { businessId, userType } = JSON.parse(localStorage.getItem('user'));

	useEffect(() => {
		_getLocations();
		props.popupOpen(false)
		const location = _useLocation.state.location
		if (location) handleEditLocation({ location, action: 'edit' })
	}, []);

	const _getLocations = async () => {
		setIsLoading(true)
		await getLocations({ businessId }).then(locations => {
			setLocationsList(locations)
			setIsLoading(false)
		}).catch(error => {
			notify('Unable to read data from database', 'error')
			console.log(error)
			setIsLoading(false)
		})
	};

	const handleEditLocation = item => {
		setCurrentItem(item)
		setIsPopupOpen(true)
	}

	const _deleteLocation = async _data => {
		await deleteLocation(_data).then(() => {
			closeModal();
			_getLocations();
			notify('Location data deleted successfully', 'success')
		}).catch(error => {
			notify('Error deleting location data', 'error')
			console.log(error)
		});
	};

	return (
		<>
			<div className='main'>
				<Popup
					isOpen={isOpenModal}
					title='Delete location'
					label='Are you sure do you want to delete'
					onCancel={closeModal}
					cancelLabel='Cancel'
					onAccept={() => _deleteLocation(data)}
					acceptLabel='Accept'
				/>
				<LoadingSpinner
					isLoading={isLoading}
					label='Loading locations...'
				/>
				{currentItem && isPopupOpen && <LocationDetail
					handleClosePopup={() => setIsPopupOpen(false)}
					location={currentItem.location}
					action={currentItem.action}
					userType={userType}
					fullScreen={_useLocation.state.location}
					isMenuCollapsed={props.isMenuCollapsed}
					response={_getLocations}
				/>}
				<div className="row-header">
					<div>
						<header>Locations</header>
					</div>
					<div>
						<BsPlusCircleFill
							className='plus-icon color-cloche-red'
							onClick={() => handleEditLocation({ location: { status: true, businessId }, action: 'create' })}
						/>
					</div>
				</div>
				<div className='header'>
					<div className='location-name'>NAME</div>
					<div className='location-state'>STATE/PROVINCE</div>
					<div className='location-city'>CITY</div>
					<div className='location-actions'>ACTIONS</div>
				</div>
				<div className='crud'>
					{locationsList.map(location => (
						<div
							className='crud-row'
							key={location.id}>
							<div className='location-name'>{location.name}</div>
							<div className='location-state'>{location.state}</div>
							<div className='location-city'>{location.city}</div>
							<div className='location-actions'>
								<Image
									className='action-icon'
									src={Pencil}
									onClick={() => handleEditLocation({ location, action: 'edit' })}
								/>
								<Image
									className='action-icon color-cloche-red'
									src={TrashCan}
									onClick={() => openModal({ id: location.id })}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Location;
