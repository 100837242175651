import React from 'react';
import PropTypes from 'prop-types';
import './OrderDetails.css'

const ItemList = ({ items }) => {
    return (
        <div className="section-content">
            {items.map((item, index) => (
                <React.Fragment key={item.id}>
                    <div className="section-item">
                        <div className="item-quantity">{item.quantity}</div>
                        <div className="item-details">
                            <div className="item-name-price">
                                <div className="item-name">{item.name}</div>
                                <div className="item-price">${parseFloat(item.price).toFixed(2)}</div>
                            </div>
                            {item.extraItems && Object.entries(item.extraItems).length > 0 && (
                                <ul className="item-extras">
                                    {Object.entries(item.extraItems).map(([key, extras]) => (
                                        extras.map((extra, index) => (
                                            <li key={index} className="extra-item">
                                                <div className="extra-name">{extra.name}</div>
                                                {extra.price && (
                                                    <div className="extra-price">${parseFloat(extra.price).toFixed(2)}</div>
                                                )}
                                            </li>
                                        ))
                                    ))}
                                </ul>
                            )}
                            {item.notes && (
                                <div className="item-notes">
                                    <div className="notes-title">Notes:</div>
                                    <div className="notes-content">{item.notes}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    {index !== items.length - 1 && <div className="item-divider"></div>}
                </React.Fragment>
            ))}
        </div>
    );
};




ItemList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            price: PropTypes.string.isRequired,
            quantity: PropTypes.number.isRequired,
            extraItems: PropTypes.object, // This assumes extraItems is an object
            notes: PropTypes.string
        })
    ).isRequired
};

export default ItemList;