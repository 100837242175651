import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import { selectOrdersListTip, setOrdersListTip, selectTipSelection, setTipSelection, selectOrdersListSubTotal } from '../../Redux/slices/ProductsSlices';
import { selectAddTipModalState, setAddTipModalState } from '../../Redux/slices/ModalsSlices';
import { GlobalColors } from '../../assets/GlobalColors';
import CurrencyInput from 'react-currency-input-field';
import check from '../../assets/images/YourCloche/check.svg';
import './Modals.css'
import customImages from '../../utils/customImages';

const AddTip = () => {
  const optionCatalog = {
    option1: 0,
    option2: 5,
    option3: 8,
    option4: 10,
    option5: 15,
    option6: 20
  }
  const dispatch = useDispatch();
  const total = useSelector(selectOrdersListSubTotal);
  const ordersTip = useSelector(selectOrdersListTip);
  const modalState = useSelector(selectAddTipModalState);
  const tipSelection = useSelector(selectTipSelection)
  const [buttonContent, setButtonContent] = useState(<div style={{ fontSize: '16px',borderRadius:'30px', backgroundColor: GlobalColors.BORDER_BOTTOM_GRAY}}>Go</div>);
  const [tipPercentage, setTipPercentage] = useState(tipSelection)
  const SignupSchema = Yup.object().shape({
    tip: Yup.number().required('Required').min(0, 'Tip must be positive'),
  });
  const handleOptionChange = (changeEvent) => {
    let option = changeEvent.target.value
    formik.values.tip = 0
    setTipPercentage({
      selectedOption: changeEvent.target.value
    })
    dispatch(setTipSelection({selectedOption: option}))
    console.log(option)
    formik.handleSubmit()
  }

  const tipValueChange = (value,name)=> {
    formik.values.tip = value
  }

  useEffect(() => {
    if(ordersTip > 0) formik.values.tip = 0
    tipValueChange()
  },[])
  
 
  
  const formik = useFormik({
    initialValues: {
      tip: ordersTip  ? ordersTip  : '',
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      setButtonContent(
        <Spinner size="sm" animation="border" style={{ width: '1rem', height: '1rem', color: 'white' , backgroundColor: GlobalColors.CLOCHE_RED, padding: '3px'}} />
      );
      try {
        if (formik.values.tip == 0 ){
          let tempVal = (total * optionCatalog[tipPercentage.selectedOption]/100)
          values.tip = Math.round(tempVal * Math.pow(10, 2)) / Math.pow(10, 2);
          
        }
        values.tip = Math.round(values.tip * Math.pow(10, 2)) / Math.pow(10, 2);
        dispatch(setOrdersListTip(values.tip));
        setTimeout(() => {
          setButtonContent(<Image src={check} />);
          dispatch(setAddTipModalState(false));
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    setTipPercentage({
      selectedOption: 'option1'
    })
    dispatch(setTipSelection({ selectedOption: 'option1' }))
    if (modalState === false) {
      setButtonContent(<div style={{fontSize: '16px', borderRadius: '30px', backgroundColor: GlobalColors.BORDER_BOTTOM_GRAY}}>Go</div>);
    }
  }, [modalState, setButtonContent]);

  return (
    <Modal 
      className="Modal-Tip"
      show={modalState} 
      centered 
      animation={true} 
      onHide={() => dispatch(setAddTipModalState(false))}
      style={{
        backgroundColor: GlobalColors.GRAY_MODAL_BACKGROUND,
      }}
      >
      <Modal.Header
        closeButton
        style={{
          border: 'none',
          backgroundColor: GlobalColors.GRAY_MODAL_BACKGROUND,
          padding: '24px 24px 0 24px'
        }}
      >
        <Modal.Title className="text-title" style={{
          fontSize: '18px',
        }}>Change your Tip</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          backgroundColor: GlobalColors.GRAY_MODAL_BACKGROUND,
          padding: '24px'
        }}
      >
        <p>
          The Cloche experience can justify a lower tip since your server is able to help 
          <div style={{display: 'inline-block',fontWeight: 600}}>&nbsp;more tables</div>, 
          <div style={{display: 'inline-block', fontWeight:600}}> &nbsp;faster</div> and 
          <div style={{display: 'inline-block', fontWeight: 600}}> &nbsp;easier</div>.
        </p>
        <p>
          But don't let that stop you if you received 
          <div style={{display: 'inline-block', fontWeight: 600}}> &nbsp;exceptional</div> service!
        </p>
        <form
          onSubmit={formik.handleSubmit}
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="mb-2 d-inline-flex p-2 ">
            <div>
              <input 
                checked={tipPercentage.selectedOption === 'option1'} 
                onChange={handleOptionChange}
                value='option1' 
                type="radio" 
                className="btn-check" 
                name="options" 
                id="option1" 
                autoComplete="off"/>
              <label 
                style={{
                  marginRight: '5px',
                  height: '50px',
                  width: '50px',
                  fontSize: '14px',
                  padding: '14px',
                  backgroundColor: tipPercentage.selectedOption =='option1' ? GlobalColors.CLOCHE_RED : GlobalColors.GRAY, 
                  color: GlobalColors.WHITE,
                  }}
                className="btn rounded-circle" 
                for="option1" 
                >
                  0%
              </label>
            </div>
            <div style={{borderRadius: '50%'}}>
              <input 
                checked={tipPercentage.selectedOption === 'option2'} 
                onChange={handleOptionChange}
                value='option2' 
                type="radio" 
                className="btn-check" 
                name="options" 
                id="option2" 
                autoComplete="off"/>
              <label 
                style={{
                  marginRight: '5px',
                  height: '50px',
                  width: '50px',
                  fontSize: '14px',
                  padding: '14px',
                  backgroundColor: tipPercentage.selectedOption =='option2' ? GlobalColors.CLOCHE_RED : GlobalColors.GRAY, 
                  color: GlobalColors.WHITE,
                  }}
                className="btn rounded-circle" 
                for="option2" 
                >
                  5%
              </label>
            </div>

            <div 
              style={{
                textAlign: 'center',
              }}
            >
              <input 
                checked={tipPercentage.selectedOption === 'option3'} 
                onChange={handleOptionChange}
                value='option3'
                type="radio" 
                className="btn-check" 
                name="options" 
                id="option3" 
                autoComplete="off"/>
              <label 
                style={{
                  marginRight: '5px',
                  height: '50px',
                  width: '50px',
                  fontSize: '14px',
                  padding: '14px',
                  backgroundColor: tipPercentage.selectedOption == 'option3' ? GlobalColors.CLOCHE_RED : GlobalColors.GRAY,
                  display: 'inline-flex'
              }}
                className="btn btn-secondary rounded-circle" 
                for="option3" checked
              >
                8%
              </label>
            </div>

            <div 
              style={{
                textAlign: 'center',
              }}
            >
              <input 
                checked={tipPercentage.selectedOption === 'option4'} 
                onChange={handleOptionChange}
                value='option4'
                type="radio" 
                className="btn-check" 
                name="options" 
                id="option4" 
                autoComplete="off"/>
              <label 
                style={{
                  marginRight: '5px',
                  height: '50px',
                  width: '50px',
                  fontSize: '14px',
                  padding: '14px',
                  backgroundColor: tipPercentage.selectedOption == 'option4' ? GlobalColors.CLOCHE_RED : GlobalColors.GRAY,
                  display: 'inline-flex'
              }}
                className="btn btn-secondary rounded-circle" 
                for="option4" checked
              >
                10%
              </label>
            </div>

            <div
              style={{
                textAlign: 'center',
              }}
              >
              <input
                checked={tipPercentage.selectedOption === 'option5'} 
                onChange={handleOptionChange}
                value='option5' 
                type="radio" 
                className="btn-check" 
                name="options" 
                id="option5" 
                autoComplete="off" />
              <label 
                style={{
                  marginRight: '5px',
                  height: '50px',
                  width: '50px',
                  fontSize: '14px',
                  backgroundColor: tipPercentage.selectedOption =='option5' ? GlobalColors.CLOCHE_RED : GlobalColors.GRAY,
                  alignItems: 'center'
              }} 
                className="btn btn-secondary rounded-circle" 
                for="option5"
                >
                  15%
              </label>
            </div>
            
            <div
              style={{
                textAlign: 'center',
                justifyContent:'center',
                display: 'inline-flex !important',
              }}
            >
              <input 
                checked={tipPercentage.selectedOption === 'option6'} 
                onChange={handleOptionChange}
                value='option6'
                type="radio" 
                className="btn-check" 
                name="options" 
                id="option6" 
                autoComplete="off"/>
              <label 
                style={{
                  height: '50px',
                  width: '50px',
                  fontSize: '14px',
                  backgroundColor: tipPercentage.selectedOption =='option6' ? GlobalColors.CLOCHE_RED : GlobalColors.GRAY,
                  alignItems: 'center',
                }} 
                className="btn btn-secondary rounded-circle" 
                for="option6"
              >
              20%
              </label>
            </div>
          </div>
          <div
            style={{
              width: 'auto',
              height: 'auto',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              className="d-inline-flex"
              style={{
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
            <div>Or enter a custom amount</div>

             <CurrencyInput
                
                id="tip"
                name="tip"
                style={{
                  width: '20%',
                  textAlign: 'end',
                  fontSize: '12px',
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderBottom: formik.errors.tip ? '1px solid red' : '1px solid #000',
                  color: GlobalColors.BLACK,
                }}
                className="text-title"
                defaultValue={null}
                placeholder="$0"
                onValueChange={(value,name)=> formik.values.tip = value}
                value={tipPercentage.selectedOption ? null :  formik.values.tip}
                prefix={'$'}
                decimalsLimit={2}
                step={1}
                allowNegativeValue={false}
              />
          <Button
            className="Btn-Go"
            type="submit"
            style={{           
              display: 'flex',
              borderRadius: '30px',
              backgroundColor: GlobalColors.BORDER_BOTTOM_GRAY,
              color: GlobalColors.WHITE,
              fontSize: '16px',
              border: 'none',
            }}
          >
            {buttonContent}
          </Button>
          </div>
          </div>
        </form>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center'
          }}
        >
          <img alt="clocheLogoTip" src={customImages.clocheLogoTip} style={{ width: '40%' }} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddTip;
