import React from 'react';
import { Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import useMediaQuery from '../../hooks/useMediaQuery';
import PriceFormat from '../../components/General/PriceFormat';
import { GlobalColors } from '../../assets/GlobalColors';

const FoodItem = ({ product, clickFunction }) => {
  const isMobileS = useMediaQuery('(max-width: 320px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptop = useMediaQuery('(max-width: 1024px)');
  const isLaptopL = useMediaQuery('(max-width: 1440px)');

  const titleFontSize = () => {
    return isMobileS || isMobileM
      ? '12px'
      : isMobileL || isTablet
        ? '14px'
        : '16px';
  };


  const descriptionFontSize = () => {
    return isMobileS || isMobileM || isMobileL || isTablet || isLaptop || isLaptopL
      ? '11px'
      : '12px';
  };

  return (
    <Button
      style={{
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100px',
        borderRadius: '0px',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: '1px solid #E5E5E5',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '8px',
        filter: !product.isAvailable ? "blur(2px)" : "none"
      }}
      id={product.kind}
      onClick={(e) => {
        e.preventDefault();
        if (product.isAvailable) clickFunction(product);
      }}
    >
      <div
        style={{
          width: '100px',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image
          alt={product.name}
          src={product.imageUrl}
          style={{
            width: '80%',
            height: '80%',
            borderRadius: '5px',
          }}
        />
      </div>

      <div
        style={{
          width: '50%',
          height: '100%',
          flex: '1 1 auto',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        <div
          className="text-title"
          style={{
            width: '100%',
            height: 'auto',
            textAlign: 'left',
            color: GlobalColors.BLACK,
            fontSize: titleFontSize(),
            letterSpacing: '0px',
          }}
        >
          {product.name}
        </div>

        <div
          className="text-normal"
          style={{
            width: '100%',
            height: '18%',
            textAlign: 'left',
            color: GlobalColors.BLACK,
            fontSize: titleFontSize(),
          }}
        >
          <PriceFormat price={product.price} />
        </div>

        <div
          className="text-normal"
          style={{
            width: '100%',
            height: '38%',
            textAlign: 'left',
            color: GlobalColors.BLACK,
            fontSize: descriptionFontSize(),
            overflow: 'scroll',
            lineHeight: '14px'
          }}
        >
          {product.description}
        </div>
      </div>
    </Button>
  );
};

export default FoodItem;
