import React from 'react';
import Image from 'react-bootstrap/Image';
import { useFormik } from 'formik';
import { FiEye } from 'react-icons/fi';
import { FiEyeOff } from 'react-icons/fi';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { notify } from '../../../utils/notify'
import axios from 'axios';

import ClocheLogoWhite from '../../../assets/images/Kitchen/SignIn/ClocheLogoWhite.svg';
import { GlobalColors } from '../../../assets/GlobalColors';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { setSignInKitchenUser } from '../../../Redux/slices/KitchenSlices';
import { REACT_APP_API_URL } from '../../../utils/envConfig';
import { setWelcomeModalState } from '../../../Redux/slices/ModalsSlices';
import { getRole } from '../../../axios/role';
import { getStations } from '../../../axios/station';
import { decryptPassword } from '../../../utils/encryptPassword';


const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptop = useMediaQuery('(max-width: 1024px)');
  const isLaptopL = useMediaQuery('(max-width: 1440px)');
  const isDesktop = useMediaQuery('(max-width: 2560px)');
  dispatch(setWelcomeModalState(false));
  const [showPassword, setShowPassword] = React.useState(false);
  const [buttonContent, setButtonContent] = React.useState(<div className="text-title">Log In</div>);
  const SignupSchema = Yup.object().shape({
    userName: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
  });


  const MAX_RETRIES = 3;

  const attemptLogin = async (username) => {
    return await axios.post(
      `${REACT_APP_API_URL}/user`,
      JSON.stringify({ username })
    );
  };

  const loginUserWithRetries = async (username) => {
    for (let i = 0; i < MAX_RETRIES; i++) {
      try {
        const response = await attemptLogin(username);
        return response.data;
      } catch (error) {
        if (i === MAX_RETRIES - 1) throw error;
      }
    }
  };

  const resetLoginForm = () => {
    formik.resetForm();
    setButtonContent(<div className="text-title">Log In</div>);
  }

  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
      userType: ''
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      setButtonContent(
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      );
      const { userName: username, password, userType } = values;

      const data = {
        username,
        password,
        userType,
      };

      try {
        const user = await loginUserWithRetries(username);

        if (!user) {
          notify('Incorrect username or password', 'error')
          resetLoginForm();
          return
        } else if (!user.stripeAccountDone) {

        }
        const passwordDecrypted = decryptPassword(user.password)
        if (passwordDecrypted !== password) {
          notify('Incorrect username or password', 'error')
          resetLoginForm();
          return
        }

        const _user = {
          userType: user.userType,
          businessId: user.businessId,
          locationId: user.locationId,
        };
        localStorage.setItem('user', JSON.stringify(_user));
        let _stripe
        if (user.userType !== 'superAdmin' && !user.stripeAccountDone) {
          _stripe = {
            redirectUser: user.stripeUrl && user.stripeUrl.url?.length > 0,
            stripeUrl: user.stripeUrl.url
          }
        } else {
          _stripe = {
            redirectUser: false,
            stripeUrl: ''
          }
        }

        localStorage.setItem('stripe', JSON.stringify(_stripe));


        data.userType = user.userType;
        dispatch(setSignInKitchenUser(data));

        await navigateToNextPage(user);
      } catch (err) {
        console.log('err', err);
        notify(err.response.data ? err.response.data : 'Unable to read data from database', 'error')
      }
    },
  });

  const navigateToNextPage = async (user) => {
    if (user.userType === 'operation' || user.userType === 'manager') {
      const stations = await getStations({ locationId: user.locationId });
      localStorage.setItem('stationList', JSON.stringify(stations));

      const hasBar = stations.some(station => station.type === 'bar');
      localStorage.setItem('hasBar', hasBar);

      if (user.userType === 'operation') {
        const role = await getRole({ roleId: user.roleId });
        const station = stations.find(station => station.id === role.station[0]);
        const stationUrl = "/" + station.type;

        const _station = {
          stationType: station.type,
        };
        localStorage.setItem('station', JSON.stringify(_station));

        navigate(stationUrl);
      } else {
        navigate('/users');
      }
    } else {
      navigate('/users');
    }
  };

  const mainDivWidth = () => {
    return isTablet ? '40%' : isLaptop ? '40%' : isLaptopL ? '40%' : isDesktop ? '35%' : '90%';
  };

  const clocheLogoSize = () => {
    return isTablet ? '65%' : isLaptop ? '55%' : isLaptopL ? '45%' : isDesktop ? '35%' : '20%';
  };

  const clocheInputTextSize = () => {
    return isTablet ? '14px' : isLaptop ? '14px' : isLaptopL ? '14px' : isDesktop ? '16px' : '14px';
  };

  return (
    <div
      style={{
        backgroundColor: GlobalColors.SIGN_IN_BACKGROUND,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: mainDivWidth(),
          height: '70%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Image src={ClocheLogoWhite} style={{ width: clocheLogoSize() }} />

        <form
          onSubmit={formik.handleSubmit}
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '10px',
              marginBottom: '20px',
            }}
          >
            <label
              htmlFor="userName"
              className="text-normal"
              style={{
                color: 'rgba(255, 255, 255, 0.6)',
                fontSize: clocheInputTextSize(),
              }}
            >
              Username
            </label>
            <input
              name="userName"
              type="userName"
              onChange={formik.handleChange}
              value={formik.values.userName}
              style={{
                width: '100%',
                height: '48px',
                borderRadius: '8px',
                padding: '12px 20px 12px 20px',
                gap: '10px',
                border: formik.errors.userName ? `2px solid ${GlobalColors.CLOCHE_RED}` : 'none',
              }}
            />
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '10px',
              marginBottom: '20px',
            }}
          >
            <label
              htmlFor="userName"
              className="text-normal"
              style={{
                color: 'rgba(255, 255, 255, 0.6)',
                fontSize: clocheInputTextSize(),
              }}
            >
              Password
            </label>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                height: 'auto',
              }}
            >
              <input
                name="password"
                type={showPassword ? 'text' : 'password'}
                onChange={formik.handleChange}
                value={formik.values.password}
                style={{
                  width: '85%',
                  height: '48px',
                  padding: '12px 20px 12px 20px',
                  gap: '10px',
                  backgroundColor: GlobalColors.WHITE,
                  borderRadius: '8px 0px 0px 8px',
                  borderLeft: formik.errors.password ? `2px solid ${GlobalColors.CLOCHE_RED}` : 'none',
                  borderTop: formik.errors.password ? `2px solid ${GlobalColors.CLOCHE_RED}` : 'none',
                  borderBottom: formik.errors.password ? `2px solid ${GlobalColors.CLOCHE_RED}` : 'none',
                  borderRight: 'none',
                }}
              />

              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  height: '48px',
                  width: '15%',
                  outline: 'none',
                  cursor: 'pointer',
                  padding: '0',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: GlobalColors.WHITE,
                  borderRadius: '0px 8px 8px 0px',
                  borderLeft: 'none',
                  borderTop: formik.errors.password ? `2px solid ${GlobalColors.CLOCHE_RED}` : 'none',
                  borderBottom: formik.errors.password ? `2px solid ${GlobalColors.CLOCHE_RED}` : 'none',
                  borderRight: formik.errors.password ? `2px solid ${GlobalColors.CLOCHE_RED}` : 'none',
                }}
              >
                {showPassword ? (
                  <FiEyeOff
                    style={{
                      color: GlobalColors.BORDER_BOTTOM_GRAY,
                    }}
                    size={25}
                  />
                ) : (
                  <FiEye
                    style={{
                      color: GlobalColors.BORDER_BOTTOM_GRAY,
                    }}
                    size={25}
                  />
                )}
              </button>
            </div>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '20px',
            }}
          >
            <div
              className="text-normal"
              style={{
                color: GlobalColors.WHITE,
                fontSize: clocheInputTextSize(),
              }}
            >
              Forgot your password?
            </div>
          </div>

          <button
            type="submit"
            style={{
              width: '100%',
              height: '48px',
              borderRadius: '8px',
              backgroundColor: GlobalColors.CLOCHE_RED,
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              color: GlobalColors.WHITE,
            }}
          >
            {buttonContent}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
