import { REACT_APP_API_URL } from '../utils/envConfig';
import axios from 'axios';

export async function getTables(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_URL}/getTables`, JSON.stringify(data))
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export async function getTable(tableId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_URL}/getTable`, JSON.stringify(tableId))
      .then(async response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export async function getNameAndLogic(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_URL}/getNameAndLogic`, JSON.stringify(data))
      .then(async response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export async function updateQR(table) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_URL}/updateQR`, JSON.stringify(table))
      .then((response) => {
        console.log(response)
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export async function addTable(table) {
  let tableId;
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_URL}/addTable`, JSON.stringify(table))
      .then((response) => {
        tableId = response.data.id
        resolve(response.data);
      })
      .then((res) => {
        updateQR({ ...table, tableId });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function updateTable(table) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_URL}/updateTable`, JSON.stringify(table))

      .then((response) => {
        resolve(response.data);
      })
      .then((res) => {
        updateQR({ ...table });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function deleteTable(table) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_URL}/deleteTable`, JSON.stringify(table))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function askForAssistance(table) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_URL}/askAssistance`, JSON.stringify(table))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getAssistanceUpdates(query) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_URL}/getAssistanceUpdates`, JSON.stringify(query))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
