import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { selectKitchenUserToken } from '../Redux/slices/KitchenSlices';

const PrivateRoutes = () => {
  const kitchenUserToken = useSelector(selectKitchenUserToken);

  return kitchenUserToken != null ? <Outlet /> : <Navigate to="/kitchen/sign-in" />;
};

export default PrivateRoutes;
