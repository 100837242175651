import React from 'react';
import { motion } from 'framer-motion';
import { useLocation, Outlet } from 'react-router-dom';

const PageLayout = ({ children }) => children;

const AnimationLayout = () => {
  const { pathname } = useLocation();

  return (
    <PageLayout>
      <motion.div
        key={pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Outlet />
      </motion.div>
    </PageLayout>
  );
};

export default AnimationLayout;
