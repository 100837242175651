import moneyCash from '../assets/images/moneycash.png';
import clocheYourOrder from '../assets/images/clocheYourOrder.png';
import cloche from '../assets/images/cloche.png'
import clocheBack from '../assets/images/clocheBack.png';
import clocheWelcome from '../assets/images/clocheWelcome.png'
import welcomeBell from '../assets/images/welcomeBell.png'
import welcomeBackground from '../assets/images/newWelcome.png'
import clocheLogoTip from '../assets/images/clocheLogoTip.png'

const customImages = { moneyCash, clocheYourOrder, cloche, clocheBack, clocheWelcome, welcomeBell, welcomeBackground, clocheLogoTip};


export default customImages;