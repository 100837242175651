import React from 'react';
import { useEffect, useState } from 'react';
import { getStations } from '../../../axios/station';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import './Station.css'
import { notify } from '../../../utils/notify';

const StationOption = () => {
	const [stationList, setStationList] = useState([])
	const [isLoading, setIsLoading] = useState(false);
	const {locationId} = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
	useEffect(() =>  {
		_getStations();
	},[])

	const _getStations = async () => {
		setIsLoading(true)
		await getStations({ locationId }).then(response => {
			setStationList(response);
			if (response && response.length === 1) {
				navigate('/kitchen');
			}
			setIsLoading(false)
		}).catch(error => {
			notify('Unable to read data from database', 'error')
			console.log(error)
			setIsLoading(false)
		})
	};


	return (
		<>
			<LoadingSpinner
				isLoading={isLoading}
				label='Loading stations...'
			/>
			<div className='main'>
				<header>Select a Station</header>
				<br />
				<div className='station-select'>
					{stationList.map(station => (
						<div
                            
                            key={station.id}>
							<div
								style={{
									display: 'flex',
									borderRadius: '30px',
									backgroundColor: 'white',
									width: '300px',
									height: '200px',
									alignItems: 'center',
									justifyContent: 'center',
									fontSize: '22px'
								}}
								className='station-select-name'
								onClick={() => navigate(`/${station.type}`)}>
                                    {station.name}
                            </div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default StationOption;
