import { REACT_APP_API_URL } from '../utils/envConfig';
import axios from 'axios';

export async function getLocalProducts (data) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getLocalProducts`, JSON.stringify(data))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function getLocalProduct (localProductId) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getLocalProduct`, JSON.stringify(localProductId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function addLocalProduct(localProduct) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/localProduct`, JSON.stringify(localProduct))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}

export async function updateLocalProduct(localProduct) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/updateLocalProduct`, JSON.stringify(localProduct))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}

export async function deleteLocalProduct (localProductId) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/deleteLocalProduct`, JSON.stringify(localProductId))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}
