import React from 'react';
import { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useModal } from '../../../hooks/useModal';
import Popup from '../../../components/Modals/Popup';
import { deleteGlobalProduct, deleteProductImage, getGlobalProducts } from '../../../axios/globalProduct';
import { deleteLocalProduct, getLocalProducts } from '../../../axios/localProduct';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import './Product.css';
import { notify } from '../../../utils/notify';
import { Image } from 'react-bootstrap';
import ProductDetail from './Detail/ProductDetail';
import Pencil from '../../../assets/NavBar Icons/Pencil.svg'
import TrashCan from '../../../assets/NavBar Icons/TrashCan.svg'

const Product = (props) => {
	const [productsList, setProductsList] = useState([]);
	const [isOpenModal, openModal, closeModal, data] = useModal();
	const [isLoading, setIsLoading] = useState(false);
	const [currentItem, setCurrentItem] = useState({ product: [], action: '' });
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const { userType, businessId, locationId } = JSON.parse(localStorage.getItem('user'));

	useEffect(() => {
		_getProducts();
	}, []);

	useEffect(() => {
		props.popupOpen(isPopupOpen)
	}, [isPopupOpen])


	const _getProducts = async () => {
		setIsLoading(true)
		await Promise.all([
			getGlobalProducts({ businessId }),
			getLocalProducts({ locationId }),
		]).then(response => {
			const localProducts = response[1].map(item => ({
				...item,
				isGlobal: false
			}))
			const globalProducts = response[0].map(item => ({
				...item,
				isGlobal: true
			}))
				.filter(globalProduct => localProducts.every(localProduct => localProduct.productId !== globalProduct.id))
			const productsList = globalProducts.concat(localProducts)

			setProductsList(productsList);
			setIsLoading(false)
		}).catch(error => {
			notify('Unable to read data from database', 'error')
			console.log(error)
			setIsLoading(false)
		})
	};

	const handleEditProduct = item => {
		setCurrentItem(item)
		setIsPopupOpen(true)
	}

	const isAllowed = (isGlobal) => {
		if(userType === "admin"){
			return true
		} else {
			return !isGlobal;
		}
	}

	const deleteProduct = async _data => {
		try {
			if (userType === 'admin' && _data.isGlobal) {
				await Promise.all([deleteGlobalProduct(_data), deleteProductImage(_data)]);
			} else if (userType === 'admin' && !_data.isGlobal) {
				await Promise.all([deleteLocalProduct(_data), deleteProductImage(_data)]);
			} else if (userType !== 'admin' && _data.isGlobal) {
				throw new Error("Non-admin user cannot delete a global product.");
			} else {
				await Promise.all([deleteLocalProduct(_data), deleteProductImage(_data)]);
			}

			closeModal();
			_getProducts();
			notify('Product data deleted successfully', 'success');
		} catch (error) {
			notify('Error deleting product data', 'error');
			console.log(error);
		}
	};

	return (
		<>
			<div className='main'>
				<Popup
					isOpen={isOpenModal}
					title='Delete product'
					label='Are you sure do you want to delete'
					onCancel={closeModal}
					cancelLabel='Cancel'
					onAccept={e => deleteProduct(data)}
					acceptLabel='Accept'
				/>
				<LoadingSpinner
					isLoading={isLoading}
					label='Loading products...'
				/>
				{currentItem && isPopupOpen && <ProductDetail
					handleClosePopup={() => setIsPopupOpen(false)}
					product={currentItem.product}
					action={currentItem.action}
					userType={userType}
					isMenuCollapsed={props.isMenuCollapsed}
					response={_getProducts}
				/>}
				<div className="row-header">
					<div>
						<header>Products</header>
					</div>
					<div>
						<BsPlusCircleFill
							className='plus-icon color-cloche-red'
							onClick={() => handleEditProduct({ product: { isAvailable: true, businessId, locationId }, action: 'create' })}
						/>
					</div>
				</div>
				<div className='header'>
					<div className='product-name'>Name</div>
					<div className='product-type'>Type</div>
					<div className='product-price'>Price</div>
					{userType === 'manager' &&
						<div className='product-isGlobal'>Level</div>
					}
					<div className='product-isAvailable'>Status</div>
					<div className='product-actions'>Actions</div>
				</div>
				<div className='crud'>
					{productsList.map(product => (
						<div
							className='crud-row'
							key={product.id}>
							<div className='product-name'>{product.name}</div>
							<div className='product-type'>{product.type.charAt(0).toUpperCase() + product.type.slice(1)}</div>
							<div className='product-price'>{product.price}</div>
							{userType === 'manager' &&
								<div className='product-isGlobal'>{product.isGlobal ? 'Global' : 'Local'}</div>
							}
							<div className={`product-isAvailable ${!product.isAvailable && 'color-cloche-red'}`}>{product.isAvailable ? 'AVAILABLE' : 'NOT AVAILABLE'}</div>
							<div className='product-actions'>
								{ isAllowed(product.isGlobal) && <div>
									<Image
										className='action-icon'
										src={Pencil}
										onClick={() => handleEditProduct({ product, action: 'edit' })}
									/>
									<Image
										className='action-icon color-cloche-red'
										src={TrashCan}
										onClick={() => openModal({ id: product.id, businessId: product.businessId, locationId: product.locationId, isGlobal: product.isGlobal })}
									/>
								</div>
								}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Product;
