import React from 'react';
import './SideMenu.css';
import { Menu, MenuItem, ProSidebar, SidebarHeader, SidebarFooter, SubMenu } from 'react-pro-sidebar';
import { GlobalColors } from '../../../assets/GlobalColors';
import { Outlet } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Logo from '../../../assets/KitchenSideBar/Logo.svg';
import TextlessLogo from '../../../assets/KitchenSideBar/TextlessLogo.svg';
import { FaUser, FaUserCog, FaBuilding } from 'react-icons/fa';
import { MdFastfood, MdLogout, MdMenuBook, MdLocationPin, MdDashboard } from 'react-icons/md';
import { GiTable, GiCookingPot, GiRoundTable } from 'react-icons/gi';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getLocation } from '../../../axios/location';
import { getBusiness } from '../../../axios/business';
import CompressionButton from './CompressionButton';
import { getStations } from '../../../axios/station';
import { getRoles } from '../../../axios/role';
import RedirectPopUp from './RedirectPopUp'
const SideMenu = (props) => {
	const handleLogOut = () => {
		localStorage.clear()
		navigate('/sign-in');
	};
	const { userType, businessId, locationId } = JSON.parse(localStorage.getItem('user'));
	const { redirectUser, stripeUrl } = JSON.parse(localStorage.getItem('stripe'));
	const [location, setLocation] = useState(JSON.parse(localStorage.getItem('myLocation')));
	const [business, setBusiness] = useState(JSON.parse(localStorage.getItem('myBusiness')));
	const [isCompressed, setIsCompressed] = useState(false);
	const [hasService, setHasService] = useState(false);
	const [hasOpServRole, sethasOpServRole] = useState(false);
	const _useLocation = useLocation();
	const [currentItemMenu, setCurrentItemMenu] = useState(1);
	const [showPopup, setShowPopup] = useState(redirectUser);
	const roleTypes = {
		Admin: 'admin',
		Manager: 'manager',
		Cook: 'kitchen',
		Bartender: 'bar',
		Runner: 'service',
		Operation: 'operation'
	}
	const urls = {
		'/users': 1,
		'/roles': 2,
		'/businesses': 3,
		'/products': 4,
		'/productOptionLists': 5,
		'/productOptions': 6,
		'/locations': 7,
		'/tables': 8,
		'/tablesManagement': 9,
		'/stations': 10,
		'/menus': 11,
	}
	const boardLogic = {
		kitchen: "kitchen",
		service: "service",
		bar: "bar",
	}
	useEffect(() => {
		if (!location && userType === 'manager') getMyLocation();
		if (!business && userType === 'admin') getMyBusiness();
		getRoles({ userType, locationId, businessId }).then((res) => sethasOpServRole(res.some(role => role.userType === roleTypes.Operation)));
		getStations({ locationId: locationId }).then((res) => setHasService(res.some(station => station.type === boardLogic.service)));
	}, []);

	useEffect(() => {
		setCurrentItemMenu(urls[_useLocation.pathname])
	}, [_useLocation]);

	const getMyLocation = async () => {
		const response = await getLocation({ locationId });
		localStorage.setItem('myLocation', JSON.stringify(response));
		setLocation(response);
	};

	const getMyBusiness = async () => {
		const response = await getBusiness({ businessId });
		localStorage.setItem('myBusiness', JSON.stringify(response));
		setBusiness(response);
	};

	const toggleCompression = () => {
		props.menuCollapsed(!isCompressed)
		setIsCompressed(!isCompressed);
	}

	const navigate = useNavigate();
	return (
		<>
			{showPopup && <RedirectPopUp stripeUrl={stripeUrl} onClose={() => setShowPopup(false)} />}
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
					height: '100vh',
					backgroundColor: GlobalColors.KITCHEN_BACKGROUND,
				}}
			>
				<ProSidebar className={isCompressed ? 'CompressedProSiderbar' : 'ProSidebar'}>
					<CompressionButton
						buttonClick={toggleCompression}
						isCompressed={isCompressed}
						marginRight={'-24px'}
					/>
					<div
						style={{
							filter: `${props.isPopupOpen ? 'blur(4px)' : 'none'}`,
							height: '105%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
							margin: '-8px',
							padding: '8px',
							backgroundColor: GlobalColors.WHITE,
						}}
					>
						<SidebarHeader
							style={{
								border: 'none',
								alignSelf: 'center',
								padding: '20px',
								justifySelf: 'center'
							}}
						>
							<div>
								<Image src={isCompressed ? TextlessLogo : Logo} className='logo' />
							</div>
						</SidebarHeader>

						<Menu className='menu'>
							<MenuItem
								className={`item-menu ${currentItemMenu === 1 && 'itemSelected'} `}
								icon={<FaUser className='menu-icon' />}
								onClick={() => {
									if (!showPopup) {
										navigate('/users');
									}
								}}
							>
								{!isCompressed && "Users"}
							</MenuItem>
							<MenuItem
								className={`item-menu ${currentItemMenu === 2 && 'itemSelected'} `}
								icon={<FaUserCog className='menu-icon' />}
								onClick={() => {
									if (!showPopup) {
										navigate('/roles');
									}
								}}
							>
								{!isCompressed && "Roles"}
							</MenuItem>
							{(userType === 'superAdmin' || userType === 'admin') &&
								<MenuItem
									className={`item-menu ${currentItemMenu === 3 && 'itemSelected'} `}
									icon={<FaBuilding className='menu-icon' />}
									onClick={() => {
										if (!showPopup) {
											navigate('/businesses', {
												state: {
													business: business,
													action: 'edit',
													fullScreen: true,
													isCollapsed: isCompressed,
													userType: userType,
												}
											})
										}

									}}
								>
									{!isCompressed && (userType === 'superAdmin' ? 'Businesses' : 'My Business')}
								</MenuItem>
							}
							{(userType === 'admin' || userType === 'manager') &&
								<>
									{
										isCompressed ?
											<MenuItem
												className={`item-menu ${[4, 5, 6].includes(currentItemMenu) && 'itemSelected'} `}
												icon={<MdFastfood className='menu-icon' />}
												onClick={() => { toggleCompression() }}
											/>
											:
											<SubMenu
												className={`item-menu ${[4, 5, 6].includes(currentItemMenu) && 'itemSelected'} `}
												title={!isCompressed && "Products"}
												icon={<MdFastfood className='menu-icon' />}
											>
												<MenuItem
													className={`item-menu ${currentItemMenu === 4 && 'itemSelected'} `}
													onClick={() => {
														if (!showPopup) {
															navigate('/businesses', {
																state: {
																	business: business,
																	action: 'edit',
																	fullScreen: true,
																	isCollapsed: isCompressed,
																	userType: userType,
																}
															})
															navigate('/products');
														}
													}}
												>
													{!isCompressed && "Products"}
												</MenuItem>
												<MenuItem
													className={`item-menu ${currentItemMenu === 5 && 'itemSelected'} `}
													onClick={() => {
														if (!showPopup) {
															navigate('/productOptionLists');
														}
													}}
												>
													{!isCompressed && "Option Lists"}
												</MenuItem>
												<MenuItem
													className={`item-menu ${currentItemMenu === 6 && 'itemSelected'} `}
													onClick={() => {
														if (!showPopup) {
															navigate('/productOptions');
														}
													}}
												>
													{!isCompressed && "Options"}
												</MenuItem>
											</SubMenu>
									}
								</>
							}
							{(userType === 'manager' || userType === 'admin') &&
								<>
									<MenuItem
										className={`item-menu ${currentItemMenu === 7 && 'itemSelected'} `}
										icon={<MdLocationPin className='menu-icon' />}
										onClick={() => {
											if (!showPopup) {
												navigate('/locations', {
													state: {
														location: location,
														action: 'edit',
														fullScreen: true,
														isCollapsed: isCompressed,
														userType: userType
													}
												})
											}
										}}
									>
										{!isCompressed && (userType === 'admin' ? 'Locations' : 'My location')}
									</MenuItem>
								</>
							}
							{userType === 'manager' &&
								<>
									<MenuItem
										className={`item-menu ${currentItemMenu === 8 && 'itemSelected'} `}
										icon={<GiTable className='menu-icon' />}
										onClick={() => {
											if (!showPopup) {
												navigate('/tables');
											}
										}}
									>
										{!isCompressed && "Tables"}
									</MenuItem>
									{hasService && hasOpServRole &&
										<MenuItem
											className={`item-menu ${currentItemMenu === 9 && 'itemSelected'} `}
											icon={<GiRoundTable className='menu-icon' />}
											onClick={() => {
												if (!showPopup) {
													navigate('/tablesManagement');
												}
											}}
										>
											{!isCompressed && "Tables Management"}
										</MenuItem>
									}
									<MenuItem
										className={`item-menu ${currentItemMenu === 10 && 'itemSelected'} `}
										icon={<GiCookingPot className='menu-icon' />}
										onClick={() => {
											if (!showPopup) {
												navigate('/stations');
											}
										}}
									>
										{!isCompressed && "Stations"}
									</MenuItem>
								</>
							}
							{(userType === 'manager' || userType === 'admin') &&
								<MenuItem
									className={`item-menu ${currentItemMenu === 11 && 'itemSelected'} `}
									icon={<MdMenuBook className='menu-icon' />}
									onClick={() => {
										if (!showPopup) {
											navigate('/menus');
										}
									}}
								>
									{!isCompressed && "Menus"}
								</MenuItem>
							}
							{userType === 'manager' &&
								<MenuItem
									className='item-menu'
									icon={<MdDashboard className='menu-icon' />}
									onClick={() => {
										if (!showPopup) {
											localStorage.setItem('station', JSON.stringify({ "stationType": "kitchen" }));
											navigate('/kitchen');
										}
									}}
								>
									{!isCompressed && "Operations"}
								</MenuItem>
							}
						</Menu>

						<SidebarFooter className='Sidebarfooter' >
							<MenuItem
								className='sideBar-item'
								icon={<MdLogout className='menu-icon' />}
								onClick={() => { handleLogOut() }}
							>
								{!isCompressed && "Log out"}
							</MenuItem>
						</SidebarFooter>
					</div>
				</ProSidebar>
				<div
					style={{
						width: '100%',
						height: '100%',
						backgroundColor: GlobalColors.KITCHEN_BACKGROUND,
					}}
				>
					<Outlet />
				</div>
			</div>
		</>
	);
};
export default SideMenu;
