import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdOutlineContactSupport } from 'react-icons/md'
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import {
  addOrder,
  selectProductsInCloche,
  selectSubTotal,
  resetProductsInCloche,
  selectTableNum,
  selectIsProductsInClocheEmpty,
  selectIsFoodTruck,
  selectRestaurantName,
  selectLocationId,
  selectLocationType,
} from '../../Redux/slices/ProductsSlices';
import { setOrderOverviewModalState, setHelpModalState } from '../../Redux/slices/ModalsSlices';
import Title from '../../components/General/Title';
import FoodItemsCloche from '../../components/YourCloche/FoodItemsCloche';
import PriceFormat from '../../components/General/PriceFormat';
import { GlobalColors } from '../../assets/GlobalColors';
import useMediaQuery from '../../hooks/useMediaQuery';
import Image from 'react-bootstrap/Image';
import check from '../../assets/images/YourCloche/check.svg';
import BottomBotton from '../../components/General/BottomBotton';
import { getLastOrderNumber, handleAddOrder } from '../../axios/order';
import Logo from '../../assets/YourCloche/Logo.svg';
import { Button } from 'react-bootstrap';
import uuid from 'react-uuid';
import { notify } from '../../utils/notify'
const YourCloche = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileS = useMediaQuery('(max-width: 320px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isIphoneX = useMediaQuery('(max-width: 390px)');
  const isIphone = useMediaQuery('(max-width: 414px)');
  const isLaptop = useMediaQuery('(max-width: 1024px)');

  const productsInCloche = useSelector(selectProductsInCloche);
  const subTotal = useSelector(selectSubTotal);
  const tableNum = useSelector(selectTableNum);
  const isProductsInClocheEmpty = useSelector(selectIsProductsInClocheEmpty);
  const isFoodTruck = useSelector(selectIsFoodTruck);
  const locationType = useSelector(selectLocationType);
  const buttonText = isFoodTruck ? "Checkout" : "Place your order"
  const restaurantName = useSelector(selectRestaurantName);
  const locationId = useSelector(selectLocationId);

  const [buttonContent, setButtonContent] = React.useState(
    <div
      style={{
        color: 'white',
        fontSize: '16px',
      }}
    >
      {buttonText}
    </div>
  );
  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const createOrder = async () => {
    const lastOrderNumber = await getLastOrderNumber({ locationId });
    let newOrder = {
      id: uuid(),
      items: productsInCloche,
      subTotal: Math.round(subTotal * 100) / 100,
      status: isFoodTruck ? 'Waiting for payment' : 'In preparation',
      idTable: tableNum,
      businessName: restaurantName,
      isForFoodTruck: isFoodTruck && locationType == "Foodtruck",
      locationId,
      orderNumber: lastOrderNumber
    };

    try {
      await handleAddOrder(newOrder);
      dispatch(addOrder(newOrder));
    } catch (error) {
      console.error("Error creating order:", error);
      notify('Failed to create order', 'error');
    }
  };

  const createOrderFlow = async () => {
    try {
      await createOrder();
      setButtonDisabled(false);
      dispatch(resetProductsInCloche());
      navigate('/your-order');
    } catch (error) {
      console.error("Error in createOrderFlow:", error);
    }
  };
  const handleButtonContent = () => {
    setButtonDisabled(true);
    setButtonContent(
      <Spinner size="sm" animation="border" style={{ width: '1rem', height: '1rem', color: 'white' }} />
    );
    setTimeout(() => {
      setButtonContent(<Image src={check} />);
      setTimeout(() => {
        if (!isFoodTruck) {
          dispatch(setOrderOverviewModalState(true));
          setTimeout(() => {
            dispatch(setOrderOverviewModalState(false));
            createOrderFlow();
          }, 3500);
        } else {
          createOrderFlow();
        }
      }, 1000);
    }, 1500);
  };

  const handleButton = () => {
    navigate('/Home');
  };

  const handleAskAssistance = () => {
    dispatch(setHelpModalState(true));
  };


  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Title title="Your Cloche" />

      {isProductsInClocheEmpty ? (
        <div
          style={{
            width: isMobileS || isMobileM || isMobileL || isIphoneX || isIphone
              ? '95%'
              : isTablet || isLaptop
                ? '70%'
                : '65%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '120px',
          }}
        >
          <Image
            src={Logo}
            style={{
              width: '100%',
              height: '65px',
            }}
          />

          <div
            style={{
              color: GlobalColors.DARK,
              fontSize: '16px',
              marginTop: '40px',
            }}
            className="text-title"
          >
            No items in your Cloche
          </div>

          <Button
            onClick={handleButton}
            style={{
              border: 'none',
              backgroundColor: 'transparent',
              color: GlobalColors.CLOCHE_RED,
              fontSize: '16px',
              marginTop: '5px',
            }}
            className="text-title"
          >
            Go back to see menu
          </Button>
        </div>
      ) : (
        <div
          style={{
            marginBottom: '200px',
            width: isMobileS || isMobileM || isMobileL || isIphoneX || isIphone
              ? '95%'
              : isTablet || isLaptop
                ? '70%'
                : '65%',
            height: 'auto',
          }}
        >
          <div
            style={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {productsInCloche.map((product, index) => {
              return (
                <FoodItemsCloche
                  key={index}
                  id={product.id}
                  pic={product.imageUrl}
                  title={product.name}
                  price={product.price}
                  totalPrice={product.totalPrice}
                  quantity={product.quantity}
                  buttonDisabled={buttonDisabled}
                  extraItems={product.extraItems}
                  notes={product.notes}
                />
              );
            })}
          </div>

          <div
            style={{
              width: '100%',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '95%',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: '20px',
                fontSize: isMobileS
                  ? '14px'
                  : isMobileM
                    ? '16px'
                    : isMobileL
                      ? '18px'
                      : isTablet
                        ? '22px'
                        : isLaptop
                          ? '24px'
                          : '26px'
              }}
            >

              <div
                className="text-title"
                style={{
                  color: GlobalColors.BLACK,
                }}
              >
                Subtotal
              </div>
              <div
                className="text-title"
                style={{
                  color: GlobalColors.BLACK,
                }}
              >

                <PriceFormat price={subTotal} />
              </div>
            </div>
          </div>
          <div
            className="text-title"
            style={{
              color: GlobalColors.GRAY,
              fontSize: '10px',
              marginTop: '10px',
              textAlign: 'end',
              display: 'flex-end',
              justifyContent: 'space-around',
            }}
          >
            delete items by changing <i>quantity</i> to <i>0</i>
          </div>
        </div>
      )}

      {!isFoodTruck &&
        <Button
          onClick={handleAskAssistance}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            position: 'fixed',
            bottom: isProductsInClocheEmpty ? '85px' : '165px',
            right: '10px',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            backgroundColor: GlobalColors.CLOCHE_RED,
            transition: 'all 0.5s ease'
          }}
        >
          <MdOutlineContactSupport
            style={{
              width: '25px',
              height: '25px',
            }}
          />
        </Button>
      }

      {isProductsInClocheEmpty ? null : (
        <BottomBotton
          buttonWidth={
            isMobileS || isMobileM || isMobileL || isIphoneX || isIphone
              ? '95%'
              : isTablet || isLaptop
                ? '70%'
                : '65%'
          }
          buttonMB="100px"
          buttonDisabled={buttonDisabled}
          buttonClick={handleButtonContent}
        >
          {buttonContent}
        </BottomBotton>
      )}
    </div>
  );
};

export default YourCloche;
