import React, { useEffect } from 'react';
import { Button, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { GrClose } from 'react-icons/gr';

import {
  selectOrderCheckoutModalState,
  setOrderCheckoutModalState,
  setOrderPaymentMethodState,
  setStripeModalState
} from '../../Redux/slices/ModalsSlices';
import { selectOrdersListTotal } from '../../Redux/slices/ProductsSlices';
import { GlobalColors } from '../../assets/GlobalColors';
import { FiChevronRight } from 'react-icons/fi';
import PriceFormat from '../General/PriceFormat';
import customImages from '../../utils/customImages';
import clocheRed from '../../assets/clocheRed.png';
const OrderCheckoutModal = () => {
  const dispatch = useDispatch();

  const orderCheckoutModalState = useSelector(selectOrderCheckoutModalState);

  const total = useSelector(selectOrdersListTotal);
 
  const divWidth = () => {
    return '100%'
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setOrderCheckoutModalState(false));
      dispatch(setOrderPaymentMethodState(false));
    }, 300);

    return () => clearTimeout(timer);
  }, [dispatch]);

  const handlePayment = () => {
    dispatch(setOrderCheckoutModalState(false))
    dispatch(setStripeModalState(true))
  }

  return (
    <div 
      style={{
        backgroundColor: GlobalColors.GRAY_MODAL_BACKGROUND,
        display: orderCheckoutModalState ? 'flex' : 'none',
        position: 'fixed',
        top: '0',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
      }}
    >
      <motion.div
        style={{
          position: 'fixed',
          bottom: '0',
          overflow: 'hidden',
          width: '100%',
          height: 'auto',
          backgroundColor: GlobalColors.WHITE,
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          display: orderCheckoutModalState ? 'flex' : 'none',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '10px',
          transition: 'all 5s ease-in-out',
          border: `1px solid ${GlobalColors.BORDER_BOTTOM_GRAY_2}`,
          padding: '15px',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <div
          style={{
            width: divWidth(),
            height: '45px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: `1px solid ${GlobalColors.BORDER_BOTTOM_GRAY_2}`,
          }}
        >
          <div
            style={{
              fontSize: '16px',
              color: GlobalColors.BLACK,
            }}
            className="text-title"
          >
            Checkout
          </div>

          <Button
            onClick={() => {
              dispatch(setOrderCheckoutModalState(false));
            }}
            
            style={{
              width: 'auto',
              height: 'auto',
              backgroundColor: 'transparent',
              border: 'none',
              position: 'fixed',
              right: '10px',
            }}
          >
            <GrClose
              style={{
                width: '15px',
                height: '15px',
              }}
              color={GlobalColors.BLACK}
            />
          </Button>
        </div>

        <div
          style={{
            width: divWidth(),
            height: '45px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${GlobalColors.BORDER_BOTTOM_GRAY_2}`,
          }}
        >
          <div
            style={{
              fontSize: '16px',
              color: GlobalColors.BLACK,
            }}
            className="text-title"
          >
            Total
          </div>

          <div
            style={{
              fontSize: '16px',
              color: GlobalColors.BLACK,
            }}
            className="text-title"
          >
            <PriceFormat price={total} />
          </div>
        </div>

        <div
          style={{
            width: divWidth(),
            height: '70px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: '14px',
              color: GlobalColors.BLACK,
            }}
            className="text-normal"
          >
            Payment
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px',
            }}
          >
            <img alt="moneycash" src={customImages.moneyCash} />
            <div
              style={{
                fontSize: '14px',
                color: GlobalColors.BLACK,
              }}
              className="text-normal"
            >
              Physical Payment
            </div>
            <button
              onClick={() => {
                dispatch(setOrderCheckoutModalState(false));
                dispatch(setOrderPaymentMethodState(true));
              }}
              style={{
                border: 'none',
                backgroundColor: 'transparent',
              }}
            >
              <FiChevronRight size={22} />
            </button>
          </div>
        </div>

        <div
          style={{
            width: divWidth(),
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '10px',
            padding: '15px',
            borderRadius: '12px',
            backgroundColor: GlobalColors.GRAY_CHECKOUT_BACKGROUND,
            border: '1px solid',
            borderColor: GlobalColors.GRAY_CHECKOUT_BORDER
          }}
        >
          <div
            style={{
              fontSize: '16px',
              color: GlobalColors.BLACK,
            }}
            className="text-title"
          >
            Get a personalized experience!
          </div>

          <div
            style={{
              fontSize: '14px',
              color: GlobalColors.BLACK,
              textAlign: 'center',
              width: '70%',
            }}
            className="text-normal"
          >
            Order faster, cheaper and easier from your favorite restaurants
          </div>

          <Image
            src={clocheRed}
            style={{
              width: '80px',
              height: '80px',
              borderRadius: '20%',
            }}
          />

          <div
            style={{
              fontSize: '14px',
              color: GlobalColors.CLOCHE_RED,
              textDecoration: 'underline',
            }}
            className="text-title"
          >
            Download the Cloche app
          </div>
        </div>

        <Button
          onClick={() => handlePayment()}
          style={{
            fontSize: '16px',
            borderRadius: '12px',
            backgroundColor: GlobalColors.CLOCHE_RED,
            color: GlobalColors.WHITE,
            height: '50px',
            width: '100%',
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <div className="text-title">Pay</div>
        </Button>
      </motion.div>
    </div>
  );
};

export default OrderCheckoutModal;
