import { useState, forwardRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { updatePaymentIntent } from '../../axios/order';
import { setStripeModalState, setThankYouModalState } from '../../Redux/slices/ModalsSlices';
import { setResetListOrderSelected, setOrdersListTip, selectPaymentIntentId, selectIsFoodTruck, selectBusinessId } from '../../Redux/slices/ProductsSlices'
import './Modals.css'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CheckoutForm({isDoneFunction}) {
  const businessId = useSelector(selectBusinessId);
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const paymentIntentId = useSelector(selectPaymentIntentId);
  const isFoodTruck = useSelector(selectIsFoodTruck)


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}`,
      },
      redirect: 'if_required'
    }).then(function(result) {
      if (result.error) {
        setOpenError(true);
        setMessage(result.error.message);
      } else {
        let paymentIntentData = {
          paymentIntentId: paymentIntentId,
          name: name,
          email: email,
          isFoodTruck: isFoodTruck,
          phone: phone,
          businessId: businessId
        }
        setOpenSuccess(true);
        isDoneFunction(true);
        dispatch(setStripeModalState(false))
        if(!isFoodTruck) dispatch(setResetListOrderSelected());
        
        updatePaymentIntent(paymentIntentData)
        dispatch(setOrdersListTip(0))
        setTimeout(() => {
          if(isFoodTruck){
            dispatch(setThankYouModalState(true))
            navigate('/your-order');
          } else {
            navigate('/Home');
          }
        }, 6000);
      }
    });
    setIsProcessing(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };

  const checkInput = (e) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setPhone(onlyDigits);
  };

  return (
    <Form id="payment-form" onSubmit={handleSubmit}>       
      <FormGroup className="col-xs-12 col-md-6 Custom-Input">         
        <FormLabel className="Input-Container">Name</FormLabel>         
        <FormControl type="text" required value={name} onChange={event => setName(event.target.value)}  />       
      </FormGroup>       
      <FormGroup className=" mt-2 col-xs-12 col-md-6 Custom-Input">        
        <FormLabel className="Input-Container">Email</FormLabel>         
        <FormControl type="email" required value={email} onChange={event => setEmail(event.target.value)} />       
      </FormGroup>
      {isFoodTruck && 
        <FormGroup className=" mt-2 col-xs-12 col-md-6 Custom-Input">        
          <FormLabel className="Input-Container">Phone</FormLabel>         
          <FormControl type="tel" maxLength={"10"} required value={phone} onChange={event =>checkInput(event)} />         
        </FormGroup>
      }
      <div>
        <PaymentElement className="mt-2" id="payment-element"  />
        <div className="Button-Container mt-5">
          <button className="Pay-Button mb-4" disabled={isProcessing || !stripe || !elements} id="submit">
            <span id="button-text">
              {isProcessing ? "Processing ... " : "Pay"}
            </span>
          </button>
        </div>
      </div>
      <Snackbar  style={{marginBottom: '20%'}} open={openSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%' }}>
          Payment done successfully!
        </Alert>
      </Snackbar>
      <Snackbar style={{marginBottom: '20%'}} open={openError} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>     
    </Form> 
  );
}

