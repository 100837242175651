import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';

import useMediaQuery from '../../hooks/useMediaQuery';
import { GlobalColors } from '../../assets/GlobalColors';
import PriceFormat from '../../components/General/PriceFormat';
import OrderInfoItem from '../../components/OrderInfo/OrderInfoItem';

const OrderInfo = () => {
  const isIphone = useMediaQuery('(max-width: 767px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isTablet = useMediaQuery('(max-width: 768px)');

  const orderInfoDummyData = {
    orderNumber: '0001',
    restaurantName: 'Cascabel Mexican Vegan',
    orderDate: 'Nov 15, 2022 - 9:41',
    orderStatus: 'In preparation',
    items: [
      {
        id: 1,
        pic: ImageData[0],
        title: 'Specialty Tostada Plater',
        price: 19.99,
        description: 'Lorem ipsum dolor sit amet, consectetur adipscing elit. Vestibulum vel dictum risus.',
        kind: 'Plates',
        quantity: 1,
        unitPrice: 19.99,
      },
      {
        id: 2,
        pic: ImageData[1],
        title: 'Las Enchiladas!',
        price: 29.98,
        description: 'Quisque sit amet neque vitae purus ullamcorper congue in vitae metus.',
        kind: 'Apetizzers',
        quantity: 2,
        unitPrice: 14.99,
      },
    ],
    subtotal: 69.96,
  };

  return (
    <div
      style={{
        marginTop: isIphone ? '20px' : '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: isMobileM || isMobileL || isTablet
            ? '95%'
            : '50%',
          height: '50px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <button
          style={{
            border: 'none',
            backgroundColor: 'transparent',
          }}
        >
          <FiChevronLeft size={22} />
        </button>

        <div
          style={{
            width: '90%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: '',
            fontWeight: 'bold',
            fontSize: '16px',
          }}
        >
          Order {orderInfoDummyData.orderNumber}
        </div>
      </div>

      <div
        style={{
          width: isMobileM || isMobileL || isTablet
            ? '95%'
            : '50%',
          height: '80px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            fontFamily: '',
            fontWeight: 'bold',
            fontSize: '20px',
          }}
        >
          {orderInfoDummyData.restaurantName}
        </div>

        <div
          style={{
            fontFamily: '',
            fontWeight: 'bold',
            fontSize: '14px',
          }}
        >
          {orderInfoDummyData.orderDate}
        </div>
      </div>

      <div
        style={{
          width: isMobileM || isMobileL || isTablet
            ? '95%'
            : '50%',
          height: '30px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '10px',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            fontFamily: '',
            fontSize: '14px',
          }}
        >
          Order status:
        </div>

        <div
          style={{
            width: '120px',
            height: '80%',
            backgroundColor:
              orderInfoDummyData.orderStatus === 'In preparation'
                ? GlobalColors.ORDER_STATUS_INPREPARATION_BG
                : GlobalColors.ORDER_STATUS_READY_BG,
            color:
              orderInfoDummyData.orderStatus === 'In preparation'
                ? GlobalColors.ORDER_STATUS_INPREPARATION_COLOR
                : GlobalColors.BLACK,
            fontFamily: '',
            fontSize: '14px',
            borderRadius: '8px',
          }}
        >
          {orderInfoDummyData.orderStatus}
        </div>
      </div>

      <div
        style={{
          width: isMobileM || isMobileL || isTablet
            ? '95%'
            : '50%',
          height: '30px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '10px',
          marginBottom: '10px',
          fontFamily: '',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        {orderInfoDummyData.items.length} items
      </div>

      <div
        style={{
          width: isMobileM || isMobileL || isTablet
            ? '95%'
            : '50%',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: '2px',
          marginBottom: '10px',
          fontFamily: '',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        {orderInfoDummyData.items.map((item, idx) => {
          return (
            <OrderInfoItem
              key={idx}
              title={item.title}
              price={<PriceFormat price={item.price} />}
              quantity={item.quantity}
            />
          );
        })}
      </div>

      <div
        style={{
          width: isMobileM || isMobileL || isTablet
            ? '95%'
            : '50%',
          height: '30px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '10px',
          fontFamily: '',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        <div>Subtotal</div>
        <PriceFormat price={orderInfoDummyData.subtotal} />
      </div>
    </div>
  );
};

export default OrderInfo;
