import React from 'react';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState, useMemo } from 'react';
import { addGlobalMenu, updateGlobalMenu, isGlobalMenuDuplicated } from '../../../../axios/globalMenu';
import { ToggleSwitch } from '../../../../components/General/ToggleSwitch';
import { getGlobalProducts } from '../../../../axios/globalProduct';
import { getLocalProducts } from '../../../../axios/localProduct';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import uuid from 'react-uuid';
import { addLocalMenu, updateLocalMenu, isLocalMenuDuplicated } from '../../../../axios/localMenu';
import LoadingSpinner from '../../../../components/Modals/LoadingSpinner';
import ProductDetail from '../../Product/Detail/ProductDetail';
import { REACT_APP_API_URL } from '../../../../utils/envConfig';
import { notify } from '../../../../utils/notify';
import Pencil from '../../../../assets/NavBar Icons/Pencil.svg'
import NoImage from '../../../../assets/NoImage.png'
import { BsPlus } from 'react-icons/bs';
const MenuDetail = () => {
	const location = useLocation();
	const { menu, action, userType } = location.state;
	const { locationId, businessId } = menu;
	const [newMenu, setNewMenu] = useState({
		name: '',
		description: '',
		selectedDays: [],
		startingHour: '',
		endingHour: '',
		status: true,
		isGlobal: true,
		detail: []
	});
	const [isLoading, setIsLoading] = useState(false);
	const [loadingLabel, setLoadingLabel] = useState('Loading...');
	const [productImages, setProductImages] = useState({})
	const [state, setState] = useState([[{}], [{}], [{}]]);
	const [currentProduct, setCurrentProduct] = useState({});
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const customSelectStyles = {
		control: (provided) => ({
			...provided,
			minHeight: '48px',
			maxHeight: '150px',
			width: '300px',
			border: '1px solid rgba(31, 31, 31, 0.2)',
			paddingInline: '10px',
			borderRadius: '8px',
			overflowY: 'auto',
		}),
		multiValue: (provided) => ({
			...provided,
			maxWidth: '90%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}),
		multiValueLabel: (provided) => ({
			...provided,
			maxWidth: 'calc(100% - 10px)',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		}),
		menu: (provided) => ({
			...provided,
			zIndex: 10
		})
	};


	const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

	const menuHeader = {
		0: 'Products',
		1: 'Categories',
		2: 'Menu',
	}

	const navigate = useNavigate();

	useEffect(() => {
		setNewMenu({ ...newMenu, ...menu })
		getProducts()
	}, []);

	const memoizedSelectedDaysValue = useMemo(() => {
		return newMenu.selectedDays.map((day) => ({ value: day, label: day }));
	}, [newMenu.selectedDays]);

	const getProducts = async () => {
		setIsLoading(true);
		await Promise.all([
			getGlobalProducts({ businessId }),
			getLocalProducts({ locationId }),
		])
			.then(async response => {
				const localProducts = response[1].map(item => ({
					...item,
					isGlobal: false
				}))
				const globalProducts = response[0].map(item => ({
					...item,
					isGlobal: true
				}))
					.filter(globalProduct => !localProducts.some(localProduct => localProduct.productId === globalProduct.id))

				const _state = Array.from(state);
				_state[0] = globalProducts.concat(localProducts)

				await getProductImages(_state[0])

				if (menu.detail) {
					const menuDetail = menu.detail.map(item => {
						if (item.isCategory) {
							return {
								...item,
								id: uuid(),
								editable: false
							}
						} else {
							return _state[0].find(product => (product.productId === item.id || product.id === item.id))
						}
					})
					_state[2] = menuDetail
					_state[0] = _state[0].filter(item => !menuDetail.some(p => p.id === item.id))
				}

				_state[0].push({ name: '' })
				setState(_state);
				setIsLoading(false);
			})
			.catch(error => {
				notify('Unable to read data from database', 'error');
				console.log(error);
				setIsLoading(false);
			});
	};

	const getProductImages = async products => {
		const lastIndex = REACT_APP_API_URL.lastIndexOf('cloche-');
		const env = REACT_APP_API_URL.substring(lastIndex).replace('-', 'web').replace('testing', 'testin')

		let _productImages = []
		await Promise.all(products.map(async product => {
			const imageUrlWithoutExt = `https://${env}.blob.core.windows.net/${product.businessId}/${product.isGlobal ? '' : product.locationId + '/'}products/${product.id}`;
			const imageExtensions = ['.png'];
			for (const extension of imageExtensions) {
				const imageUrl = `${imageUrlWithoutExt}${extension}`;
				if (await imageExists(imageUrl)) {
					_productImages[product.id] = imageUrl
				}
			}

		})).catch(error => {
			console.log(error)
			notify('Error loading images')
		})
		setProductImages(_productImages)
	}

	const imageExists = async url => {
		try {
			const res = await fetch(url, { method: 'HEAD' });
			return res.status === 200 ? true : false
		} catch (error) {
			return false;
		}
	}

	const validateData = () => {
		if (
			Object.values(newMenu).some((x) => x === '') ||
			newMenu.startingHour === '' ||
			newMenu.endingHour === ''
		) {
			notify('Please fill all fields', 'warning');
			return false;
		}

		if (newMenu.startingHour >= newMenu.endingHour) {
			notify('The ending hour must be after the starting hour', 'warning');
			return false;
		}

		return true;
	};
	const isDuplicate = async () => {
		try {
			const params = {
				selectedDays: newMenu.selectedDays || [],
				startingHour: newMenu.startingHour || '',
				id: action === 'edit' ? newMenu.id : false
			};

			if (newMenu.isGlobal) {
				const result = await isGlobalMenuDuplicated(params);
				console.log("global", result)
				if (result.isDuplicate) notify(result.message, 'warning');
				return result.isDuplicate;
			} else {
				const result = await isLocalMenuDuplicated(params);
				console.log("local", result)
				if (result.isDuplicate) notify(result.message, 'warning');
				return result.isDuplicate;
			}
		} catch (error) {
			console.error("Error checking duplication", error);
			return true;
		}
	};


	const handleMenu = ({ value, field }) => {
		const newMenuCopy = { ...newMenu, [field]: value };
		setNewMenu(newMenuCopy);
	};

	const addCategory = async () => {
		const _state = Array.from(state)
		_state[1].push({ id: uuid(), name: 'New Category', isCategory: true })
		setState(_state)
	};

	const updateField = (ind, index, value, field) => {
		const _state = Array.from(state)
		_state[ind][index][field] = value
		setState(_state)
	}

	const handleKeyDown = ({ ind, index, value, key }) => {
		if (key === 'Enter') {
			updateField(ind, index, value, 'name')
			updateField(ind, index, false, 'editable')
		}
	}

	const move = (source, destination, droppableSource, droppableDestination) => {
		const sourceClone = Array.from(source);
		const destClone = Array.from(destination);
		const [removed] = sourceClone.splice(droppableSource.index, 1);

		destClone.splice(droppableDestination.index, 0, removed);

		const result = {};
		result[droppableSource.droppableId] = sourceClone;
		result[droppableDestination.droppableId] = destClone;

		return result;
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = result => {
		const { source, destination, draggableId } = result;
		if (!destination) return

		const sourceId = +source.droppableId;
		const destinationId = +destination.droppableId;

		const item = state[0].find(item => item.id === draggableId) ||
			state[1].find(item => item.id === draggableId) ||
			state[2].find(item => item.id === draggableId)
		if (destinationId === 1 && !item.isCategory) {
			notify('Move product to menu section', 'warning');
			return
		}
		if (destinationId === 0 && item.isCategory) {
			notify('Move category to menu section', 'warning');
			return
		}

		if (sourceId === destinationId) {
			const items = reorder(state[sourceId], source.index, destination.index);
			const newState = [...state];
			newState[sourceId] = items;
			setState(newState);
		} else {
			const result = move(state[sourceId], state[destinationId], source, destination);
			const newState = [...state];
			newState[sourceId] = result[sourceId];
			newState[destinationId] = result[destinationId];

			setState(newState.filter(group => group.length));
		}
	}

	const handleEditProduct = ({ item, ind, index }) => {
		if (item.isCategory) {
			updateField(ind, index, true, 'editable')
		} else {
			setCurrentProduct({ item, ind, index })
			setIsPopupOpen(true)
		}
	}

	const updateProduct = newProduct => {
		const { ind, index } = currentProduct
		const _state = Array.from(state)
		_state[ind].splice(index, 1, newProduct)
	}

	const saveMenu = async () => {
		if (!validateData() || await isDuplicate()) {
			return;
		}

		setLoadingLabel('Saving menu...')
		setIsLoading(true)
		try {
			const _newMenu = newMenu.isGlobal && userType === 'manager'
				? {
					...newMenu,
					menuId: newMenu.id,
					locationId,
					isGlobal: false,
					businessId: null
				}
				: newMenu

			let categoryName
			_newMenu.detail = state[2].map(item => {
				if (!item) return null
				if (item.isCategory) {
					categoryName = item.name
					return { name: item.name, isCategory: item.isCategory }
				}
				else if (item.id) return { id: item.id, isGlobal: item.isGlobal, categoryName }
				else return null
			}).filter(item => item !== null)

			if (userType === 'admin') {
				await (action === 'create' ? addGlobalMenu : updateGlobalMenu)(_newMenu)
			}
			else {
				await (newMenu.isGlobal ? addLocalMenu : updateLocalMenu)(_newMenu)
			}
			notify(`Menu ${action === 'create' ? 'created' : 'edited'} successfully`, 'success')
			navigate('/menus');
			setIsLoading(false)
		} catch (error) {
			notify('Error saving data', 'error')
			console.log(error);
			setIsLoading(false)
		}
	};

	return (
		<>
			<LoadingSpinner
				isLoading={isLoading}
				label={loadingLabel}
			/>
			{currentProduct && isPopupOpen && <ProductDetail
				handleClosePopup={() => setIsPopupOpen(false)}
				product={currentProduct.item}
				action='edit'
				userType={userType}
				response={newProduct => updateProduct(newProduct)}
			/>}
			<div className='main'>
				<header>{action === 'create' ? 'New Menu' : 'Edit Menu'}</header>
				<div className='pop-up-row-wrap'>
					<div className="pop-up-col">
						<div className='pop-up-row'>Name</div>
						<div className='pop-up-row'>
							<input
								className='input'
								defaultValue={newMenu.name}
								onChange={event => handleMenu({ value: event.target.value, field: 'name' })}
							/>
						</div>
					</div>
					<div className="pop-up-col">
						<div className='pop-up-row'>Days</div>
						<div className='pop-up-row'>
							<Select
								isMulti
								closeMenuOnSelect={false}
								value={memoizedSelectedDaysValue}
								options={daysOfWeek.map((day) => ({ value: day, label: day }))}
								onChange={(selectedOptions) => {
									handleMenu({ value: selectedOptions.map((option) => option.value), field: 'selectedDays' });
								}}
								styles={customSelectStyles}
							/>
						</div>
					</div>
					<div className="pop-up-col">
						<div className='pop-up-row'>Starting Hour</div>
						<div className='pop-up-row'>
							<input
								className='input'
								type="time"
								value={newMenu.startingHour}
								onChange={event => handleMenu({ value: event.target.value, field: 'startingHour' })}
							/>
						</div>
					</div>
					<div className="pop-up-col">
						<div className='pop-up-row'>Ending Hour</div>
						<div className='pop-up-row'>
							<input
								className='input'
								type="time"
								value={newMenu.endingHour}
								onChange={event => handleMenu({ value: event.target.value, field: 'endingHour' })}
							/>
						</div>
					</div>
					<div className="pop-up-col">
						<div className='pop-up-row'>Status</div>
						<div className='pop-up-row'>
							<ToggleSwitch
								label='Status'
								handleCheckBox={event => { }}
								defaultChecked={menu.status}
								disabled={true}
							/>
						</div>
					</div>
				</div>
				<div className='pop-up-row-wrap'>
					<div className="pop-up-col" style={{ width: '100%' }}>
						<div className='pop-up-row'>Description</div>
						<div className='pop-up-row'>
							<textarea
								className="menu-text-area"
								defaultValue={newMenu.description}
								onChange={event => handleMenu({ value: event.target.value, field: 'description' })}
							/>
						</div>
					</div>
				</div>
				<div className='dragDropContext'>
					<DragDropContext onDragEnd={onDragEnd}>
						{state?.map((el, ind) => (
							<Droppable key={ind} droppableId={`${ind}`}>
								{(provided, snapshot) => (
									<div className='droppable'>
										{menuHeader[ind]}
										<div
											className={`droppable-list
											${snapshot.isDraggingOver && 'isDraggingOver'} `}
											ref={provided.innerRef}
											{...provided.droppableProps}
										>
											{el.map((item, index) => (
												<>
													{item?.id &&
														<Draggable
															key={item.id}
															draggableId={item.id}
															index={index}
														>
															{(provided, snapshot) => (
																<div
																	className={`draggable-item ${snapshot.isDragging && 'isDragging'} ${item.isCategory && 'category-draggable-item'} `}
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																>
																	<div
																		className={`${item.isCategory ? 'category-item' : 'product-item'}`}
																		onDoubleClick={() => updateField(ind, index, true, 'editable')}
																	>
																		{!item.isCategory && <img style={{ height: '40px', width: '50px' }} src={productImages[item.id] || NoImage} alt="No image available" />}
																		{state[ind][index].editable ?
																			<input
																				className='input-text'
																				type="text"
																				defaultValue={item.name}
																				onKeyDown={event => handleKeyDown({ ind, index, value: event.target.value, key: event.key })}
																				onBlur={event => handleKeyDown({ ind, index, value: event.target.value, key: 'Enter' })}
																				onLostPointerCapture={() => updateField(ind, index, true, 'editable')}
																			/>
																			: <div className='item-tag'>
																				{item.name}
																			</div>
																		}
																		<div>
																			<img
																				src={Pencil}
																				className='menu-edit-icon'
																				onClick={() => handleEditProduct({ item, ind, index })}
																			/>
																		</div>
																	</div>
																</div>
															)}
														</Draggable>
													}
												</>
											))}
											{provided.placeholder}
											{ind === 1 &&
												<div
													className='add-category'
													onClick={() => addCategory()}>
													<BsPlus
														className='menu-plus-icon' />
													Add new category
												</div>
											}
										</div>
									</div>
								)}
							</Droppable>
						))}
					</DragDropContext>
				</div>
				<div className='row detail-row center'>
					<button
						className='btn-lg btn-cancel m-3'
						onClick={() => navigate('/menus')}>
						Cancel
					</button>
					<button
						className='btn-lg btn-confirm m-3'
						onClick={saveMenu}>
						Save
					</button>
				</div>
			</div>
		</>
	);
};

export default MenuDetail;
