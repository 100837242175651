import './ToggleSwitch.css'

export const ToggleSwitch = ({
  label,
  handleCheckBox,
  defaultChecked,
  isDisabled,
}) => {

  return (
    <div className='toggle-button-cover'>
      <div className='button-cover'>
        <div className="button">
          <input
            type='checkbox'
            className='checkbox'
            disabled={isDisabled}
            name={label}
            id={label}
            defaultChecked={defaultChecked}
            onChange={event => handleCheckBox(event.target.checked)}
          />
          <div className="knobs"></div>
          <div className="layer">On ‎ ‎ ‎ ‎‎‎‎‎ ‎‎ ‎  Off</div>
        </div>
      </div>
    </div>
  )
}

export default ToggleSwitch
