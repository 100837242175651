import React from 'react';
import { useEffect, useState } from 'react';
import { addStation, updateStation } from '../../../../axios/station';
import { ToggleSwitch } from '../../../../components/General/ToggleSwitch';
import LoadingSpinner from '../../../../components/Modals/LoadingSpinner';
import { notify } from '../../../../utils/notify';

const StationDetail = (props) => {
	const {
		handleClosePopup,
		station,
		action,
		isMenuCollapsed,
		response
	} = props
	const [newStation, setNewStation] = useState({
		name: '',
		type: '',
		status: true,
		locationId: ''
	});
	const stationTypes = [
		{ value: 'kitchen', name: 'Kitchen' },
		{ value: 'bar', name: 'Bar' },
		{ value: 'service', name: 'Service' },
	];
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setNewStation({ ...newStation, ...station });
	}, []);

	const handleStationData = async ({ value, field }) => {
		setNewStation({
			...newStation,
			[field]: value,
		});
	};

	const validateData = () => {
		if (Object.values(newStation).some(x => x === '')) {
			notify('Please fill all fields', 'warning')
			return;
		}
		return true
	};

	const saveStation = async () => {
		if (!(validateData())) {
			return;
		}

		setIsLoading(true)
		try {
			await (action === 'create' ? addStation : updateStation)(newStation);
			notify(`Station ${action === 'create' ? 'created' : 'edited'} successfully`, 'success')
			response()
			handleClosePopup()
			setIsLoading(false)
		} catch (error) {
			notify('Error saving data', 'error')
			console.log(error);
			setIsLoading(false)
		}
	};

	return (
		<>
			<LoadingSpinner
				isLoading={isLoading}
				label='Saving station...'
			/>
			<div className="pop-up-modal">
				<div className={`pop-up-container ${isMenuCollapsed && 'collapsed'}`}>
					<div className="pop-up-header">{action === 'create' ? 'New Station' : 'Edit Station'}</div>
					<div className="pop-up-row-wrap">
						<div className="pop-up-col">
							<div className="pop-up-row">Name</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newStation.name}
									onChange={event => handleStationData({ value: event.target.value, field: 'name' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="radio-row">Type</div>
							<div className="radio-row" >
								{stationTypes.map(_station => (
									<div className='radio-div' key={_station.id}>
										<div>
											<input
												type='radio'
												className='radio-input'
												value={_station.value}
												checked={newStation.type === _station.value}
												name='stationType'
												onChange={event => handleStationData({ value: event.target.value, field: 'type' })}
											/>
										</div>
										<div>
											{_station.name}
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">Status</div>
							<div className="pop-up-row">
								<ToggleSwitch
									label='Status'
									handleCheckBox={value => handleStationData({ value, field: 'status' })}
									defaultChecked={station.status}
								/>
							</div>
						</div>
					</div>
					<br />
					<div className="pop-up-row-wrap justify-space-between">
						<div className="pop-up-col">
							<button
								className='btn-lg btn-cancel'
								onClick={handleClosePopup}>
								Cancel
							</button>
						</div>
						<div className="pop-up-col">
							<button
								className='btn-lg btn-confirm'
								onClick={saveStation}>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StationDetail;
