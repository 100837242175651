import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { GrFormSubtract, GrFormAdd } from 'react-icons/gr';
import Image from 'react-bootstrap/Image';

import { GlobalColors } from '../../assets/GlobalColors';
import useMediaQuery from '../../hooks/useMediaQuery';
import {
  selectFoodSelected,
  selectFoodOptions,
  handleFoodSelectedQuantity,
  addProduct
} from '../../Redux/slices/ProductsSlices';
import PriceFormat from '../../components/General/PriceFormat';
import MenuCheckbox from '../General/MenuCheckbox';

const FoodItemDetail = ({ show, onHide, handleCloseAndNavigate }) => {
  const dispatch = useDispatch();
  const foodSelected = useSelector(selectFoodSelected);
  const foodOptions = useSelector(selectFoodOptions);
  const isMobileS = useMediaQuery('(max-width: 320px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptop = useMediaQuery('(max-width: 1024px)');
  const isLaptopL = useMediaQuery('(max-width: 1440px)');
  const isIphoneX = useMediaQuery('(max-width: 390px)');
  const isIphone = useMediaQuery('(max-width: 414px)');
  const [notes, setNotes] = useState('');

  const descriptionHeight = (text) => {
    let textLength = text.length;
    return textLength >= 144 ? '100px' : '50px'
  }

  const handleItem = quantity => {
    if (quantity === 1) {
      dispatch(handleFoodSelectedQuantity(1));
    } else {
      if (foodSelected.quantity > 1) {
        dispatch(handleFoodSelectedQuantity(-1));
      } else {
        onHide();
      }
    }
  };

  const handleContent = (event) => {
    setNotes(event.target.value)
  }

  const closeButtonSize = () => {
    return isMobileS
      ? '30px'
      : isMobileM || isMobileL || isTablet || isLaptop
        ? '35px'
        : '40px';
  };

  const closeButtonAiOutlineCloseSize = () => {
    return isMobileS
      ? '14px'
      : isMobileM || isTablet || isLaptop
        ? '16px'
        : '18px';
  };

  const closeButtonPositionRight = () => {
    return isMobileS || isMobileM || isMobileL || isIphoneX || isIphone
      ? '15px'
      : isTablet
        ? '50px'
        : isLaptop
          ? '120px'
          : isLaptopL
            ? '160px'
            : '485px';
  };

  const closeButtonPositionTop = () => {
    return isMobileS
      ? '0'
      : isMobileM
        ? '2px'
        : isMobileL
          ? '4px'
          : isTablet
            ? '6px'
            : isLaptop
              ? '8px'
              : '10px';
  };

  const contentDivWidth = () => {
    return isMobileS || isMobileM || isMobileL || isTablet
      ? '75%'
      : '80%';
  };

  const numItemDivWidth = () => {
    return isMobileS || isMobileM
      ? '60%'
      : isMobileL
        ? '55%'
        : isTablet || isLaptop || isLaptopL
          ? '40%'
          : '30%';
  };



  const foodSelectedTitleFontSize = () => {
    return isMobileS || isMobileM
      ? '12px'
      : isMobileL
        ? '14px'
        : isTablet
          ? '16px'
          : '18px';
  };


  const foodSelectedDescFontSize = () => {
    return isMobileS || isTablet
      ? '14px'
      : isMobileM
        ? '12px'
        : isMobileL
          ? '13px'
          : '16px';
  };

  const ImageSize = () => {
    return isMobileS ? 90 : isMobileM ? 90 : isMobileL ? 110 : 130;
  };

  const handleAddItem = () => {
    dispatch(addProduct({ ...foodSelected, notes: notes }));
    handleCloseAndNavigate();
  };

  return (
    <React.Fragment>
      <style type="text/css">
        {`
          .main-modal {
            padding-top: 10%;
            background-color: rgba(0, 0, 0, 0.2);
          }
          .main-modal .modal-content {
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            padding-top: 15px;
          }
        `}
      </style>
      <Modal show={show} fullscreen dialogClassName="main-modal" animation={true}>
        <div
          style={{
            width: '100%',
            height: '85%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            overflow: 'scroll'
          }}
        >
          <Button
            onClick={onHide}
            style={{
              position: 'absolute',
              top: closeButtonPositionTop(),
              right: closeButtonPositionRight(),
              width: closeButtonSize(),
              height: closeButtonSize(),
              borderRadius: '50%',
              border: 'none',
              backgroundColor: GlobalColors.WHITE,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              color: GlobalColors.BLACK,
              fontSize: closeButtonAiOutlineCloseSize(),
            }}
          >
            <div>x</div>
          </Button>

          <div
            style={{
              width: isMobileS || isMobileM || isMobileL
                ? '100%'
                : isTablet
                  ? '90%'
                  : isLaptop || isLaptopL
                    ? '80%'
                    : '50%',

              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 10px 0 10px',
              borderBottomLeftRadius: '20px',
              borderBottomRightRadius: '20px',
              marginTop: '10px',
            }}
          >
            <div
              style={{
                width: isMobileS || isMobileM || isMobileL
                  ? '100%'
                  : isTablet
                    ? '90%'
                    : '80%',
                height: isMobileS || isMobileM || isMobileL
                  ? '55%'
                  : '50%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px',
                backgroundColor: GlobalColors.WHITE,
              }}
            >
              {foodSelected.pic === '' || foodSelected.imageUrl === '' ? (
                <div
                  style={{
                    width: ImageSize(),
                    height: ImageSize(),
                    backgroundColor: GlobalColors.BORDER_BOTTOM_GRAY_2
                  }}
                ></div>
              ) : (
                <Image
                  alt={foodSelected.name}
                  src={foodSelected.imageUrl}
                  rounded
                  width={ImageSize() * 1.33}
                  height={ImageSize()}
                />
              )}

              <div
                style={{
                  width: isMobileS || isMobileM || isMobileL
                    ? '70%'
                    : isTablet
                      ? '75%'
                      : isLaptopL
                        ? '85%'
                        : '80%',
                  height: isMobileL
                    ? '70%'
                    : isTablet || isLaptop
                      ? '90%'
                      : '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  marginLeft: '10px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '30%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    fontSize: foodSelectedTitleFontSize(),
                    color: GlobalColors.BLACK,
                  }}
                  className="text-title"
                >
                  {foodSelected.name}
                </div>

                <div
                  style={{
                    width: '100%',
                    height: '23%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    fontSize: foodSelectedTitleFontSize(),
                    color: GlobalColors.BLACK,
                  }}
                  className="text-normal"
                >
                  <PriceFormat price={foodSelected.price} />
                </div>


              </div>
            </div>
          </div>
          <div
            style={{
              width: '90%',
              height: descriptionHeight(foodSelected.description),
              marginTop: '20px',
              marginBottom: '20px',
              fontSize: foodSelectedDescFontSize(),
              color: GlobalColors.BORDER_BOTTOM_GRAY,
              textAlign: 'justify',
              lineHeight: '16px'
            }}
            className="Description-Text"
          >
            {foodSelected.description}
          </div>
          <div
            style={{
              width: contentDivWidth(),
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '20px'
            }}
          >
            <div
              style={{
                width: numItemDivWidth(),
                fontSize: '20px',
                height: '50px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontFamily: '',
                fontStyle: 'normal',
                color: GlobalColors.BLACK,
                border: '1px solid' + GlobalColors.BORDER_BOTTOM_GRAY,
                borderRadius: '8px',
                backgroundColor: GlobalColors.WHITE,
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleItem(-1);
                }}
                style={{
                  width: '30%',
                  height: '90%',
                  border: 'none',
                  backgroundColor: GlobalColors.WHITE,
                  color: GlobalColors.BLACK,
                }}
              >
                <GrFormSubtract color={GlobalColors.BLACK} />
              </button>
              {foodSelected.quantity}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleItem(1);
                }}
                style={{
                  width: '30%',
                  height: '90%',
                  border: 'none',
                  backgroundColor: GlobalColors.WHITE,
                  color: GlobalColors.BLACK,

                }}
              >
                <GrFormAdd color={GlobalColors.BLACK} />
              </button>
            </div>
          </div>

          <div
            style={{
              fontWeight: 700,
              width: '90%',
              padding: '10px',
              height: '50%',
              justifyContent: 'center',
            }}
          >
            {foodOptions && <MenuCheckbox quantity={foodSelected.quantity} menuItems={foodOptions} handleCloseAndNavigate={handleCloseAndNavigate} notes={notes} setNotes={setNotes} />}
            {foodSelected.tags?.some((tag) => tag === 'hasAlcohol') &&
              <div style={{ fontSize: '14px', textAlign: 'center' }}>
                NO ALCOHOL WILL BE SERVED TO PATRONS UNDER 21 YEARS OF AGE
              </div>
            }
            <div className="form-group">
              <div className='Notes-Container'><label htmlFor="ordernotes">Customize your order</label></div>
              <textarea
                className="form-control"
                id="ordernotes"
                rows="5"
                onChange={handleContent}
              />
            </div>

          </div>
          {!foodOptions &&
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleAddItem();
              }}
              className='Add-Button'
              style={{
                backgroundColor: GlobalColors.CLOCHE_RED,
                color: GlobalColors.WHITE
              }}
            >
              Add {foodSelected.quantity} to cloche - <PriceFormat price={foodSelected.totalPrice} />
            </Button>}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default FoodItemDetail;
