import { REACT_APP_API_URL } from '../utils/envConfig';
import axios from 'axios';

export async function getRoles(data) {
    return new Promise(async (resolve, reject) => {
        await axios
        .post(`${REACT_APP_API_URL}/getRoles`, JSON.stringify(data))
        .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export async function getRole (roleId) {
    return new Promise(async (resolve, reject) => {
        await axios
			.post(`${REACT_APP_API_URL}/getRole`, JSON.stringify(roleId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
    });
}

export async function addRole(role) {
    return new Promise(async (resolve, reject) => {
        await axios
        .post(`${REACT_APP_API_URL}/role`,JSON.stringify(role))
        .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export async function updateRole(role) {
    return new Promise(async (resolve, reject) => {
        await axios
        .post(`${REACT_APP_API_URL}/updateRole`,JSON.stringify(role))
        .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export async function deleteRole(role) {
    return new Promise(async (resolve, reject) => {
        await axios
        .post(`${REACT_APP_API_URL}/deleteRole`,JSON.stringify(role))
        .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}
