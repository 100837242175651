import { REACT_APP_API_URL } from '../utils/envConfig';
import axios from 'axios';

export async function getGlobalProductOptions (data) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getGlobalProductOptions`, JSON.stringify(data))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function getGlobalProductOption (globalProductOptionId) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getGlobalProductOption`, JSON.stringify(globalProductOptionId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function addGlobalProductOption(globalProductOption) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/globalProductOption`, JSON.stringify(globalProductOption))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}

export async function updateGlobalProductOption(globalProductOption) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/updateGlobalProductOption`, JSON.stringify(globalProductOption))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}

export async function deleteGlobalProductOption (globalProductOptionId) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/deleteGlobalProductOption`, JSON.stringify(globalProductOptionId))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}
