import React, { useState, useEffect } from 'react';
import { getHistoricOrders } from '../../../axios/order';
import { getLocation } from '../../../axios/location';
import { getBusiness } from '../../../axios/business';
import './DataTable.css';
import Pagination from './Pagination';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { GlobalColors } from '../../../assets/GlobalColors';
import Logo from '../../../assets/General/RedClocheLogo.png';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import { notify } from '../../../utils/notify';
import OrderDetails from './OrderDetails'

const DataTable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [location, setLocation] = useState(null);
  const [business, setBusiness] = useState(null);
  const filteredData = startDate && endDate
    ? data.filter(row => {
      const rowDate = new Date(row.fullfillDate);
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setDate(end.getDate() + 1);
      return rowDate >= start && rowDate < end;
    })
    : data;
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const { locationId } = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    fetchData(startDate, endDate);
    getLocation({ locationId }).then(response => {
      setLocation(response);
      getBusiness({ businessId: response.businessId }).then(response => {
        setBusiness(response);
      })
    });
  }, []);

  const fetchData = async () => {
    try {
      if (startDate && endDate && startDate > endDate) {
        alert('Start date cannot be after end date');
        return;
      }
      setIsLoading(true)
      await getHistoricOrders({ locationId, startDate: startDate, endDate: endDate }).then(response => {
        setIsLoading(false)
        setData(response);
      }).catch(error => {
        notify('Unable to read data from database', 'error')
        console.log(error)
        setIsLoading(false)
      })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getBackgroundColor = (status) => {
    switch (status) {
      case 'Waiting for payment':
        return GlobalColors.ORDER_STATUS_INPREPARATION_BG
      case 'In preparation':
        return GlobalColors.ORDER_STATUS_INPREPARATION_BG
      case 'Delivered':
        return GlobalColors.ORDER_STATUS_DELIVERED_BG
      case 'Cooked':
        return GlobalColors.ORDER_STATUS_DELIVERED_BG
      case 'Cancelled':
        return GlobalColors.ORDER_STATUS_CANCELLED_BG
      default:
        return GlobalColors.WHITE;
    }
  }

  const getColor = (status) => {
    switch (status) {
      case 'Waiting for payment':
        return GlobalColors.ORDER_STATUS_INPREPARATION_COLOR
      case 'In preparation':
        return GlobalColors.ORDER_STATUS_INPREPARATION_COLOR
      case 'Delivered':
        return GlobalColors.ORDER_STATUS_DELIVERED_COLOR
      case 'Cooked':
        return GlobalColors.ORDER_STATUS_DELIVERED_COLOR
      case 'Cancelled':
        return GlobalColors.test
      default:
        return GlobalColors.BLACK;
    }
  }

  const handleRowClick = (order) => {
    setSelectedOrder(order);
    setShowOrderDetails(true);
  };

  const formatOrderNumber = (unFormatNum) => {
    return String(unFormatNum).padStart(4, '0');
  };

  const formatDateTime = (input) => {
    if (!input) return 'No date data';
    const date = new Date(input);
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${month} ${day}, ${year} - ${hours}:${minutes}`;
  }

  const formatTimeToMinutesAndSeconds = (timestamp) => {
    const date = new Date(timestamp);
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${minutes}:${seconds}`;
  };

  const formatMoney = (num) => {
    if (num === null || num === undefined) {
      return '';
    }
    return `$${num.toFixed(2)}`;
  };

  const exportPDF = () => {
    const doc = new jsPDF();

    doc.addImage(Logo, 17, 15, 35, 20);

    const businessName = business.name;
    const businessAddress = `${business.name}, ${business.ownerName} ${business.ownerLastName}, ${business.address}, ${business.city}, ${business.state}, ${business.country}, ${business.postalCode}`;
    const locationAddress = `${location.name}, ${location.type}, ${location.address}, ${location.city}, ${location.state}, ${location.country}, ${location.postalCode}`;
    const downloadDateTime = new Date().toLocaleString();

    doc.setFontSize(12);
    doc.text(`Downloaded: ${downloadDateTime}`, 125, 10);
    doc.text(businessName, 65, 20);
    doc.text('Business Address:', 65, 30);
    doc.text(businessAddress, 65, 35);
    doc.text('Location Address:', 65, 45);
    doc.text(locationAddress, 65, 50);

    const tableHeaders = [
      'Order',
      'Date',
      'Table',
      'Items',
      'Service Time',
      'Status',
      'Gross sale',
      'Cloche fees',
      'Tips',
      'Taxes',
      'Total Charges'
    ];

    const tableData = filteredData.map((row) => [
      formatOrderNumber(row.orderNumber),
      formatDateTime(row.fullfillDate),
      row.table,
      row.itemsCount,
      formatTimeToMinutesAndSeconds(row.timeToDo),
      row.status,
      formatMoney(row.subTotalAmount),
      formatMoney(row.serviceChargeAmount),
      formatMoney(row.tipAmount),
      formatMoney(row.taxAmount),
      formatMoney(row.totalAmount > 0 ? row.totalAmount : row.chargeAmount)
    ]);

    const totalCharges = tableData.reduce((sum, row) => sum + parseFloat(row[10].replace('$', '')), 0);
    const totalRow = ['', '', '', '', '', '', '', '', '', 'Total', formatMoney(totalCharges)];

    const styles = {
      startY: 60,
      headStyles: { fillColor: [66, 66, 66], textColor: [255, 255, 255], rowRadius: 10 },
      bodyStyles: { textColor: [0, 0, 0] },
      alternateRowStyles: { fillColor: [255, 255, 255] },
    };

    doc.autoTable({
      head: [tableHeaders],
      body: [...tableData, totalRow],
      ...styles
    });

    doc.save('orders_report.pdf');
  };

  const exportCSV = () => {
    const tableHeaders = [
      'Order',
      'Date',
      'Table',
      'Items',
      'Service Time',
      'Status',
      'Gross sale',
      'Cloche fees',
      'Tips',
      'Taxes',
      'Total Charges'
    ];

    const tableData = filteredData.map((row) => ({
      Order: formatOrderNumber(row.orderNumber),
      Date: formatDateTime(row.fullfillDate),
      Table: row.table,
      Items: row.itemsCount,
      'Service Time': formatTimeToMinutesAndSeconds(row.timeToDo),
      Status: row.status,
      'Gross sale': formatMoney(row.subTotalAmount),
      'Cloche fees': formatMoney(row.serviceChargeAmount),
      Tips: formatMoney(row.tipAmount),
      Taxes: formatMoney(row.taxAmount),
      'Total Charges': formatMoney(row.totalAmount > 0 ? row.totalAmount : row.chargeAmount)
    }));

    const csv = Papa.unparse({
      fields: tableHeaders,
      data: tableData
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'orders_report.csv');
  };

  const renderTableRows = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const filteredData = startDate && endDate
      ? data.filter(row => {
        const rowDate = new Date(row.fullfillDate);
        const start = new Date(startDate);
        const end = new Date(endDate);
        end.setDate(end.getDate() + 1);
        return rowDate >= start && rowDate < end;
      })
      : data;

    return (
      filteredData &&
      filteredData.slice(startIndex, endIndex).map((row, index) => (
        <tr onClick={() => handleRowClick(row)} key={index} className={index === filteredData.length - 1 ? 'last-row' : ''}>
          <td className='OrderNumber' >{formatOrderNumber(row.orderNumber)}</td>
          <td>{formatDateTime(row.fullfillDate)}</td>
          <td>{row.table}</td>
          <td>{row.itemsCount}</td>
          <td>{formatTimeToMinutesAndSeconds(row.timeToDo)}</td>
          <td>
            <div
              style={{
                backgroundColor: getBackgroundColor(row.status),
                color: getColor(row.status),
                width: '100%'
              }}
              className='Status'
            >
              {row.status}
            </div>
          </td>
          <td>{formatMoney(row.subTotalAmount)}</td>
          <td>{formatMoney(row.serviceChargeAmount)}</td>
          <td>{formatMoney(row.tipAmount)}</td>
          <td>{formatMoney(row.taxAmount)}</td>
          <td>{formatMoney(row.chargeAmount)}</td>
        </tr>
      ))
    );
  };

  const handlePageChange = (newPage) => {
    if (startDate && endDate && startDate > endDate) {
      alert('Start date cannot be after end date');
      return;
    }
    setCurrentPage(newPage);
  };

  return (
    <div className="table-page">
      {showOrderDetails && selectedOrder && (
        <OrderDetails
          selectedOrder={selectedOrder}
          onClose={() => setShowOrderDetails(false)}
          backgroundColor={getBackgroundColor(selectedOrder.status)}
          color={getColor(selectedOrder.status)}
          formatDate={formatDateTime}
          formatTimeToDo={formatTimeToMinutesAndSeconds}
          formatMoney={formatMoney}
        />
      )}
      <LoadingSpinner
        isLoading={isLoading}
        label='Loading Order History...'
      />
      <div style={{ display: 'flex' }}>
        <div className="container">
          <div className="inner-container">
            <div className="label-text">Start Date:</div>
          </div>
          <div className="date-container">
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              min="2000-01-01"
              max={endDate || undefined}
              className="date-input"
            />
          </div>
        </div>

        <div className="container">
          <div className="inner-container">
            <div className="label-text">End Date:</div>
          </div>
          <div className="date-container">
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              min={startDate || undefined}
              max={new Date().toISOString().split('T')[0]}
              className="date-input"
            />
          </div>
        </div>
        <div className="flex-container-end">
          <div onClick={exportPDF} className="button-container">
            <div className="icon icon-pdf"></div>
            <div className="button-label">PDF</div>
          </div>
          <div onClick={exportCSV} className="button-container">
            <div className="icon icon-csv"></div>
            <div className="button-label">CSV</div>
          </div>
        </div>
      </div>
      <div className='table-container'>
        <table className="table">
          <thead>
            <tr>
              <th style={{ borderTopLeftRadius: '10px' }}>Order</th>
              <th style={{ width: '125px' }}>Ordered</th>
              <th style={{ marginLeft: '5px' }}>Table</th>
              <th>Items</th>
              <th>Service Time</th>
              <th>Status</th>
              <th>Gross sale</th>
              <th>Cloche fees</th>
              <th>Tips</th>
              <th>Taxes</th>
              <th style={{ borderTopRightRadius: '10px' }}>Total Charges</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default DataTable;
