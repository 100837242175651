import React from 'react';
import { GlobalColors } from '../../assets/GlobalColors';

const OrderInfoItem = ({ title, price, quantity }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '60px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '10px',
        borderBottom: `1px solid ${GlobalColors.BORDER_BOTTOM_GRAY_2}`,
      }}
    >
      <div
        style={{
          backgroundColor: GlobalColors.ORDER_INFO_ITEM_CHIP,
          fontWeight: 'bold',
          borderRadius: '10px',
          width: '23px',
          height: '27px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {quantity}
      </div>

      <div
        style={{
          width: '85%',
          height: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            fontSize: '15px',
            fontWeight: 700,
            fontStyle: 'normal',
            color: `${GlobalColors.BLACK}`,
          }}
        >
          {title}
        </div>
        <div
          style={{
            fontSize: '14px',
          }}
        >
          {price}
        </div>
      </div>
    </div>
  );
};

export default OrderInfoItem;
