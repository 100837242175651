import { createSlice } from '@reduxjs/toolkit';
import uuid from 'react-uuid';
import { updateOrder, updateOrderItemStatus } from '../../axios/order';
import { askForAssistance } from '../../axios/table';
const initialState = {
  user: {
    name: '',
    id: '',
    username: '',
    idBranch: '',
    token: null,
    userType: null
  },
  ordersToDo: [],
  ordersHistory: [],
  ordersByBoard: []
};

function countTotalOrders(tables) {
  let counter = 0;
  tables.forEach(table => {
    counter += table.tableOrders.length;
  });
  return counter;
}

function setTableOrderAsDelivered(isFoodTruck, isCancelled, hasService) {
  return !isCancelled && (isFoodTruck || !hasService);
}

function findOrderAndTableOrderByItemId(state, itemId, orderType) {
  let foundOrder = null;
  let foundTableOrder = null;

  const orders = state[orderType];
  for (const order of orders) {
    for (const tableOrder of order.tableOrders) {
      const itemExists = tableOrder.items.some(item => item.id === itemId);
      if (itemExists) {
        foundOrder = order;
        foundTableOrder = tableOrder;
        return { foundOrder, foundTableOrder }; 
      }
    }
  }

  return { foundOrder: null, foundTableOrder: null };
}

export const KitchenSlices = createSlice({
  name: 'Kitchen',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSignInKitchenUser: (state, action) => {
      state.user = signIn(action.payload.userName, action.payload.password, action.payload.userType);
    },
    setSignOutKitchenUser: (state) => {
      state.user = initialState.user;
    },
    setKitchenOrders: (state, action) => {
      state.ordersToDo = action.payload;
    },
    setKitchenOrdersByBoard: (state, action) => {
      state.ordersByBoard = action.payload;
    },
    toggleTableAssistance: (state, action) => {
      const { index } = action.payload;
      state.ordersByBoard[index].needsAssistance = true;
    },
    addEmptyTable: (state, action) => {
      const { tableNum } = action.payload;
      let tableAux = {
        timestamp: 0,
        tableOrders: [],
        needsAssistance: true,
        tableNumber: tableNum
      };
      state.ordersToDo.push(tableAux);
      state.ordersByBoard.push(tableAux);
    },
    updateKitchenOrder: (state, action) => {
      const { tableOrder, status } = action.payload;
      let tableAux = Object.assign({}, tableOrder);
      tableAux.status = status;
      tableAux.served = true;
      updateOrder(tableAux);
      const indexTable = state.ordersToDo.findIndex((table) => table.tableNumber == tableOrder.idTable);
      const indexOrder = state.ordersToDo[indexTable]?.tableOrders?.findIndex((table) => tableOrder.id == table.id);
      state.ordersToDo[indexTable].tableOrders.splice(indexOrder, 1)
      const indexTableB = state.ordersByBoard.findIndex((table) => table.tableNumber == tableOrder.idTable);
      const indexOrderB = state.ordersByBoard[indexTableB]?.tableOrders?.findIndex((table) => tableOrder.id == table.id);
      state.ordersByBoard[indexTable].tableOrders.splice(indexOrderB, 1)

    },
    handelAssistance: (state, action) => {
      const { tableOrder } = action.payload
      const tableQuery = {
        id: tableOrder.tableNumber,
        toggleValue: false
      }
      askForAssistance(tableQuery)
      const indexTable = state.ordersToDo.findIndex((table) => table.tableNumber == tableOrder.idTable);
      state.ordersToDo[indexTable].needsAssistance = false;
      state.ordersByBoard[indexTable].needsAssistance = false;
    },
    setItemStatus: (state, action) => {
      const { id, status, hasService, isFoodTruck } = action.payload;

      const { foundOrder: orderToDo, foundTableOrder: tableOrder } =
        findOrderAndTableOrderByItemId(state, id, 'ordersToDo');

      const { foundOrder: orderToDoBoard, foundTableOrder: tableOrderBoard } =
        findOrderAndTableOrderByItemId(state, id, 'ordersByBoard');

      if (!tableOrder || !tableOrderBoard) {
        console.error('No tableOrder found for the given item ID:', id);
        return; 
      }

      const indexTable = state.ordersToDo.indexOf(orderToDo)
      const indexOrder = orderToDo?.tableOrders?.indexOf(tableOrder)

      const indexTableB = state.ordersByBoard.indexOf(orderToDoBoard)
      const indexOrderB = orderToDoBoard?.tableOrders?.indexOf(tableOrderBoard)

      const item = tableOrder.items.find((item) => item.id === id);
      item.status = status;

      const itemB = tableOrderBoard.items.find((item) => item.id === id);
      itemB.status = status;

      tableOrder.done = tableOrder.items.every(item => item.status !== 'In preparation')
      const isCancelled = tableOrder.items.every(item => item.status === 'Cancelled')

      updateOrderItemStatus(item)
      if (tableOrder.done || isCancelled) {
        tableOrder.status = isCancelled ? "Cancelled" : "In preparation";
        if (setTableOrderAsDelivered(isFoodTruck, isCancelled, hasService)) {
          tableOrder.status = "Delivered";
        } tableOrder.fulfillDate = new Date();
        updateOrder(tableOrder)
        state.ordersToDo[indexTable].tableOrders.splice(indexOrder, 1)
        state.ordersByBoard[indexTableB].tableOrders.splice(indexOrderB, 1)
      }
    },
  },
});

export const { setSignInKitchenUser, setSignOutKitchenUser, setItemStatus, setKitchenOrders, updateKitchenOrder, toggleTableAssistance, addEmptyTable, handelAssistance, setKitchenOrdersByBoard } = KitchenSlices.actions;

const signIn = (userName, password, userType) => {
  return {
    name: 'John Doe',
    id: uuid(),
    userName: userName,
    token: '123456789',
    userType: userType
  };
};


export const selectKitchenUserToken = (state) => state.Kitchen.user.token;
export const selectKitchenOrdersToDo = (state) => state.Kitchen.ordersToDo;
export const selectKitchenOrdersByBoard = (state) => state.Kitchen.ordersByBoard;
export const selectNumberOfOrdersToDo = (state) => countTotalOrders(state.Kitchen.ordersToDo);
export const selectUserType = (state) => state.Kitchen.user.userType;




export default KitchenSlices.reducer;
