import { REACT_APP_API_URL } from '../utils/envConfig';
import axios from 'axios';

export async function getLocalProductOptionLists (data) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getLocalProductOptionLists`, JSON.stringify(data))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function getLocalProductOptionList (localProductOptionListId) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getLocalProductOptionList`, JSON.stringify(localProductOptionListId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function addLocalProductOptionList(localProductOptionList) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/localProductOptionList`, JSON.stringify(localProductOptionList))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}

export async function updateLocalProductOptionList(localProductOptionList) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/updateLocalProductOptionList`, JSON.stringify(localProductOptionList))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}

export async function deleteLocalProductOptionList (localProductOptionListId) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/deleteLocalProductOptionList`, JSON.stringify(localProductOptionListId))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}
