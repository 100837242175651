import React from 'react';
import { Button } from 'react-bootstrap';
import { GlobalColors } from '../../assets/GlobalColors';

const BottomBotton = ({ children, buttonWidth, buttonMB, buttonDisabled, buttonClick }) => {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        buttonClick();
      }}
      style={{
        width: buttonWidth ? buttonWidth : '100%',
        marginBottom: buttonMB ? buttonMB : '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        height: '50px',
        borderRadius: '12px',
        backgroundColor: buttonDisabled ? GlobalColors.GRAY_BUTTON_BACKGROUND :  GlobalColors.CLOCHE_RED,
        color: GlobalColors.WHITE,
        position: 'fixed',
        bottom: '0',
        overflow: 'hidden',
        fontSize: '16px',
        border: 'none',
      }}
      disabled={buttonDisabled ? buttonDisabled : false}
    >
      <div className="text-title">{children}</div>
    </Button>
  );
};

export default BottomBotton;
