import { REACT_APP_SECRET_CRYPTO } from './envConfig';
import CryptoJS from 'crypto-js';

export function encryptPassword (password) {
    return CryptoJS.AES.encrypt(password, REACT_APP_SECRET_CRYPTO).toString();
}

export function decryptPassword (password) {
    const bytes = CryptoJS.AES.decrypt(password, REACT_APP_SECRET_CRYPTO)
    return bytes.toString(CryptoJS.enc.Utf8)
}

