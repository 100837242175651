export const GlobalColors = {
  ASSISTANCE_HEADER: '#E87E1C',
  CLOCHE_RED: '#B0271F',
  CLOCHE_RED_SECONDARY: '#C00415',
  YOUR_CLOCHE_NOTIFICATION: '#EA4E3D',
  BLACK: '#000000',
  GRAY: 'rgba(0, 0, 0, 0.6)',
  GRAY_1: 'rgba(0, 0, 0, 1)',
  GRAY_MODAL_BACKGROUND: 'rgba(0, 0, 0, 0.16)',
  GRAY_CHECKOUT_BORDER: 'rgba(0, 0, 0, 0.12)',
  GRAY_CHECKOUT_BACKGROUND: 'rgba(0, 0, 0, 0.04)',
  GRAY_BUTTON_BACKGROUND: 'rgba(0, 0, 0,0.3)',
  BORDER_BOTTOM_GRAY: ' #797979',
  BORDER_BOTTOM_GRAY_2: '#E5E5E5',
  COLOR_TIMER_1: '#424242',
  COLOR_TIMER_2: '#FFA726',
  COLOR_TIMER_3: '#DB0000',
  POPUP_CHECKOUT_BOTTOM_GRAY: '#CCCCCC',
  WHITE: '#FFFFFF',
  GREEN_CHECK_BUTTON: '#3CB01F',
  ORDER_STATUS_INPREPARATION_BG: '#FBF2CB',
  ORDER_STATUS_CANCELLED_BG: '#B0271F',
  ORDER_STATUS_INPREPARATION_COLOR: '#E1A96F',
  ORDER_STATUS_DELIVERED_COLOR: '#3CB01F',
  ORDER_STATUS_DELIVERED_BG: '#D8EFD2',
  ORDER_STATUS_READY_BG: '#5FAD3B',
  ORDER_INFO_ITEM_CHIP: '#00000014',
  SIGN_IN_BACKGROUND: '#353535',
  KITCHEN_SIDE_BAR: 'rgba(229, 229, 229, 1)',
  KITCHEN_BACKGROUND: 'rgb(246, 246, 246)',
  KITCHEN_ORDERCARD_BORDER: 'rgba(218, 218, 218, 1)',
  KITCHEN_DONE_ITEM: 'rgba(60, 176, 31, 1)',
  KITCHEN_NOT_DONE_ITEM: 'rgba(176, 39, 31, 1)',
  KITCHEN_TIMER_COLOR: '#D89D57',
  KITCHEN_CARDBG_COLOR: '#EFD4D2',
  test: '#fff',
};
