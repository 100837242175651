import React, { useState, useEffect } from 'react';
import { Element } from 'react-scroll';
import { useDispatch } from 'react-redux';
import { GlobalColors } from '../../assets/GlobalColors';
import OrderCard from './OrderCard';
import DoneWhite from '../../assets/KitchenItems/DoneWhite.svg';
import { getTable } from '../../axios/table'
import { Image } from 'react-bootstrap';
import { handelAssistance } from '../../Redux/slices/KitchenSlices';


const TableContainer = ({ orderToDo, isFoodTruck }) => {
  const [isAssitanceRequired, setIsAssitanceRequired] = useState(false);
  const [table, setTable] = useState();
  const [tableColor, setTableColor] = useState('');
  const dispatch = useDispatch();
  const styles = {
    container: {
      width: '290px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: '16px',
      marginLeft: '20px',
    },
    tableHeader: {
      width: '96%',
      height: '50px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: '12px',
      gap: '40px',
      padding: '12px 20px 12px 20px',
      backgroundColor: tableColor,
      color: GlobalColors.WHITE,
      fontSize: '16px',
      lineHeight: '22px',
    },
    assistanceHeader: {
      width: '96%',
      height: '50px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: '12px',
      gap: '40px',
      padding: '12px 20px 12px 20px',
      backgroundColor: GlobalColors.ASSISTANCE_HEADER,
      color: GlobalColors.WHITE,
      fontSize: '16px',
      lineHeight: '22px',
    },
    overflowEllipsis: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }

  };

  useEffect(() => {
    setIsAssitanceRequired(orderToDo.needsAssistance)
    if (orderToDo.needsAssistance) setTableColor(GlobalColors.ASSISTANCE_HEADER)
    _getTable();
  }, [orderToDo]);

  const _getTable = async () => {
    const _table = await getTable({ id: orderToDo.idTable || orderToDo.tableNumber })
    setTable(_table)
  }

  const handleCheckAssistance = () => {
    setIsAssitanceRequired(false)
    dispatch(handelAssistance({ tableOrder: orderToDo }))
  }

  return (
    <div style={styles.container}>
      <div style={styles.tableHeader} className="text-title">
        <div style={styles.overflowEllipsis}>
          {isFoodTruck ? `${table?.name} / ${orderToDo.clientName}` : table?.name}
        </div>
      </div>

      {isAssitanceRequired &&
        <div style={styles.assistanceHeader} className="text-title">
          <div>Assistance Required</div>
          <Image
            onClick={() => handleCheckAssistance()}
            src={DoneWhite}
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        </div>
      }


      <div className='board-column'>
        <Element className='board-element'>
          {isFoodTruck ? (
            <OrderCard key={0} tableOrder={orderToDo} timestamp={orderToDo.timestamp} setTableColor={setTableColor} />
          ) : (
            orderToDo.tableOrders.map((tableOrder, idx) => {
              return <OrderCard key={idx} tableOrder={tableOrder} timestamp={tableOrder.timestamp} setTableColor={setTableColor} />;
            })
          )}
        </Element>
      </div>

    </div>
  );
};

export default TableContainer;
