import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import ProductsSlices from './slices/ProductsSlices';
import ModalsSlices from './slices/ModalsSlices';
import KitchenSlices from './slices/KitchenSlices';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['Products', 'Modals', 'Kitchen'],
};


const appReducer = combineReducers({
  Products: ProductsSlices,
  Modals: ModalsSlices,
  Kitchen: KitchenSlices,
})

const rootReducer = (state, action) => {
  if (action.type === 'Modals/resetCache') {
      storage.removeItem('persist:root')
      return appReducer(undefined, action);
    }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
