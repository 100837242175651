import { useState } from 'react'

export const useModal = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [data, setData] = useState(false)

  const openModal = e => {
    setIsOpenModal(true)
    setData(e)
  }
  const closeModal = () => {
    setIsOpenModal(false)
  }

  return [isOpenModal, openModal, closeModal, data]
}
