import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getActiveLocalMenu } from '../../axios/localMenu';
import _ from 'lodash';
import { getActiveGlobalMenu, fetchNextProducts } from '../../axios/globalMenu';
const moment = require('moment');

const initialState = {
  isFoodTruck: false,
  productsList: [],
  productsInCloche: [],
  currentPage: 1,
  isMenuGlobal: false,
  menuId: '',
  subTotal: 0,
  kindOfFoodSelected: 'all',
  tableNumber: 0,
  foodOptions: [],
  foodSelected: {
    id: null,
    pic: '',
    title: '',
    price: 0,
    unitaryPrice: 0,
    description: '',
    kind: '',
    totalPrice: 0,
    quantity: 1,
    table: 0,
    cancelled: false,
    notes: '',
    itemOptions: null,
  },
  ordersList: [],
  kindsOfFood: [],
  restaurantName: '',
  businessId: '',
  locationId: '',
  tableId: '',
  fetchLoading: false,
  allProductsFetched: false,
  ordersListSubTotal: 0,
  ordersListSubTotalWithTaxes: 0,
  ordersListSubTotalWithFee: 0,
  ordersListTip: 0,
  ordersListTotal: 0,
  TAXES: 0,
  FEE: 2,
  client_secret: '',
  paymentIntentId: '',
  notes: '',
  menuSelection: '',
  tipPercentage: { selectedOption: 'option1' },
  businessImage: '',
  locationType: ''
};

function subTotal(state) {
  let isFoodTruck = state.Products.isFoodTruck
  let aux = state.Products.ordersList.filter(order => (order.idTable == state.Products.tableNumber) && order.status !== 'Cancelled')
  if (!isFoodTruck) {
    let sumWithoutCancelled = aux.reduce((accumOrder, order) => {
      let filteredInOrder = order.items.filter(item => item?.status !== "Cancelled")
      return accumOrder + filteredInOrder.reduce((acc, item) => acc + item.totalPrice, 0.0)
    }, 0.0
    )
    return sumWithoutCancelled
  }
  else {
    aux = state.Products.ordersList.filter(order => order.status === 'Waiting for payment')
    let sumWithoutCancelled = aux
      .reduce((accumOrder, order) => {
        let filteredInOrder = order.items.filter(item => item?.status !== 'Cancelled')
        return accumOrder + filteredInOrder.reduce((acc, item) => acc + item.totalPrice, 0.0)
      }, 0.0
      )
    return parseFloat(sumWithoutCancelled)
  }
}

function subTotalWithTaxes(state) {
  return (subTotal(state) * state.Products.TAXES) / 100;
}

function subTotalWithFee(state) {
  return (subTotal(state) * state.Products.FEE) / 100;
}

function getActiveOrders(state) {
  return state.Products.ordersList.filter(orderItem => orderItem.idTable == state.Products.tableNumber)
}

function getSubTotal(state) {
  return state.Products.productsInCloche.filter(item => item.table == state.Products.tableNumber).reduce((acc, product) => acc + product.totalPrice, 0)
}


export const fetchProducts = createAsyncThunk('products/fetchProducts', async (query) => {
  const localMenu = await getActiveLocalMenu({ locationId: query.locationId, currentMoment: moment().format("HH:mm:ss") })
  let menu = localMenu
  let isMenuGlobal = false;
  if (!menu) {
    const globalMenu = await getActiveGlobalMenu({ businessId: query.businessId, currentMoment: moment().format("HH:mm:ss") })
    menu = globalMenu;
    isMenuGlobal = true;
  }

  const response = {
    products: menu.products,
    types: menu.types,
    isMenuGlobal,
    menuId: menu.id,
    allFetched: menu.allFetched
  }
  return response;
});

export const fetchMoreProducts = createAsyncThunk(
  'products/fetchMore',
  async ({ currentPage, isMenuGlobal, menuId }, thunkAPI) => {
    const response = await fetchNextProducts({
      currentPage: currentPage + 1,
      isMenuGlobal,
      menuId
    });
    return response;
  }
);

export const ProductsSlices = createSlice({
  name: 'Products',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      let productAux = Object.assign({}, action.payload);
      if (productAux.extraItems && Object.entries(productAux.extraItems).length > 0) productAux.unitaryPrice = productAux.totalPrice / productAux.quantity;
      productAux.table = state.tableNumber;
      let product = state.productsInCloche.find((product) => product.id === action.payload.id);

      if (product) {
        const hasExtraItems = product.extraItems && JSON.stringify(product.extraItems).length > 0 && JSON.stringify(product.extraItems) !== '{}';
        const auxHasExtraItems = productAux.extraItems && JSON.stringify(productAux.extraItems).length > 0 && JSON.stringify(productAux.extraItems) !== '{}';
        const extraItemsEqual = hasExtraItems && auxHasExtraItems && JSON.stringify(product.extraItems) === JSON.stringify(productAux.extraItems);
        const noExtraItemsInAux = !productAux.extraItems || productAux.extraItems.length === 0;
        const notesEqual = product.notes.length > 0 && productAux.notes.length > 0 && product.notes === productAux.notes;
        if (noExtraItemsInAux || (extraItemsEqual && notesEqual)) {
          product.quantity += action.payload.quantity;
          product.totalPrice += action.payload.totalPrice * action.payload.quantity;
        } else {
          state.productsInCloche.push(productAux);
        }
      } else {
        state.productsInCloche.push(productAux);
      }
      const sum = state.productsInCloche.filter(item => item.table == state.tableNumber).reduce((acc, product) => acc + product.totalPrice, 0);
      state.subTotal = sum;
    },

    addOrder: (state, action) => {
      state.ordersList.push(action.payload);
      const sum = state.ordersList.filter(order => order.idTable == state.tableNumber).reduce((acc, order) => acc + (order.subTotal * 1.0), 0.0);
      state.ordersListSubTotal = sum;
      state.ordersListSubTotalWithTaxes = (sum * state.TAXES) / 100;
      state.ordersListSubTotalWithFee = (sum * state.FEE) / 100;
      state.ordersListTotal = sum + state.ordersListSubTotalWithTaxes + state.ordersListSubTotalWithFee + state.ordersListTip;
    },

    setOrderStatus: (state, action) => {
      const indexAux = state.ordersList.findIndex((order) => action.payload.id === order.id)
      state.ordersList[indexAux].status = action.payload.status;
    },

    setOrderDetailStatus: (state, action) => {
      const orderIndexAux = state.ordersList.findIndex((order) => action.payload.orderId === order.id)
      const orderAux = state.ordersList[orderIndexAux]
      const orderDetailIndexAux = orderAux.items.findIndex((orderDetail) => action.payload.id === orderDetail.id)
      const orderDetailAux = orderAux.items[orderDetailIndexAux]
      state.ordersList[orderIndexAux].items[orderDetailIndexAux].status = action.payload.status;
      state.ordersList[orderIndexAux].subTotal = state.ordersListSubTotal - action.payload.price;
    },


    addQuantityToProduct: (state, action) => {
      const product = state.productsInCloche.filter(item => item.table == state.tableNumber).find((product) => product.id === action.payload.id);
      product.quantity += 1;

      product.totalPrice = (product.unitaryPrice ?? +product.price) * (+product.quantity);
      product.subTotal = (product.unitaryPrice ?? +product.price) * (+product.quantity);
      const sum = state.productsInCloche.filter(item => item.table == state.tableNumber).reduce((acc, product) => acc + (product.unitaryPrice ?? product.price), 0);
      state.subTotal = sum;
    },


    removeQuantityToProduct: (state, action) => {
      const product = state.productsInCloche.filter(item => item.table == state.tableNumber).find((product) => product.id === action.payload.id);
      product.quantity -= 1;
      if (product.quantity === 0) {
        const index = state.productsInCloche.indexOf(product);
        state.productsInCloche.splice(index, 1);
        const sum = state.productsInCloche.filter(item => item.table == state.tableNumber).reduce((acc, product) => acc + (product.unitaryPrice ?? product.price), 0);
        state.subTotal = sum;
      } else {
        product.totalPrice = (+(product.unitaryPrice ?? product.price)) * (+product.quantity);
        const sum = state.productsInCloche.filter(item => item.table == state.tableNumber).reduce((acc, product) => acc + (product.unitaryPrice ?? product.price), 0) * product.quantity;
        state.subTotal = sum;
      }
    },

    setClientSecret: (state, action) => {
      state.client_secret = action.payload
    },

    setPaymentIntentId: (state, action) => {
      state.paymentIntentId = action.payload
    },

    setkindOfFoodSelected: (state, action) => {
      state.kindOfFoodSelected = action.payload;
    },

    setTableNumber: (state, action) => {
      state.tableNumber = action.payload;
    },

    setFeePercentage: (state, action) => {
      state.FEE = action.payload;
    },

    setFoodSelected: (state, action) => {
      const newfoodSelected = { ...action.payload };

      state.foodSelected = newfoodSelected;
    },

    setFoodOptions: (state, action) => {
      state.foodOptions = action.payload;
    },

    setMenuSelection: (state, action) => {
      state.menuSelection = action.payload;
    },

    resetProductsModal: (state) => {
      state = initialState;
    },

    setResetFoodSelected: (state) => {
      state.foodSelected = {
        id: null,
        pic: '',
        title: '',
        price: 0,
        description: '',
        kind: '',
        totalPrice: 0,
        quantity: 1,
        itemOptions: null,
      };
    },

    setResetFoodOptions: (state) => {
      state.foodOptions = []
    },

    setResetListOrderSelected: (state) => {
      _.remove(state.ordersList, (el) => el.idTable == state.tableNumber)

    },

    handleFoodSelectedQuantity: (state, action) => {
      const product = state.foodSelected;
      product.quantity += action.payload;
    },

    resetProductsInCloche: (state) => {
      state.productsInCloche = state.productsInCloche.filter(item => item.table != state.tableNumber);
      state.subTotal = 0;
    },
    resetProductsInClocheOrder: (state) => {
      state.productsInCloche = state.productsInCloche.filter(item => item.table != state.tableNumber);
      state.subTotal = 0;
      state.foodSelected = {
        id: null,
        pic: '',
        title: '',
        price: 0,
        unitaryPrice: 0,
        description: '',
        kind: '',
        totalPrice: 0,
        quantity: 1,
        table: 0,
        cancelled: false,
        notes: '',
        itemOptions: null,
      }
      state.ordersList = []
    },

    setOrdersListTip: (state, action) => {
      state.ordersListTip = action.payload;
      state.ordersListTotal =
        state.ordersListSubTotal + state.ordersListSubTotalWithTaxes + state.ordersListSubTotalWithFee + action.payload;
    },

    setIsFoodTruck: (state, action) => {
      state.isFoodTruck = action.payload;
    },

    setLocationType: (state, action) => {
      state.locationType = action.payload;
    },

    setTAXES: (state, action) => {
      state.TAXES = action.payload
    },

    setRestaurantName: (state, action) => {
      state.restaurantName = action.payload;
    },

    setBusinessId: (state, action) => {
      state.businessId = action.payload;
    },
    setBusinessImage: (state, action) => {
      state.businessImage = action.payload;
    },

    setLocationId: (state, action) => {
      state.locationId = action.payload;
    },

    setTableId: (state, action) => {
      state.tableId = action.payload;
    },

    setTipSelection: (state, action) => {
      state.tipPercentage = action.payload;
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.fulfilled, (state, action) => {
        if (!action.hasMore) {
          state.productsList = action.payload.products;
          state.kindsOfFood = action.payload.types;
        }
        state.menuId = action.payload.menuId;
        state.isMenuGlobal = action.payload.isMenuGlobal;
        state.currentPage = 1;
      })
      .addCase(fetchMoreProducts.fulfilled, (state, action) => {
        const nextProducts = action.payload.products;
        state.currentPage += 1;
        state.productsList = [...state.productsList, ...nextProducts];
        if (!nextProducts.length) state.allProductsFetched = true;
      });
  },
});

export const {
  addProduct,
  addOrder,
  setOrderStatus,
  setOrderDetailStatus,
  addQuantityToProduct,
  removeQuantityToProduct,
  setkindOfFoodSelected,
  setClientSecret,
  setPaymentIntentId,
  setTableNumber,
  setFeePercentage,
  setFoodSelected,
  setFoodOptions,
  setResetFoodSelected,
  setResetFoodOptions,
  handleFoodSelectedQuantity,
  setResetListOrderSelected,
  resetProductsInCloche,
  setOrdersListTip,
  addCustomizeTextToProduct,
  setMenuSelection,
  resetProductsModal,
  setIsFoodTruck,
  setLocationType,
  setTAXES,
  setRestaurantName,
  setBusinessId,
  setBusinessImage,
  setLocationId,
  setTableId,
  resetProductsInClocheOrder,
  setTipSelection,
  setCurrentPage,
} = ProductsSlices.actions;


export const selectProducts = (state) => state.Products.productsList;
export const selectProductsInCloche = (state) => state.Products.productsInCloche.filter(item => item.table == state.Products.tableNumber);
export const selectOrdersList = (state) => getActiveOrders(state);
export const selectSubTotal = (state) => getSubTotal(state);
export const selectTableNum = (state) => state.Products.tableNumber;
export const selectKindOfFoodSelected = (state) => state.Products.kindOfFoodSelected;
export const selectFoodSelected = (state) => state.Products.foodSelected;
export const selectFoodOptions = (state) => state.Products.foodOptions;
export const selectQuantityProductsInCloche = (state) => state.Products.productsInCloche.filter(item => item.table == state.Products.tableNumber).length;
export const selectQuantityOrdersItems = (state) => state.Products.ordersList.filter(orderItem => orderItem.idTable == state.Products.tableNumber).length;
export const selectKindsOfFood = (state) => state.Products.kindsOfFood;
export const selectRestaurantName = (state) => state.Products.restaurantName;
export const selectBusinessId = (state) => state.Products.businessId;
export const selectBusinessImage = (state) => state.Products.businessImage;
export const selectLocationId = (state) => state.Products.locationId;
export const selectTableId = (state) => state.Products.tableId;
export const selectOrdersListTotal = (state) => state.Products.ordersListTotal;
export const selectIsProductsInClocheAvailable = (state) => {
  return state.Products.productsInCloche.filter(item => item.table == state.Products.tableNumber).length ? true : false;
};
export const selectIsOrdersListAvailable = (state) => {
  return state.Products.ordersList.length ? true : false;
};
export const selectOrdersListSubTotal = (state) => subTotal(state);
export const selectOrdersListSubTotalWithTaxes = (state) => subTotalWithTaxes(state);
export const selectOrdersListSubTotalWithFee = (state) => subTotalWithFee(state);
export const selectOrdersListTip = (state) => state.Products.ordersListTip;
export const selectTotal = (state) => subTotal(state) + subTotalWithFee(state) + subTotalWithTaxes(state);
export const selectTotalPay = (state) => state.Products.ordersListTip + selectTotal(state)
export const selectIsProductsInClocheEmpty = (state) => {
  return state.Products.productsInCloche.filter(item => item.table == state.Products.tableNumber).length ? false : true;
};

export const selectClientSecret = (state) => state.Products.client_secret
export const selectPaymentIntentId = (state) => state.Products.paymentIntentId
export const selectMenuSelection = (state) => state.Products.menuSelection;
export const selectIsFoodTruck = (state) => state.Products.isFoodTruck;
export const selectLocationType = (state) => state.Products.locationType;
export const selectTipSelection = (state) => state.Products.tipPercentage;
export const selectTAXES = (state) => state.Products.TAXES;
export const selectFee = (state) => state.Products.FEE;
export const selectCurrentPage = (state) => state.Products.currentPage;
export const selectMenuId = (state) => state.Products.menuId;
export const selectIsMenuGlobal = (state) => state.Products.isMenuGlobal;


export default ProductsSlices.reducer;
