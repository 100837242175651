import { REACT_APP_API_URL } from '../utils/envConfig';
import axios from 'axios';

export async function getBusinesses() {
	return new Promise(async (resolve, reject) => {
		await axios
			.get(`${REACT_APP_API_URL}/getBusinesses`)
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function getBusiness (businessId) {
    return new Promise(async (resolve, reject) => {
        await axios
			.post(`${REACT_APP_API_URL}/getBusiness`, JSON.stringify(businessId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
    });
}

export async function addBusiness(business) {
    return new Promise(async (resolve, reject) => {
        await axios
        .post(`${REACT_APP_API_URL}/business`,JSON.stringify(business))
        .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export async function uploadCoverImage(data) {
    return new Promise(async (resolve, reject) => {
        await axios
            .post(`${REACT_APP_API_URL}/uploadCoverImage`, JSON.stringify(data))
            .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export async function updateBusiness(business) {
    return new Promise(async (resolve, reject) => {
        await axios
        .post(`${REACT_APP_API_URL}/updateBusiness`,JSON.stringify(business))
        .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export async function deleteBusiness(business) {
    return new Promise(async (resolve, reject) => {
        await axios
        .post(`${REACT_APP_API_URL}/deleteBusiness`,JSON.stringify(business))
        .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export async function updateBusinessStatus(data) {

    return new Promise(async (resolve, reject) => {
        await axios
        .post(`${REACT_APP_API_URL}/updateBusinessStatus`, JSON.stringify(data))
        .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}
