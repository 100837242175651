import React from 'react';
import { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useModal } from '../../../hooks/useModal';
import Popup from '../../../components/Modals/Popup';
import { getBusinesses } from '../../../axios/business';
import { deleteBusiness } from '../../../axios/business';
import { useNavigate } from 'react-router'
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import './Business.css'
import { notify } from '../../../utils/notify';
import { Image } from 'react-bootstrap';
import BusinessDetail from './BusinessDetail/BusinessDetail'
import Pencil from '../../../assets/NavBar Icons/Pencil.svg'
import TrashCan from '../../../assets/NavBar Icons/TrashCan.svg'
import { useLocation } from 'react-router';

const Business = (props) => {
    const _useLocation = useLocation();
    const [businessesList, setBusinessesList] = useState([])
    const [isOpenModal, openModal, closeModal, data] = useModal()
    const [isLoading, setIsLoading] = useState(false);
    const [currentItem, setCurrentItem] = useState({ business: [], action: '' });
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        _getBusinesses()
        const business = _useLocation.state?.business
        if (business) handleEditBusiness({ business, action: 'edit' })
    }, [])

    useEffect(() => {
        if (!_useLocation.state?.business) props.popupOpen(isPopupOpen)
    }, [isPopupOpen])

    const _getBusinesses = async () => {
        setIsLoading(true)
        await getBusinesses().then(business => {
            setBusinessesList(business)
            setIsLoading(false)
        }).catch(error => {
            notify('Unable to read data from database', 'error')
            console.log(error)
            setIsLoading(false)
        })
    }

    const handleEditBusiness = item => {
        setCurrentItem(item)
        setIsPopupOpen(true)
    }

    const _deleteBusiness = async _data => {
        await deleteBusiness(_data).then(() => {
            _getBusinesses();
            closeModal()
            notify('Business data deleted successfully', 'success')
        }).catch(error => {
            notify('Error deleting business data', 'error')
            console.log(error)
        })
    }

    return (
        <>
            <div className="main">
                <Popup
                    isOpen={isOpenModal}
                    title='Delete business'
                    label='Are you sure do you want to delete'
                    onCancel={closeModal}
                    cancelLabel='Cancel'
                    onAccept={() => _deleteBusiness(data)}
                    acceptLabel='Accept'
                />
                <LoadingSpinner
                    isLoading={isLoading}
                    label='Loading business...'
                />
                {currentItem && isPopupOpen && <BusinessDetail
                    handleClosePopup={() => setIsPopupOpen(false)}
                    business={currentItem.business}
                    action={currentItem.action}
                    fullScreen={_useLocation.state?.business}
                    isMenuCollapsed={props.isMenuCollapsed}
                    response={_getBusinesses}
                />}
                <div className="row-header">
                    <div>
                        <header>Businesses</header>
                    </div>
                    <div>
                        <BsPlusCircleFill
                            className='plus-icon color-cloche-red'
                            onClick={() => navigate('/quickstart')}
                        />
                    </div>
                </div>
                <div className='header'>
                    <div className='business-name'>NAME</div>
                    <div className='business-city'>CITY</div>
                    <div className='business-state'>STATE/PROVINCE</div>
                    <div className='business-country'>COUNTRY</div>
                    <div className='business-status'>STATUS</div>
                    <div className='business-actions'>ACTIONS</div>
                </div>
                <div className="crud">
                    {businessesList.map(business => (
                        <div className="crud-row" key={business.id}>
                            <div className="business-name">{business.name}</div>
                            <div className="business-city">{business.city}</div>
                            <div className="business-state">{business.state}</div>
                            <div className="business-country">{business.country}</div>
                            <div className="business-status">{business.status}</div>
                            <div className="business-actions">
                                <Image
                                    className='action-icon'
                                    src={Pencil}
                                    onClick={() => handleEditBusiness({ business, action: 'edit', fullscreen: false })}
                                />
                                <Image
                                    className='action-icon color-cloche-red'
                                    src={TrashCan}
                                    onClick={() => openModal({ id: business.id })}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Business;
