import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

import {
  selectOrderOverviewModalState,
  selectHelpModalState,
  selectOrderCheckoutModalState,
  selectOrderPaymentMethodState,
  selectWelcomeModalState,
  selectThankYouModalState,
} from '../Redux/slices/ModalsSlices';

import {
  selectTableNum
} from '../Redux/slices/ProductsSlices'

import PrivateRoutes from './PrivateRoutes';
import RestrictYourCloche from './RestricRoutes/RestrictYourCloche';
import RestrictYourOrder from './RestricRoutes/RestrictYourOrder';
import AnimationLayout from './Layouts/AnimationLayout';


import HeaderLayout from './Layouts/Header/HeaderLayout';
import FooterLayout from './Layouts/Footer/FooterLayout';

import Home from '../screens/Home/Home';
import YourCloche from '../screens/YourCloche/YourCloche';
import OrderInfo from '../screens/OrderInfo/OrderInfo';
import YourOrder from '../screens/OrderInfo/YourOrder';
import User from '../screens/Plataform/User/User';
import Roles from '../screens/Plataform/Roles/Roles';
import StationOption from '../screens/Plataform/Station/StationOption';
import Station from '../screens/Plataform/Station/Station';
import Tables from '../screens/Plataform/Tables/Tables'
import TablesManagement from '../screens/Plataform/Tables/TablesManagement'
import Business from '../screens/Plataform/Business/Business';
import Location from '../screens/Plataform/Location/Location';
import Menu from '../screens/Plataform/Menu/Menu';
import MenuDetail from '../screens/Plataform/Menu/MenuDetail/MenuDetail';
import Product from '../screens/Plataform/Product/Product';
import ProductOption from '../screens/Plataform/Product/Option';
import OptionList from '../screens/Plataform/Product/OptionList';

import OrderOverviewModal from '../components/Modals/OrderOverviewModal';
import HelpModal from '../components/Modals/HelpModal';
import OrderCheckoutModal from '../components/Modals/OrderCheckoutModal';
import OrderPaymentMethod from '../components/Modals/OrderPaymentMethod';
import WelcomeModal from '../components/Modals/WelcomeModal'
import AddTip from '../components/Modals/AddTip';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import StripeModal from '../components/Modals/StripeModal';
import ThankYouModal from '../components/Modals/ThankYouModal';

import Header from '../screens/Plataform/QuickStart/Header';
import Content from '../screens/Plataform/QuickStart/Content';
import ClocheWelcome from '../screens/Plataform/QuickStart/ClocheWelcome';


import SideNavigation from './Layouts/SideNavigation/SideNavigation';
import SideMenu from './Layouts/SideMenu/SideMenu';
import Unsubscribe from '../screens/External/Unsubscribe';

import SignIn from '../screens/KitchenScreens/SignIn/SignIn';
import BoardComponent from '../screens/KitchenScreens/Home/BoardComponent';
import OrderHistory from '../screens/KitchenScreens/OrderHistory/OrderHistory';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Index = () => {
  const tableNumber = useSelector(selectTableNum);
  const OrderOverviewModalState = useSelector(selectOrderOverviewModalState);
  const HelpModalState = useSelector(selectHelpModalState);
  const ThankYouModalState = useSelector(selectThankYouModalState);
  const OrderCheckoutModalState = useSelector(selectOrderCheckoutModalState);
  const OrderPaymentMethodState = useSelector(selectOrderPaymentMethodState);
  const WelcomeModalState = useSelector(selectWelcomeModalState);
  const [currentStep, setCurrenStep] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const handlepopupOpen = value => {
    setIsPopupOpen(value)
  }
  const handleMenuCollapsed = value => {
    setIsMenuCollapsed(value)
  }

  return (
    <div>
      <Router>
        <Routes>
          <Route element={<AnimationLayout />}>
            <Route element={<HeaderLayout />}>
              <Route path="/:businessId/:locationId/:tableId" element={<Home />} />
              <Route path="/Home" element={<Home />} />
            </Route>

            <Route element={<FooterLayout />}>
              <Route element={<RestrictYourCloche />}>
                <Route path="/YourCloche" element={<YourCloche />} />
              </Route>

              <Route element={<RestrictYourOrder />}>
                <Route path="/your-order" element={<YourOrder />} />
              </Route>

              <Route path="/OrderInfo" element={<OrderInfo />} />
            </Route>

            <Route>
              <Route path="/Checkout" element={<StripeModal />} />
            </Route>

            <Route>
              <Route path="/updateUnsubscribeFlag/:id" element={<Unsubscribe />} />
            </Route>

            <Route element={<Header currentStep={currentStep} />}>
              <Route path="/quickStart" element={<Content setCurrenStep={setCurrenStep} />} />
              <Route path="/quickStart/:referralCode" element={<Content setCurrenStep={setCurrenStep} />} />
            </Route>

            <Route>
              <Route path="/welcome" element={<ClocheWelcome />} />
              <Route path="/welcome/:referralCode" element={<ClocheWelcome />} />
            </Route>

            <Route element={<SideMenu isPopupOpen={isPopupOpen} menuCollapsed={handleMenuCollapsed} />}>
              <Route>
                <Route path="/users" element={<User popupOpen={handlepopupOpen} isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/roles" element={<Roles popupOpen={handlepopupOpen} isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/stationoption" element={<StationOption state />} />
                <Route path="/stations" element={<Station popupOpen={handlepopupOpen} isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/tables" element={<Tables popupOpen={handlepopupOpen} isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/tablesManagement" element={<TablesManagement />} />
                <Route path="/businesses" element={<Business popupOpen={handlepopupOpen} isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/locations" element={<Location popupOpen={handlepopupOpen} isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/menus" element={<Menu popupOpen={handlepopupOpen} />} />
                <Route path="/menus/detail" element={<MenuDetail state />} />
                <Route path="/products" element={<Product popupOpen={handlepopupOpen} isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/productOptions" element={<ProductOption popupOpen={handlepopupOpen} isMenuCollapsed={isMenuCollapsed} />} />
                <Route path="/productOptionLists" element={<OptionList popupOpen={handlepopupOpen} isMenuCollapsed={isMenuCollapsed} />} />
              </Route>
            </Route>

            <Route>
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="" element={<SignIn />} />

              <Route element={<PrivateRoutes />}>
                <Route element={<SideNavigation />}>
                  <Route path="/kitchen/" element={<BoardComponent />} />
                  <Route path="/service/" element={<BoardComponent />} />
                  <Route path="/bar/" element={<BoardComponent />} />
                  <Route path="/order-history" element={<OrderHistory />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </Router>

      <ToastContainer limit={3} />
      <OrderOverviewModal show={OrderOverviewModalState} />
      <HelpModal show={HelpModalState} />
      <ThankYouModal show={ThankYouModalState} />
      <OrderCheckoutModal show={OrderCheckoutModalState} />
      <OrderPaymentMethod show={OrderPaymentMethodState} />
      <WelcomeModal show={WelcomeModalState && tableNumber} />
      <ConfirmationModal />
      <AddTip />
    </div>
  );
};

export default Index;
