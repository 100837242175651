import React from 'react';

const PriceFormat = ({ price }) => {
  const [priceFormat, setPriceFormat] = React.useState('');

  React.useEffect(() => {
    const numericPrice = parseFloat(price);
    if (!isNaN(numericPrice)) {
      setPriceFormat(`$ ${numericPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`);
    } else {
      setPriceFormat('Invalid Price');
    }
  }, [price]);

  return <>{priceFormat}</>;
};

export default PriceFormat;
