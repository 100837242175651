import './Modals.css'
import { ClockLoader } from "react-spinners/";
import { GlobalColors } from '../../assets/GlobalColors';

const LoadingSpinner = ({
  isLoading,
  label,
  width,
  height
}) => {
  
  return (
    <>
      {isLoading &&
        <div className="isLoading">
          <div className="isLoading_dialog" style={{width, height}}>
            <ClockLoader color={GlobalColors.CLOCHE_RED} cssOverride={{ border: `1px solid ${GlobalColors.CLOCHE_RED}` }} />
            <div className="mt-4">
              {label}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default LoadingSpinner
