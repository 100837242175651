import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { throttle } from 'lodash';

import FoodItem from '../../components/Home/FoodItem';
import FoodItemDetail from '../../components/Modals/FoodItemDetail';
import {
  selectProducts,
  setFoodSelected,
  setResetFoodSelected,
  setFoodOptions,
  setResetFoodOptions,
  selectKindsOfFood,
  selectIsFoodTruck,
  selectPaymentIntentId,
  selectBusinessId,
  selectMenuId,
  selectCurrentPage,
  selectIsMenuGlobal,
  fetchMoreProducts,
  selectLocationType,
} from '../../Redux/slices/ProductsSlices';
import useMediaQuery from '../../hooks/useMediaQuery';
import { getGlobalProductOptionList } from '../../axios/globalProductOptionList';
import { updatePaymentIntent } from '../../axios/order';
import { getLocalProductOptionList } from '../../axios/localProductOptionList';
import { setResetListOrderSelected } from '../../Redux/slices/ProductsSlices';
import { setOrdersListTip } from '../../Redux/slices/ProductsSlices';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const businessId = useSelector(selectBusinessId);
  const locationType = useSelector(selectLocationType);
  const isMobileS = useMediaQuery('(max-width: 320px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptop = useMediaQuery('(max-width: 1024px)');
  const menuId = useSelector(selectMenuId);
  const currentPage = useSelector(selectCurrentPage);
  const isMenuGlobal = useSelector(selectIsMenuGlobal);
  const types = useSelector(selectKindsOfFood);
  const products = useSelector(selectProducts);
  const isFoodTruck = useSelector(selectIsFoodTruck);
  const [openFoodItem, setOpenFoodItem] = useState(false);
  const paymentIntentId = useSelector(selectPaymentIntentId);
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentSuccess = queryParams.get('payment_success') === 'true';
    if (paymentSuccess && isFoodTruck) {
      let paymentIntentData = {
        id: paymentIntentId,
        isFoodTruck: isFoodTruck,
        businessId: businessId,
        locationType: locationType,
      }
      updatePaymentIntent(paymentIntentData)
      navigate('/your-order');
    } else if (paymentSuccess) {
      dispatch(setResetListOrderSelected());
      dispatch(setOrdersListTip(0))
    }
  }, [location.search, dispatch]);

  const foodKinds = types

  const handleScroll = () => {
    const threshold = 150;
    const positionFromBottom = document.documentElement.offsetHeight - (window.innerHeight + document.documentElement.scrollTop);

    if (positionFromBottom <= threshold && !isLoading && menuId) {
      setIsLoading(true);
      dispatch(fetchMoreProducts({ currentPage, isMenuGlobal, menuId }))
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 100);

    window.addEventListener('scroll', throttledHandleScroll);

    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
      throttledHandleScroll.cancel();
    };
  }, [isLoading, currentPage, isMenuGlobal, menuId]);




  const renameItems = (array, id, count) => {
    let index = array.findIndex(item => item.id === id)
    for (let i = 0; i < count; i++) {
      const label = i + 1;
      array[i + index].typeName += " - " + label;
    }
    return array
  }

  const handleDuplicates = (array) => {
    const mapDupCount = new Map();
    for (let arrItem of array) {
      if (mapDupCount.has(arrItem.id)) {
        const accum = mapDupCount.get(arrItem.id)
        mapDupCount.set(arrItem.id, accum + 1)
      } else {
        mapDupCount.set(arrItem.id, 1)
      }
    }
    for (let [key, value] of mapDupCount) {
      if (value > 1) array = renameItems(array, key, value)
    }
    return array
  }

  const handleClickFunction = async (item) => {
    let options = item.optionLists
      ? await Promise.all(item.optionLists?.map(option => {
        return (option.isGlobal ? getGlobalProductOptionList : getLocalProductOptionList)({ id: option.optionListId })
      }))
      : []
    options = options.filter(x => x.options.length)
    if (options.length) options = handleDuplicates(options)
    const _foodSelected = {
      totalPrice: item.price,
      quantity: 1,
      ...item,
    }
    dispatch(setFoodSelected(_foodSelected));
    dispatch(setFoodOptions(options));
    setOpenFoodItem(true);
  };

  const handleClose = () => {
    setOpenFoodItem(false);
    dispatch(setResetFoodSelected());
    dispatch(setResetFoodOptions());
  };

  const handleCloseAndNavigate = () => {
    setOpenFoodItem(false);
    dispatch(setResetFoodSelected());
  };

  return (
    <div
      style={{
        width: isMobileS || isMobileM || isMobileL || isTablet
          ? '100%'
          : isLaptop
            ? '70%'
            : '60%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '85px',
      }}
    >
      {foodKinds?.map((foodKind, idx) => {
        return (
          <>
            <div
              id={foodKind.name}
              ref={scrollRef}
              style={{
                width: '85%',
                display: 'inline-flex',
                textJustify: 'start',
                marginTop: '20px',
                fontSize: '18px',
                fontWeight: 700,
                alignSelf: 'start',
                marginLeft: '20px',
              }}
              key={idx}
            >
              {foodKind.name}
            </div>
            {products.map((product, index) => {
              return <>
                {product.kind === foodKind.kind &&
                  <FoodItem key={index} product={product} clickFunction={handleClickFunction} />}
              </>
            })
            }
          </>
        )
      })
      }
      <FoodItemDetail show={openFoodItem} onHide={handleClose} handleCloseAndNavigate={handleCloseAndNavigate} />
    </div>
  );
};

export default Home;
