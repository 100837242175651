import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import { GlobalColors } from '../../assets/GlobalColors';

const ConfirmationModal = (props) => {

  const divWidth = () => {
    return '100%'
  };
 
  const handleClose = (val) =>{
    props.handleCancelItem(val)
    props.setIsOpen(false)
  }



  return (
    <Modal
      show={props.isOpen}
      onHide={handleClose}
      style={{
        width: divWidth(),
        transform: 'translateY(100px)',
        borderRadius: '30px'
      }}
    >
      <div
        className="modal show"
        style={{ display: 'block', position: 'initial' }}
      >
        
          <div 
            style={{
              textAlign: 'center',
              padding: '10px',
              paddingBottom: '20px',
              paddingTop: '20px',
              fontSize:'22px',
              fontWeight:'bold',
            }}
          >
           <p>Are you sure you want to cancel this plate?</p>
           </div>
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                alignContent: 'center',
                padding: '10px',
                display: 'flex',
              }}
            >
              <Button
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  height: '50px',
                  borderRadius: '12px',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  color: GlobalColors.WHITE,
                  fontSize: '14px',
                  border: 'none',
                  fontWeight: 'bold',
                  marginRight: '5px',
                }}
                onClick={() => handleClose(false)}
              >
                <div className="text-title">Go back</div>
              </Button>
              <Button
                onClick={() => handleClose(true)}
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  height: '50px',
                  borderRadius: '12px',
                  backgroundColor: GlobalColors.CLOCHE_RED,
                  color: GlobalColors.WHITE,
                  fontSize: '14px',
                  border: 'none',
                }}
              >
                <div className="text-title">Confirm</div>
              </Button>
            </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
