import { REACT_APP_API_URL } from '../utils/envConfig';
import axios from 'axios';

export async function getOptionTypes (businessId) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getOptionTypes`, JSON.stringify(businessId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function getOptionType (optionTypeId) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getOptionType`, JSON.stringify(optionTypeId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function addOptionType (optionType) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/optionType`, JSON.stringify(optionType))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function updateOptionType (optionType) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/updateOptionType`, JSON.stringify(optionType))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function deleteOptionType (optionTypeId) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/deleteOptionType`, JSON.stringify(optionTypeId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}
