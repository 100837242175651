import React from 'react';
import Image from 'react-bootstrap/Image';
import PriceFormat from '../General/PriceFormat';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { CgMathMinus, CgMathPlus } from 'react-icons/cg';

import { GlobalColors } from '../../assets/GlobalColors';
import { addQuantityToProduct, removeQuantityToProduct } from '../../Redux/slices/ProductsSlices';
import useMediaQuery from '../../hooks/useMediaQuery';
import './YourCloche.css'
const FoodItemsCloche = ({ id, pic, title, price, totalPrice, quantity, buttonDisabled, extraItems, notes }) => {
  const dispatch = useDispatch();
  const isMobileS = useMediaQuery('(max-width: 320px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptop = useMediaQuery('(max-width: 1024px)');
  const isLaptopL = useMediaQuery('(max-width: 1440px)');
  const isIphoneX = useMediaQuery('(max-width: 390px)');
  const isIphone = useMediaQuery('(max-width: 414px)');



  const ButtonSize = () => {
    return '24px';
  };

  return (
    <div
      style={{
        width: '95%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        borderBottom: `1px solid ${GlobalColors.BORDER_BOTTOM_GRAY_2}`,
        paddingBottom: '10px',
        marginBottom: '10px',
      }}
    >
      {pic === '' ? (
        <div
          style={{
            width: '25%',
            height: '100%',
            borderRadius: '5px',
            backgroundColor: '#E5E5E5',
            aspectRatio: '4/3'
          }}
        ></div>
      ) : (
        <Image
          src={pic}
          style={{
            width: '25%',
            height: '100%',
            borderRadius: '5px',
            alignSelf: 'baseline',
            aspectRatio: '4/3'
          }}
        />
      )}

      <div
        style={{
          width: '73%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            width: isMobileS
              ? '67%'
              : isMobileM
                ? '71%'
                : isMobileL || isIphoneX || isIphone
                  ? '75%'
                  : isTablet
                    ? '80%'
                    : isLaptop
                      ? '85%'
                      : isLaptopL
                        ? '89%'
                        : '91%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <div
            style={{
              fontSize: isMobileS || isMobileM || isMobileL || isIphoneX || isIphone || isTablet
                ? '12px'
                : '14px',
              textAlign: 'left',
              color: GlobalColors.BLACK,
              width: '100%',
              height: 'auto',
            }}
            className="text-title-item"
          >
            {title}
          </div>

          <div
            style={{
              fontSize: isLaptop || isLaptopL
                ? '14px'
                : '12px',
              textAlign: 'left',
              color: GlobalColors.GRAY_1,
              width: '100%',
              height: 'auto',
            }}
            className="text-normal"
          >
            <PriceFormat price={price} />
            {extraItems && Object.keys(extraItems).map((type, typeIndex) => {
              return (
                <div key={`${typeIndex}-container`} className='extra-items-container'>
                  {extraItems[type].map((item, itemIndex) => {
                    return (
                      <div key={`${itemIndex}-item`}>{item.name} {item.price > 0 && <PriceFormat price={item.price} />}</div>
                    )
                  })}
                </div>
              );
            })}
            {notes &&
              <div className='text-normal'>
                {notes}
              </div>
            }
            {totalPrice && totalPrice !== price &&
              <div className='text-total-price'><PriceFormat price={totalPrice} /></div>
            }
          </div>
        </div>

        <div
          style={{
            width: isMobileS
              ? '33%'
              : isMobileM
                ? '29%'
                : isMobileL || isIphoneX || isIphone
                  ? '25%'
                  : isTablet
                    ? '20%'
                    : isLaptop
                      ? '15%'
                      : isLaptopL
                        ? '11%'
                        : '9%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                removeQuantityToProduct({
                  id: id,
                })
              );
            }}
            disabled={buttonDisabled}
            style={{
              width: ButtonSize(),
              height: ButtonSize(),
              borderRadius: '50%',
              backgroundColor: 'white',
              border: '1px solid' + GlobalColors.CLOCHE_RED,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <CgMathMinus
              color={GlobalColors.CLOCHE_RED}
              style={{
                minHeight: '14px',
                minWidth: '14px',
              }}
            />
          </Button>

          <div
            className="text-title"
            style={{
              fontSize: '18px',
              width: 'auto',
              height: 'auto',
              padding: '0 0.5rem',
            }}
          >
            {quantity}
          </div>

          <Button
            onClick={(e) => {
              e.preventDefault();
              dispatch(
                addQuantityToProduct({
                  id: id,
                })
              );
            }}
            disabled={buttonDisabled}
            style={{
              width: ButtonSize(),
              height: ButtonSize(),
              borderRadius: '50%',
              backgroundColor: 'white',
              border: '1px solid' + GlobalColors.CLOCHE_RED,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <CgMathPlus
              color={GlobalColors.CLOCHE_RED}
              style={{
                minHeight: '14px',
                minWidth: '14px',
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FoodItemsCloche;
