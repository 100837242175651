import React from 'react';
import stripeLogo from './../../../assets/QuickStart/stripeLogo.svg'
import building from './../../../assets/QuickStart/building.svg'
import { Image } from 'react-bootstrap';
import './QuickStart.css'

const StripeNextSteps = ({ setCurrenStep, stripeLink, rollBack }) => {

    const handleClick = () => {
        rollBack();
        setCurrenStep(3);
    }

    return (
        <div className="my-component">
            <div className="sub-component">
                <div className="circle-stripe">
                    <Image src={building} alt="Placeholder" />
                </div>
                <div>
                    <span className="text-normal-qs">Enter your business and bank data to create your linked </span>
                    <span className="text-bold">Stripe account.</span>
                </div>
            </div>
            <div className="horizontal-line"></div>
            <div className="flex-container">
                <div className="sub-container">
                    <Image className="image" src={stripeLogo} alt="Placeholder" />
                    <div className="text-container-stripe">
                        <div className="text-uppercase">Stripe account creation</div>
                        <div className="text-light">Stripe will ask for your business and bank account data, but Cloche never sees that information.</div>
                    </div>
                </div>
                <div className="button-container-stripe">
                    <button className="btn btn-confirm  stripe-button-next" onClick={() => window.open(stripeLink.url, "_blank")}>
                        Go to Stripe -&gt;
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StripeNextSteps;
