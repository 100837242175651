import { REACT_APP_API_URL } from '../utils/envConfig';
import axios from 'axios';

export async function getGlobalProducts (data) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getGlobalProducts`, JSON.stringify(data))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function getGlobalProduct (globalProductId) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getGlobalProduct`, JSON.stringify(globalProductId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function addGlobalProduct(globalProduct) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/globalProduct`, JSON.stringify(globalProduct))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}

export async function updateGlobalProduct(globalProduct) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/updateGlobalProduct`, JSON.stringify(globalProduct))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}

export async function deleteGlobalProduct (globalProductId) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/deleteGlobalProduct`, JSON.stringify(globalProductId))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}

export async function uploadProductImage (data) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/uploadProductImage`, JSON.stringify(data))
			.then(async (response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}
export async function deleteProductImage (data) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/deleteProductImage`, JSON.stringify(data))
			.then(async (response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}