import { REACT_APP_API_URL } from '../utils/envConfig';
import axios from 'axios';

export async function getStations (locationId) {
    return new Promise(async (resolve, reject) => {
        await axios
			.post(`${REACT_APP_API_URL}/getStations`, JSON.stringify(locationId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
    });
}

export async function getStation (stationId) {
    return new Promise(async (resolve, reject) => {
        await axios
			.post(`${REACT_APP_API_URL}/getStation`, JSON.stringify(stationId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
    });
}

export async function addStation(station) {
    return new Promise(async (resolve, reject) => {
        await axios
        .post(`${REACT_APP_API_URL}/station`,JSON.stringify(station))
        .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export async function updateStation(station) {
    return new Promise(async (resolve, reject) => {
        await axios
        .post(`${REACT_APP_API_URL}/updateStation`,JSON.stringify(station))
        .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export async function deleteStation(station) {
    return new Promise(async (resolve, reject) => {
        await axios
        .post(`${REACT_APP_API_URL}/deleteStation`,JSON.stringify(station))
        .then(async (response) => {
            resolve(response.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}
