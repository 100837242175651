export const defaultPermissions =
{
    superAdmin: [
        {
            name: 'createUsers',
            value: true,
        },
        {
            name: 'readUsers',
            value: true,
        },
        {
            name: 'updateUsers',
            value: true,
        },
        {
            name: 'deleteUsers',
            value: true,
        },
        {
            name: 'createRoles',
            value: true,
        },
        {
            name: 'readRoles',
            value: true,
        },
        {
            name: 'updateRoles',
            value: true,
        },
        {
            name: 'deleteRoles',
            value: true,
        },
        {
            name: 'createTemplates',
            value: true,
        },
        {
            name: 'readTemplates',
            value: true,
        },
        {
            name: 'updateTemplates',
            value: true,
        },
        {
            name: 'deleteTemplates',
            value: true,
        },
        {
            name: 'createBusinesses',
            value: true,
        },
        {
            name: 'readBusinesses',
            value: true,
        },
        {
            name: 'updateBusinesses',
            value: true,
        },
        {
            name: 'deleteBusinesses',
            value: true,
        },
        {
            name: 'createProducts',
            value: true,
        },
        {
            name: 'readProducts',
            value: true,
        },
        {
            name: 'updateProducts',
            value: true,
        },
        {
            name: 'deleteProducts',
            value: true,
        },
        {
            name: 'createLocations',
            value: true,
        },
        {
            name: 'readLocations',
            value: true,
        },
        {
            name: 'updateLocations',
            value: true,
        },
        {
            name: 'deleteLocations',
            value: true,
        },
        {
            name: 'createTables',
            value: true,
        },
        {
            name: 'readTables',
            value: true,
        },
        {
            name: 'updateTables',
            value: true,
        },
        {
            name: 'deleteTables',
            value: true,
        },
        {
            name: 'createStations',
            value: true,
        },
        {
            name: 'readStations',
            value: true,
        },
        {
            name: 'updateStations',
            value: true,
        },
        {
            name: 'deleteStations',
            value: true,
        },
        {
            name: 'createMenus',
            value: true,
        },
        {
            name: 'readMenus',
            value: true,
        },
        {
            name: 'updateMenus',
            value: true,
        },
        {
            name: 'deleteMenus',
            value: true,
        },
        {
            name: 'seeBoard',
            value: true,
        },
        {
            name: 'updateTickets',
            value: true,
        },
    ],
    admin: [
        {
            name: 'createUsers',
            value: true,
        },
        {
            name: 'readUsers',
            value: true,
        },
        {
            name: 'updateUsers',
            value: true,
        },
        {
            name: 'deleteUsers',
            value: true,
        },
        {
            name: 'createRoles',
            value: true,
        },
        {
            name: 'readRoles',
            value: true,
        },
        {
            name: 'updateRoles',
            value: true,
        },
        {
            name: 'deleteRoles',
            value: true,
        },
        {
            name: 'createTemplates',
            value: true,
        },
        {
            name: 'readTemplates',
            value: true,
        },
        {
            name: 'updateTemplates',
            value: true,
        },
        {
            name: 'deleteTemplates',
            value: true,
        },
        {
            name: 'createBusinesses',
            value: true,
        },
        {
            name: 'readBusinesses',
            value: true,
        },
        {
            name: 'updateBusinesses',
            value: true,
        },
        {
            name: 'deleteBusinesses',
            value: true,
        },
        {
            name: 'createProducts',
            value: true,
        },
        {
            name: 'readProducts',
            value: true,
        },
        {
            name: 'updateProducts',
            value: true,
        },
        {
            name: 'deleteProducts',
            value: true,
        },
        {
            name: 'createLocations',
            value: true,
        },
        {
            name: 'readLocations',
            value: true,
        },
        {
            name: 'updateLocations',
            value: true,
        },
        {
            name: 'deleteLocations',
            value: true,
        },
        {
            name: 'createTables',
            value: true,
        },
        {
            name: 'readTables',
            value: true,
        },
        {
            name: 'updateTables',
            value: true,
        },
        {
            name: 'deleteTables',
            value: true,
        },
        {
            name: 'createStations',
            value: true,
        },
        {
            name: 'readStations',
            value: true,
        },
        {
            name: 'updateStations',
            value: true,
        },
        {
            name: 'deleteStations',
            value: true,
        },
        {
            name: 'createMenus',
            value: true,
        },
        {
            name: 'readMenus',
            value: true,
        },
        {
            name: 'updateMenus',
            value: true,
        },
        {
            name: 'deleteMenus',
            value: true,
        },
        {
            name: 'seeBoard',
            value: true,
        },
        {
            name: 'updateTickets',
            value: true,
        },
    ],
    manager: [
        {
            name: 'createUsers',
            value: true,
        },
        {
            name: 'readUsers',
            value: true,
        },
        {
            name: 'updateUsers',
            value: true,
        },
        {
            name: 'deleteUsers',
            value: true,
        },
        {
            name: 'createRoles',
            value: false,
        },
        {
            name: 'readRoles',
            value: true,
        },
        {
            name: 'updateRoles',
            value: false,
        },
        {
            name: 'deleteRoles',
            value: false,
        },
        {
            name: 'createTemplates',
            value: false,
        },
        {
            name: 'readTemplates',
            value: false,
        },
        {
            name: 'updateTemplates',
            value: false,
        },
        {
            name: 'deleteTemplates',
            value: false,
        },
        {
            name: 'createBusinesses',
            value: false,
        },
        {
            name: 'readBusinesses',
            value: false,
        },
        {
            name: 'updateBusinesses',
            value: false,
        },
        {
            name: 'deleteBusinesses',
            value: false,
        },
        {
            name: 'createProducts',
            value: false,
        },
        {
            name: 'readProducts',
            value: false,
        },
        {
            name: 'updateProducts',
            value: false,
        },
        {
            name: 'deleteProducts',
            value: false,
        },
        {
            name: 'createLocations',
            value: true,
        },
        {
            name: 'readLocations',
            value: true,
        },
        {
            name: 'updateLocations',
            value: true,
        },
        {
            name: 'deleteLocations',
            value: true,
        },
        {
            name: 'createTables',
            value: true,
        },
        {
            name: 'readTables',
            value: true,
        },
        {
            name: 'updateTables',
            value: true,
        },
        {
            name: 'deleteTables',
            value: true,
        },
        {
            name: 'createStations',
            value: true,
        },
        {
            name: 'readStations',
            value: true,
        },
        {
            name: 'updateStations',
            value: true,
        },
        {
            name: 'deleteStations',
            value: true,
        },
        {
            name: 'createMenus',
            value: true,
        },
        {
            name: 'readMenus',
            value: true,
        },
        {
            name: 'updateMenus',
            value: true,
        },
        {
            name: 'deleteMenus',
            value: true,
        },
        {
            name: 'seeBoard',
            value: true,
        },
        {
            name: 'updateTickets',
            value: true,
        },
    ],
    operation: [
        {
            name: 'createUsers',
            value: false,
        },
        {
            name: 'readUsers',
            value: false,
        },
        {
            name: 'updateUsers',
            value: false,
        },
        {
            name: 'deleteUsers',
            value: false,
        },
        {
            name: 'createRoles',
            value: false,
        },
        {
            name: 'readRoles',
            value: false,
        },
        {
            name: 'updateRoles',
            value: false,
        },
        {
            name: 'deleteRoles',
            value: false,
        },
        {
            name: 'createTemplates',
            value: false,
        },
        {
            name: 'readTemplates',
            value: false,
        },
        {
            name: 'updateTemplates',
            value: false,
        },
        {
            name: 'deleteTemplates',
            value: false,
        },
        {
            name: 'createBusinesses',
            value: false,
        },
        {
            name: 'readBusinesses',
            value: false,
        },
        {
            name: 'updateBusinesses',
            value: false,
        },
        {
            name: 'deleteBusinesses',
            value: false,
        },
        {
            name: 'createProducts',
            value: false,
        },
        {
            name: 'readProducts',
            value: false,
        },
        {
            name: 'updateProducts',
            value: false,
        },
        {
            name: 'deleteProducts',
            value: false,
        },
        {
            name: 'createLocations',
            value: false,
        },
        {
            name: 'readLocations',
            value: false,
        },
        {
            name: 'updateLocations',
            value: false,
        },
        {
            name: 'deleteLocations',
            value: false,
        },
        {
            name: 'createTables',
            value: false,
        },
        {
            name: 'readTables',
            value: false,
        },
        {
            name: 'updateTables',
            value: false,
        },
        {
            name: 'deleteTables',
            value: false,
        },
        {
            name: 'createStations',
            value: false,
        },
        {
            name: 'readStations',
            value: false,
        },
        {
            name: 'updateStations',
            value: false,
        },
        {
            name: 'deleteStations',
            value: false,
        },
        {
            name: 'createMenus',
            value: false,
        },
        {
            name: 'readMenus',
            value: false,
        },
        {
            name: 'updateMenus',
            value: false,
        },
        {
            name: 'deleteMenus',
            value: false,
        },
        {
            name: 'seeBoard',
            value: true,
        },
        {
            name: 'updateTickets',
            value: true,
        },
    ]
}