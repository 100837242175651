import React from 'react';
import { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useModal } from '../../../hooks/useModal';
import Popup from '../../../components/Modals/Popup';
import { deleteGlobalProductOptionList, getGlobalProductOptionLists } from '../../../axios/globalProductOptionList';
import { deleteLocalProductOptionList, getLocalProductOptionLists } from '../../../axios/localProductOptionList';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import './Product.css';
import { notify } from '../../../utils/notify';
import { Image } from 'react-bootstrap';
import OptionListDetail from './Detail/OptionListDetail';
import Pencil from '../../../assets/NavBar Icons/Pencil.svg'
import TrashCan from '../../../assets/NavBar Icons/TrashCan.svg'

const ProductOptionList = (props) => {
	const [productOptionList, setProductOptionList] = useState([]);
	const [isOpenModal, openModal, closeModal, data] = useModal();
	const [isLoading, setIsLoading] = useState(false);
	const { userType, businessId, locationId } = JSON.parse(localStorage.getItem('user'));
	const [currentOptionList, setCurrentOptionList] = useState({ optionList: [], action: '' });
	const [isPopupOpen, setIsPopupOpen] = useState(false);


	useEffect(() => {
		_getProductOptionLists();
	}, []);

	useEffect(() => {
		props.popupOpen(isPopupOpen)
	}, [isPopupOpen])

	const _getProductOptionLists = async () => {
		setIsLoading(true)
		await Promise.all([
			getGlobalProductOptionLists({ businessId }),
			getLocalProductOptionLists({ locationId }),
		]).then(response => {
			const localProductOptionLists = response[1].map(item => ({
				...item,
				isGlobal: false
			}))
			const globalProductOptionLists = response[0].map(item => ({
				...item,
				isGlobal: true
			}))
				.filter(globalOptionList => localProductOptionLists.every(localOptionList => localOptionList.listId !== globalOptionList.id))

			const productOptionLists = globalProductOptionLists.concat(localProductOptionLists)

			setProductOptionList(productOptionLists);
			setIsLoading(false)
		}).catch(error => {
			notify('Unable to read data from database', 'error')
			console.log(error)
			setIsLoading(false)
		})
	};

	const handleEditOptionList = item => {
		setCurrentOptionList(item)
		setIsPopupOpen(true)
	}

	const deleteProductOptionList = async _data => {
		await (userType === 'admin'
			? deleteGlobalProductOptionList
			: deleteLocalProductOptionList)(_data)
			.then(() => {
				closeModal()
				_getProductOptionLists()
				notify('Option list data deleted successfully', 'success')
			}).catch(error => {
				notify('Error deleting option list data', 'error')
				console.log(error)
			})
	};

	return (
		<>
			<div className='main'>
				<Popup
					isOpen={isOpenModal}
					title='Delete productOptionList'
					label='Are you sure do you want to delete'
					onCancel={closeModal}
					cancelLabel='Cancel'
					onAccept={() => deleteProductOptionList(data)}
					acceptLabel='Accept'
				/>
				<LoadingSpinner
					isLoading={isLoading}
					label='Loading option lists...'
				/>
				{currentOptionList && isPopupOpen && <OptionListDetail
					handleClosePopup={() => setIsPopupOpen(false)}
					productOptionList={currentOptionList.optionList}
					action={currentOptionList.action}
					userType={userType}
					isMenuCollapsed={props.isMenuCollapsed}
					response={_getProductOptionLists}
				/>}
				<div className="row-header">
					<div>
						<header>Option Lists</header>
					</div>
					<div>
						<BsPlusCircleFill
							className='plus-icon color-cloche-red'
							onClick={() => handleEditOptionList({
								optionList: {
									isRequired: true,
									isMultipleChoice: true,
									isAvailable: true,
									businessId,
									locationId
								}, action: 'create'
							})}
						/>
					</div>
				</div>
				<div className='header'>
					<div className='productOptionList-name'>NAME</div>
					<div className='productOptionList-isRequired'>REQUIRED</div>
					<div className='productOptionList-isMultipleChoice'>MULTIPLE CHOICE</div>
					{userType === 'manager' &&
						<div className='productOptionList-isGlobal'>LEVEL</div>
					}
					<div className='productOptionList-isAvailable'>AVAILABLE</div>
					<div className='productOptionList-actions'>ACTIONS</div>
				</div>
				<div className='crud'>
					{productOptionList.map(productOptionList => (
						<div
							className='crud-row'
							key={productOptionList.id}
						>
							<div className='productOptionList-name'>{productOptionList.name}</div>
							<div className='productOptionList-isRequired'>{productOptionList.isRequired ? 'True' : 'False'}</div>
							<div className='productOptionList-isMultipleChoice'>{productOptionList.isMultipleChoice ? 'True' : 'False'}</div>
							{userType === 'manager' &&
								<div className='productOptionList-isGlobal'>{productOptionList.isGlobal ? 'Global' : 'Local'}</div>
							}
							<div className={`productOptionList-isAvailable ${!productOptionList.isAvailable && 'color-cloche-red'}`}>
								{productOptionList.isAvailable ? 'AVAILABLE' : 'NOT AVAILABLE'}
							</div>
							<div className='productOptionList-actions'>
								<Image
									className='action-icon'
									src={Pencil}
									onClick={() => handleEditOptionList({
										optionList: {
											...productOptionList,
											businessId: productOptionList.businessId ?? businessId,
											locationId: productOptionList.locationId ?? locationId,
										},
										action: 'edit'
									})}
								/>
								{(userType === 'admin' || !productOptionList.isGlobal) &&
									<Image
										className='action-icon color-cloche-red'
										src={TrashCan}
									onClick={() => openModal({ id: productOptionList.id, idSections: productOptionList.idSections  })}
									/>
								}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default ProductOptionList;
