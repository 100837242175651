import React, { useEffect,useState } from 'react';
import { Menu, MenuItem, ProSidebar, SidebarHeader, SidebarFooter } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Outlet } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { getStations } from '../../../axios/station';
import { GlobalColors } from '../../../assets/GlobalColors';
import Logo from '../../../assets/KitchenSideBar/Logo.svg';
import OrdersSelected from '../../../assets/NavBar Icons/OrdersSelected.svg';
import Kitchen from '../../../assets/NavBar Icons/Kitchen.svg';
import KitchenSelected from '../../../assets/NavBar Icons/KitchenSelected.svg';
import Drinks from '../../../assets/NavBar Icons/Drinks.svg';
import DrinksSelected from '../../../assets/NavBar Icons/DrinksSelected.svg';
import Orders from '../../../assets/NavBar Icons/Orders.svg';
import ServiceBoardSelected from '../../../assets/NavBar Icons/ServiceBoardSelected.svg';
import ServiceBoard from '../../../assets/NavBar Icons/ServiceBoard.svg'
import { MdDashboard, MdLogout } from 'react-icons/md';
import CompressionButton from '../SideMenu/CompressionButton';
import TextlessLogo from '../../../assets/KitchenSideBar/TextlessLogo.svg';
import './../SideMenu/SideMenu.css'

const SideNavigation = () => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const [stationList, setStationList] = useState([])
  const { userType, locationId } = JSON.parse(localStorage.getItem('user'));
  const { stationType } = JSON.parse(localStorage.getItem('station')) ?? {stationType:undefined}
  const [itemSelected, setItemSelected] = React.useState('0');
  const [isCompressed, setIsCompressed] = useState(false);

  const handleLogOut = () => {
    localStorage.clear()
    navigate('/sign-in');
  };
  
  const Styles = {
    sidebar: {
      height: '100%',
      position: 'fixed',
    },
    imageContainer: {
      color: GlobalColors.BLACK,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '15px',
    },
    menuItem:{
      width: '100%',
      marginBottom: '10px'
    },
    menuImage: { 
      width: '28px', 
      height: '28px'
    },
  };

  const toggleCompression = () => {
    setIsCompressed(!isCompressed);
  }

	useEffect(() =>  {
		_getStations();
	},[])

	const _getStations = async () => {
		const response = await getStations({locationId})
		setStationList(response);
	};
  
  const canView = (stationAux) => {
    if(userType === "operation"){
      return stationAux === stationType || stationAux === 'order-history';
    } else {
      return (userType === 'admin' || userType === 'manager') && stationList.find(s => s.type === stationAux) || (stationAux === 'order-history' && userType === 'manager') 
    }
  };
  useEffect(() => {
    switch (location.pathname) {
      case '/kitchen/':
        setItemSelected('1');
        break;
      case '/kitchen/home':
        setItemSelected('1');
        break;
      case '/kitchen':
        setItemSelected('2');
        break;
      case '/bar':
        setItemSelected('3');
        break;
      case '/service':
        setItemSelected('4');
        break;
      case '/order-history':
        setItemSelected('5');
        break; 
      default:
        setItemSelected('0');
        break;
    }
  }, [location]);

  return (
    <div
      className='side-navigation sidebar'
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100vh',
        backgroundColor: GlobalColors.KITCHEN_BACKGROUND,
      }}
    >
      <ProSidebar className={isCompressed ? 'CompressedProSiderbar' : "sidebar"} >
        <CompressionButton
          buttonClick={toggleCompression}
          isCompressed={isCompressed}
          marginRight={'-18px'}
        />
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            backgroundColor: GlobalColors.WHITE,
          }}
        >
          <SidebarHeader
            style={{
              border: 'none',
            }}
          >
            <div
              style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: '32px',
                paddingLeft: '20px',
                paddingRight: '20px',
                paddingBottom: '32px',
                backgroundColor: GlobalColors.WHITE,
              }}
            >
              <Image src={isCompressed ? TextlessLogo : Logo} style={{ height: '100%', width: '100%' }} />
            </div>
          </SidebarHeader>

          <Menu
            style={{
              width: '100%',
              height: 'auto',
            }}
          >
            { canView('kitchen') &&
              <MenuItem
              icon={
                <Image
                  src={itemSelected === '2' ? KitchenSelected : Kitchen}
                  alt="Menu"
                />
              }
                onClick={() => {
                  setItemSelected('2');
                  if(userType === 'manager') localStorage.setItem('station', JSON.stringify({"stationType":"kitchen"}));
                  navigate('/kitchen')
                }}
                style={Styles.menuItem}
              >
                <div
                  style={Styles.imageContainer}
                className={itemSelected === '2' ? 'itemSelected' : 'text-normal'}
                >                  
                  Kitchen
                </div>
              </MenuItem>
            }
            {canView('bar') &&
              <MenuItem
              icon={
                <Image
                  src={itemSelected === '3' ? DrinksSelected : Drinks}
                  alt="Menu"
                />
              }
                onClick={() => {
                  setItemSelected('3');
                  if (userType === 'manager') localStorage.setItem('station', JSON.stringify({ "stationType": "bar" }));
                  navigate('/bar')
                }}
                style={Styles.menuItem}
              >
                <div
                  style={Styles.imageContainer}
                className={itemSelected === '3' ? 'itemSelected' : 'text-normal'}
                >
                  Drinks
                </div>
              </MenuItem>
            }

            {canView('service') &&
              <MenuItem
              icon={
                <Image
                  src={itemSelected === '4' ? ServiceBoardSelected : ServiceBoard}
                  alt="Menu"
                />
              }
                onClick={() => {
                  setItemSelected('4');
                  if(userType === 'manager') localStorage.setItem('station', JSON.stringify({"stationType":"service"}));
                  navigate('/service');
                }}  
                style={Styles.menuItem}
              >
                <div
                  style={Styles.imageContainer}
                className={itemSelected === '4' ? 'itemSelected' : 'text-normal'}
                >
                  
                  Service
                </div>
              </MenuItem>
            }

            {canView('order-history') &&
              <MenuItem
              icon={
                <Image
                  src={itemSelected === '5' ? OrdersSelected : Orders}
                  alt="Menu"
                />
              }
                onClick={() => {
                  setItemSelected(5);
                  navigate('/order-history');
                }}  
                style={Styles.menuItem}
              >
                <div
                  style={Styles.imageContainer}
                className={itemSelected === '5' ? 'itemSelected' : 'text-normal'}
                >
                  Order History
                </div>
              </MenuItem>
            }
            {userType === 'manager' &&
              <MenuItem
                icon={<MdDashboard className='menu-icon' />}
                style={Styles.menuItem}
                onClick={() => {
                  navigate('/users');
                }}
              >
                Portal Admin
              </MenuItem>
            }
          </Menu>
        </div>

        <SidebarFooter className='Sidebarfooter' >
          <MenuItem
            className='sideBar-item'
            icon={<MdLogout className='menu-icon' />}
            onClick={() => { handleLogOut() }}
          >
            {!isCompressed && "Log out"}
          </MenuItem>
        </SidebarFooter>
      </ProSidebar>

      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: GlobalColors.KITCHEN_BACKGROUND,
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};
export default SideNavigation;
