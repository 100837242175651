import React from 'react';
import { useEffect, useState } from 'react';
import { ToggleSwitch } from '../../../../components/General/ToggleSwitch';
import { addOptionType, getOptionTypes } from '../../../../axios/optionType';
import { addGlobalProductOption, updateGlobalProductOption } from '../../../../axios/globalProductOption';
import { addLocalProductOption, updateLocalProductOption } from '../../../../axios/localProductOption';
import LoadingSpinner from '../../../../components/Modals/LoadingSpinner';
import { notify } from '../../../../utils/notify';

const ProductOptionDetail = (props) => {
	const {
		handleClosePopup,
		productOption,
		action,
		userType,
		isMenuCollapsed,
		response
	} = props
	const { locationId, businessId } = productOption;
	const [newProductOption, setNewProductOption] = useState({
		name: '',
		optionType: '',
		isGlobal: true,
		isAvailable: true,
	});
	const [optionTypeList, setOptionTypeList] = useState();
	const [optionType, setOptionType] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [loadingLabel, setLoadingLabel] = useState('Loading...');

	useEffect(() => {
		_getOptionType()
	}, []);

	const _getOptionType = async () => {
		setIsLoading(true)
		await getOptionTypes({ businessId }).then(optionTypes => {
			const _optionTypeList = [...optionTypes, { id: 1, name: 'Add new' }]
			setOptionType(_optionTypeList[0].name)
			setOptionTypeList(_optionTypeList);
			setNewProductOption({
				...newProductOption,
				...productOption,
				optionType: _optionTypeList[0].name
			})
			setIsLoading(false)
		}).catch(error => {
			notify('Unable to read data from database', 'error')
			console.log(error)
			setIsLoading(false)
		})
	};

	const validateData = () => {
		if (Object.values(newProductOption).some(x => x === '')) {
			notify('Please fill all fields', 'warning')
			return;
		}

		return true;
	};

	const handleProductOption = ({ value, field }) => {
		let _newProductOption = { ...newProductOption }
		if (field === 'option') {
			setOptionType(value)
			_newProductOption.optionType = value !== 'Add new' ? value : ''
		}
		else _newProductOption[field] = value

		setNewProductOption(_newProductOption);
	};

	const saveProductOption = async () => {
		if (!validateData()) {
			return;
		}

		setLoadingLabel('Saving product option..')
		setIsLoading(true)
		try {
			if (optionType === 'Add new') {
				const _optionType = { name: newProductOption.optionType, businessId }
				await addOptionType(_optionType)
			}

			const _productOption = newProductOption.isGlobal && userType === 'manager'
				? {
					...newProductOption,
					optionId: newProductOption.id,
					locationId,
					isGlobal: false,
					businessId: null,
				}
				: newProductOption;

			if (userType === 'admin') {
				await (action === 'create' ? addGlobalProductOption : updateGlobalProductOption)(_productOption);
			} else {
				await (newProductOption.isGlobal ? addLocalProductOption : updateLocalProductOption)(_productOption);
			}
			notify(`Option ${action === 'create' ? 'created' : 'edited'} successfully`, 'success')
			response()
			handleClosePopup()
			setIsLoading(false)
		} catch (error) {
			notify('Error saving data', 'error')
			console.log(error);
			setIsLoading(false)
		}
	};

	return (
		<>
			<LoadingSpinner
				isLoading={isLoading}
				label={loadingLabel}
			/>
			<div className="pop-up-modal">
				<div className={`pop-up-container ${isMenuCollapsed && 'collapsed'}`}>
					<div className="pop-up-header">{action === 'create' ? 'New Product Option' : 'Edit Product Option'}</div>
					<div className="pop-up-row-wrap">
						<div className="pop-up-col">
							<div className="pop-up-row">Name</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newProductOption.name}
									onChange={event => handleProductOption({ value: event.target.value, field: 'name' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">Option Type</div>
							<div className="pop-up-row">
								<select
									className='input-select'
									onChange={event => handleProductOption({ value: event.target.value, field: 'option' })}>
									{optionTypeList?.map(type => (
										<option
											key={type.id}
											defaulValue={type.name === productOption.optionType}
										>
											{type.name}
										</option>
									))}
								</select>
							</div>
						</div>
						{optionType === 'Add new' &&
							<div className="pop-up-col">
							<div className="pop-up-row">New Option Type</div>
								<div className="pop-up-row">
								<input
									className='input'
									defaultValue={productOption.optionType}
									onChange={event => handleProductOption({ value: event.target.value, field: 'optionType' })}
								/>
								</div>
							</div>
						}
						<div className="pop-up-col">
							<div className="pop-up-row">Available</div>
							<div className="pop-up-row">
								<ToggleSwitch
									label='Status'
									handleCheckBox={event => handleProductOption({ value: event, field: 'isAvailable' })}
									defaultChecked={productOption.isAvailable}
								/>
							</div>
						</div>
					</div>
					<br />
					<div className="pop-up-row-wrap justify-space-between">
						<div className="pop-up-col">
							<button
								className='btn-lg btn-cancel'
								onClick={handleClosePopup}>
								Cancel
							</button>
						</div>
						<div className="pop-up-col">
							<button
								className='btn-lg btn-confirm'
								onClick={saveProductOption}>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductOptionDetail;
