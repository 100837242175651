import React, { useState, useEffect } from 'react';
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';
import { useDispatch } from 'react-redux';

import { GlobalColors } from '../../assets/GlobalColors';
import OrderItem from './OrderItem';
import './Kitchen.css'

import { updateKitchenOrder } from '../../Redux/slices/KitchenSlices';

const OrderCard = ({ tableOrder, timestamp, setTableColor }) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState(0);
  const [bgColor, setBgColor] = useState('');
  const [cardBGColor, setCardBGColor] = useState('');
  const [color, setColor] = useState('');
  const [timeUpdated, setTimeUpdated] = useState(0);
  const { stationType } = JSON.parse(localStorage.getItem('station')) ?? { stationType: undefined };
  const boardLogic = {
    kitchen: "kitchen",
    service: "service",
    bar: "bar",
  }
  const styles = {
    container: {
      width: '99%',
      height: 'auto',
      borderRadius: '12px',
      padding: '20px',
      border: '1px solid' + bgColor,
      marginBottom: '16px',
    },
    orderHeader: {
      width: '100%',
      height: '65px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    orderHeaderLeft: {
      width: '70%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    orderHeaderRight: {
      width: '30%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    orderTimer: {
      width: '64px',
      height: '27px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '12px',
      fontSize: '14px',
    },
    orderItems: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      overflow: 'hidden',
      marginTop: '10px'
    },
    orderNotes: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: '20px',
      marginTop: '10px',
      borderBottom: '1px solid' + GlobalColors.BORDER_BOTTOM_GRAY_2,
    },
    bottomButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      height: '27px',
      width: '240px',
      borderRadius: '100px',
      bottom: '0',
      overflow: 'hidden',
      fontSize: '16px',
    }
  };

  useEffect(() => {
    if (timestamp !== 0) {
      const secondsSince1970 = Math.floor(Date.now() / 1000);
      const secondsSinceTimestamp = secondsSince1970 - timestamp;
      const millisecondsSinceTimestamp = secondsSinceTimestamp * 1000;

      setTime(millisecondsSinceTimestamp);
    }
  }, [timestamp]);

  useEffect(() => {
    const updateColors = (time) => {
      if (tableOrder.done) {
        setCardBGColor(GlobalColors.ORDER_STATUS_DELIVERED_BG);
      } else {
        setCardBGColor(GlobalColors.WHITE);

      }
      let colorScheme;
      if (time < 419400) {
        colorScheme = GlobalColors.COLOR_TIMER_1;
      } else if (time < 875400) {
        colorScheme = GlobalColors.COLOR_TIMER_2;
      } else {
        colorScheme = GlobalColors.COLOR_TIMER_3;
      }

      setBgColor(colorScheme);
      setColor(GlobalColors.WHITE);
      setTableColor(colorScheme);
    };

    updateColors(timeUpdated.time);
  }, [timeUpdated, tableOrder.done]);


  const handleFinishOrder = (tableOrder) => {
    if (!tableOrder.done) return;

    const updatedTableOrder = {
      ...tableOrder,
      timestamp: timeUpdated.time,
    };

    dispatch(
      updateKitchenOrder({
        tableOrder: updatedTableOrder,
        status: 'Delivered',
      })
    );
  };


  return (
    <div
      style={{
        ...styles.container
      }}
    >
      <div style={styles.orderHeader}>
        <div style={styles.orderHeaderLeft}>
          <div
            className="text-title"
            style={{
              color: GlobalColors.BLACK,
              fontSize: '16px',
            }}
          >
            <div>
              Order {tableOrder.orderNumber}
            </div>
          </div>

          <div
            className="text-title"
            style={{
              color: GlobalColors.GRAY,
              fontSize: '14px',
            }}
          >
            {tableOrder.items?.length || 0} items
          </div>
        </div>

        <div style={styles.orderHeaderRight}>
          <div
            className="text-title"
            style={{
              ...styles.orderTimer,
              backgroundColor: bgColor,
              color: color,
            }}
          >
            <Timer
              active={!tableOrder.served}
              duration={null}
              time={time}
              onStop={() => tableOrder.served}
              onTimeUpdate={(time) => {
                setTimeUpdated(time);
              }}
            >
              <Timecode format="mm:ss" />
            </Timer>
          </div>
        </div>
      </div>

      <div style={styles.orderItems}>
        {tableOrder.items && tableOrder.items.some(item => item.type === 'plates') ? (
          <div style={{ width: '100%', height: '100%', paddingLeft: 24, paddingRight: 24, paddingTop: 8, paddingBottom: 8, background: 'rgba(40, 40, 40, 0.15)', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex', borderRadius: '12px' }}>
            <div style={{ color: 'black', fontSize: 14, fontFamily: 'Manrope', fontWeight: '700', wordWrap: 'break-word' }}>Food</div>
          </div>
        ) : null}
        {tableOrder.items &&
          tableOrder.items.filter(item => item.type === 'plates').map((item, idx) => {
            return (
              <div key={`${idx}-container`}
                style={{
                  margin: '10px 0',
                }}>
                <OrderItem key={idx} item={item} isFoodTruck={tableOrder.isFoodTruck} />
                {item.extraItems &&
                  <div className='Extra-Container'>
                    {Object.keys(item.extraItems).map((key, index) => {
                      return (
                        <div className='Extra-Container-Item' key={`${index}-extra`}>
                          {item.extraItems[key].map((children, index) => {
                            return (
                              <div key={`${index}-child`}>
                                {children.name}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                }
                {item.notes &&
                  <div style={styles.orderNotes}>
                    <div
                      className="text-title"
                      style={{
                        color: GlobalColors.GRAY_1,
                        fontSize: '14px',
                      }}
                    >
                      Notes:
                    </div>
                    <div
                      className="text-normal"
                      style={{
                        color: GlobalColors.GRAY_1,
                        fontSize: '14px',
                        display: 'flex',
                        textAlign: 'left',
                      }}
                    >
                      {item.notes}
                    </div>
                  </div>
                }
              </div>
            );
          })}
        {tableOrder.items && tableOrder.items.some(item => item.type === 'drinks') ? (
          <div style={{ width: '100%', height: '100%', paddingLeft: 24, paddingRight: 24, paddingTop: 8, paddingBottom: 8, background: 'rgba(40, 40, 40, 0.15)', justifyContent: 'flex-start', alignItems: 'center', gap: 10, display: 'inline-flex', borderRadius: '12px' }}>
            <div style={{ color: 'black', fontSize: 14, fontFamily: 'Manrope', fontWeight: '700', wordWrap: 'break-word' }}>Drink</div>
          </div>
        ) : null}
        {tableOrder.items &&
          tableOrder.items.filter(item => item.type === 'drinks').map((item, idx) => {
            return (
              <div key={`${idx}-container`}
                style={{
                  margin: '10px 0',
                }}>
                <OrderItem key={idx} item={item} isFoodTruck={tableOrder.isFoodTruck} />
                {item.extraItems &&
                  <div className='Extra-Container'>
                    {Object.keys(item.extraItems).map((key, index) => {
                      return (
                        <div className='Extra-Container-Item' key={`${index}-extra`}>
                          {item.extraItems[key].map((children, index) => {
                            return (
                              <div key={`${index}-child`}>
                                {children.name}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                }
                {item.notes &&
                  <div style={styles.orderNotes}>
                    <div
                      className="text-title"
                      style={{
                        color: GlobalColors.GRAY_1,
                        fontSize: '14px',
                      }}
                    >
                      Notes:
                    </div>
                    <div
                      className="text-normal"
                      style={{
                        color: GlobalColors.GRAY_1,
                        fontSize: '14px',
                        display: 'flex',
                        textAlign: 'left',
                      }}
                    >
                      {item.notes}
                    </div>
                  </div>
                }
              </div>
            );
          })}
      </div>
      {stationType === boardLogic.service &&
        <div
          onClick={() => handleFinishOrder(tableOrder)}
          style={{
            borderTop: '1px solid rgb(50,50,50, 0.2)',
            paddingTop: '20px',
            fontWeight: 'bolder',
          }}
        >
          {tableOrder.done ? "Ready" : tableOrder.items.filter(item => item.status === 'In preparation').length + " items pending"}
        </div>
      }
    </div>
  );
};

export default OrderCard;
