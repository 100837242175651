import React from 'react';
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { addLocation, updateLocation } from '../../../../axios/location';
import LoadingSpinner from '../../../../components/Modals/LoadingSpinner';
import { notify } from '../../../../utils/notify';

const LocationDetail = (props) => {
	const {
		handleClosePopup,
		location,
		action,
		userType,
		fullScreen,
		isCollapsed,
		isMenuCollapsed,
		response
	} = props
	const { businessId, locationId } = JSON.parse(localStorage.getItem('user'));
    const [newLocation, setNewLocation] = useState({
		name: '',
		address: '',
        type: '',
        city: '',
        state: '',
        postalCode: '',
		country: '',
		businessId: businessId,
		id: locationId
	})
	const locationType = [
		{ name: 'Restaurant', value: 'restaurant' },
		{ name: 'Foodtruck', value: 'foodtruck' },
	];
	const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate()

	useEffect(() => {
		setNewLocation({ ...newLocation, ...location })
	}, [])

	const handleLocationData = async ({ event, field }) => {
		let value = event.target.value
		if (field === 'postalCode') {
			value = (event.target.validity.valid && value >= 0)
				? parseInt(value) || ''
				: newLocation.postalCode
		}
		setNewLocation({
			...newLocation,
			[field]: value,
		});
    }

    const validateData = async () => {
		if (Object.values(newLocation).some(x => x === '')) {
			notify('Please fill all fields', 'warning')
			return;
		}
        return true
	};
    
    const saveLocation = async () => {
        if (!(await validateData())) {
			return;
		}

		setIsLoading(true)
		try {
			await (action === 'create' ? addLocation : updateLocation)(newLocation)
			if (userType === 'admin') navigate('/locations')
			notify(`Location ${action === 'create' ? 'created' : 'edited'} successfully`, 'success')
			if (fullScreen) localStorage.setItem('myLocation', JSON.stringify(newLocation));
			else {
				response()
				handleClosePopup()
			}
			setIsLoading(false)
		} catch (error) {
			notify('Error saving data', 'error')
			console.log(error);
			setIsLoading(false)
		}
    }

    return (
		<>
			<LoadingSpinner
				isLoading={isLoading}
				label='Saving location...'
			/>
			<div className="pop-up-modal">
				<div className={`${(isCollapsed || isMenuCollapsed) && 'collapsed'} ${fullScreen ? 'full-screen-container' : 'pop-up-container'}`}>
					<div className="pop-up-header">{action === 'create' ? 'New Location' : 'Edit Location'}</div>
					<div className="pop-up-row-wrap">
						<div className="pop-up-col">
							<div className="pop-up-row">Name</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newLocation.name}
									onChange={event => handleLocationData({ event, field: 'name' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">Address</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newLocation.address}
									onChange={event => handleLocationData({ event, field: 'address' })}
								/>
							</div>
						</div>
						<div className="pop-up-col" style={{ width: '300px' }}>
							<div className="radio-row">Location Type</div>
							<div className="radio-row">
								{locationType.map(type => (
									<div className='radio-div' key={type.id}>
										<div>
											<input
												type='radio'
												className='radio-input'
												value={type.value}
												checked={newLocation.type === type.value}
												name='locationType'
												onChange={event => handleLocationData({ event, field: 'type' })}
											/>
										</div>
										<div>
											{type.name}
										</div>
									</div>
								))}
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">City</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newLocation.city}
									onChange={event => handleLocationData({ event, field: 'city' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">State/Province</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newLocation.state}
									onChange={event => handleLocationData({ event, field: 'state' })}
								/>
							</div>
						</div>
						<div className="pop-up-col" style={{width:'300px'}}>
							<div className="pop-up-row">ZIP/Postal Code</div>
							<div className="pop-up-row">
								<input
									className='input-sm'
									pattern='[0-9]{0,13}'
									maxLength='9'
									value={newLocation.postalCode}
									onChange={event => handleLocationData({ event, field: 'postalCode' })}
								/>
							</div>
						</div>
						<div className="pop-up-col">
							<div className="pop-up-row">Country</div>
							<div className="pop-up-row">
								<input
									className='input'
									defaultValue={newLocation.country}
									onChange={event => handleLocationData({ event, field: 'country' })}
								/>
							</div>
						</div>

					</div>
					<br />
					<div className={fullScreen ? "full-screen-button" : "pop-up-row-wrap justify-space-between"}>
						<div className="pop-up-col">
							{!fullScreen &&
								<button
									className='btn-lg btn-cancel'
									onClick={handleClosePopup}>
									Cancel
								</button>
							}
						</div>
						<div className="pop-up-col">
							<button
								className='btn-lg btn-confirm'
								onClick={saveLocation}>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default LocationDetail;
