import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setHelpModalState, getAssistance } from '../../Redux/slices/ModalsSlices';
import { selectTableNum } from '../../Redux/slices/ProductsSlices';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GlobalColors } from '../../assets/GlobalColors';
import { Button } from 'react-bootstrap';




const HelpModal = (props) => {

  const dispatch = useDispatch();
  const isMobileS = useMediaQuery('(max-width: 320px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptopL = useMediaQuery('(max-width: 1440px)');
  const tableId = useSelector(selectTableNum);
  const handleAssistance = async () => {
    dispatch(getAssistance({tableId: tableId}));
  };
      

  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setHelpModalState(false));
    }, 1500);
    return () => clearTimeout(timer);
  }, [dispatch]);

  return (
    <Modal
      className="help-modal"
      {...props}
      size={
        isMobileM || isMobileL || isTablet
          ? 'lg'
          : 'sm'
      }
      centered
    >
      <div
        style={{
          height: isMobileS || isMobileM || isLaptopL
            ? '250px'
            : '300px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          borderRadius: '30px',
          backgroundColor: GlobalColors.GRAY_MODAL_BACKGROUND,
          padding: '10px',
        }}
      >
        <div
          style={{
            fontSize: '20px'
          }}
          className="text-title"
        >
          Help is on the way
        </div>

        <div
          style={{
            fontSize: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="text-normal">A waiter will be coming </div>
          <div className="text-normal">to your table you shortly</div>
        </div>
        <Button className='Welcome-Button'
          onClick={(e) => {
            e.preventDefault();
            handleAssistance()
            dispatch(setHelpModalState(false));
          }}
          style={{
          backgroundColor: GlobalColors.CLOCHE_RED_SECONDARY,
          color: GlobalColors.WHITE,
        }}>
          <div className="text-title">Got it</div>
        </Button>
      </div>
      
    </Modal>
  );
};

export default HelpModal;
