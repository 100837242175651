import { useEffect, useState } from 'react'
import NoImage from '../../assets/NoImage.png'
import './DragAndDrop.css'

export const DragAndDrop = ({ image, width, height, isProduct, handlerSetter, isFromContent }) => {
    const [imagePrevious, setImagePrevious] = useState(isProduct ? NoImage : undefined);

    useEffect(() => {
        if (image.length) setImagePrevious(image)
    }, [image])

    const handlerDragAndDrop = event => {
        const reader = new FileReader();
        const file = event.target.files[0];
        const img = new Image();
        const widthImage = parseInt(width, 10);
        const heightImage = parseInt(height, 10);

        img.onload = function () {
            const resizedDataURL = resizeImage(this, widthImage, heightImage);
            if (isFromContent) {
                handlerSetter(resizedDataURL)
            } else {
                handlerSetter([resizedDataURL, true]);
            }
        };

        reader.readAsDataURL(file);
        reader.onload = e => {
            e.preventDefault();
            img.src = e.target.result;
        };
    };

    function resizeImage(img, maxWidth, maxHeight) {
        const canvas = document.createElement('canvas');
        width = img.width;
        height = img.height;

        if (width > height) {
            if (width > maxWidth) {
                height = Math.round(height *= maxWidth / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round(width *= maxHeight / height);
                height = maxHeight;
            }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        return canvas.toDataURL('image/png');
    }

    return (
        <div>
            <div className="StyleDrageArea" style={{ width, height }}>
                <div className={'image-uplad-wrap'} style={{ width, height }}>
                    <input
                        className="file-uplad-input"
                        type="file"
                        accept="image/*"
                        src={imagePrevious}
                        onChange={event => handlerDragAndDrop(event)}
                    />

                    <div
                        className={`prev-img ${isProduct ? 'product' : 'cover'}`}
                        style={isProduct ? { width: '90px', height: '90px' } : { width, height }}
                    >
                        <img
                            className="prev-image"
                            src={imagePrevious}
                            alt=""
                        />
                    </div>
                    <div className="DragAndDropLabel" style={{ maxWidth: width }}>
                        Drag and drop your image or upload a file
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DragAndDrop