import React from 'react';
import { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useModal } from '../../../hooks/useModal';
import Popup from '../../../components/Modals/Popup';
import { deleteGlobalMenu, getGlobalMenus } from '../../../axios/globalMenu';
import { deleteLocalMenu, getLocalMenus } from '../../../axios/localMenu';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import './Menu.css';
import { notify } from '../../../utils/notify';
import { Image } from 'react-bootstrap';
import Pencil from '../../../assets/NavBar Icons/Pencil.svg'
import TrashCan from '../../../assets/NavBar Icons/TrashCan.svg'

const moment = require('moment');
const MAX_RETRIES = 3;

const Menu = (props) => {
	const [menusList, setMenusList] = useState([]);
	const [isOpenModal, openModal, closeModal, data] = useModal();
	const [isLoading, setIsLoading] = useState(false);
	const { userType, businessId, locationId } = JSON.parse(localStorage.getItem('user'));

	useEffect(() => {
		getMenus();
		props.popupOpen(false)
	}, []);

	const isCurrentlyActive = (startingHour, endingHour, selectedDays) => {
		const currentMoment = moment();
		const currentDay = currentMoment.format('ddd');
		const currentTime = currentMoment.format('HH:mm');

		return selectedDays.includes(currentDay) && currentTime >= startingHour && currentTime <= endingHour;
	}



	const fetchMenus = async (businessId, locationId) => {
		const [globalMenusResponse, localMenusResponse] = await Promise.all([
			getGlobalMenus({ businessId }),
			getLocalMenus({ locationId }),
		]);

		const localMenus = localMenusResponse.map(item => ({
			...item,
			isGlobal: false,
			status: isCurrentlyActive(item.startingHour, item.endingHour, item.selectedDays)
		}));

		const globalMenus = globalMenusResponse.map(item => ({
			...item,
			isGlobal: true,
			status: isCurrentlyActive(item.startingHour, item.endingHour, item.selectedDays)
		}))
			.filter(globalMenu => localMenus.every(localMenu => localMenu.menuId !== globalMenu.id));

		return globalMenus.concat(localMenus);
	};

	const getMenus = async () => {
		setIsLoading(true);

		for (let i = 0; i < MAX_RETRIES; i++) {
			try {
				const menusList = await fetchMenus(businessId, locationId);
				setMenusList(menusList);
				setIsLoading(false);
				break;
			} catch (error) {
				if (i === MAX_RETRIES - 1) {
					notify('Unable to read data from database', 'error');
					console.log(error);
					setIsLoading(false);
				}
			}
		}
	};

	const _deleteMenu = async (_data) => {
		await (userType === 'admin'
			? deleteGlobalMenu
			: deleteLocalMenu)(_data)
			.then(() => {
				getMenus()
				closeModal()
				notify('Menu data deleted successfully', 'success')
			}).catch(error => {
				notify('Error deleting menu data', 'error')
				console.log(error)
			})
	};

	const truncateDescription = (description, maxLength = 75) => {
		return description.length > maxLength
			? `${description.substring(0, maxLength)}...`
			: description;
	}

	const formatTime = (timeString) => {
		const options = { hour: '2-digit', minute: '2-digit', hour12: false };
		return new Date(`1970-01-01T${timeString}`).toLocaleTimeString('en-US', options);
	};



	return (
		<>
			<div className='main'>
				<Popup
					isOpen={isOpenModal}
					title='Delete menu'
					label='Are you sure do you want to delete'
					onCancel={closeModal}
					cancelLabel='Cancel'
					onAccept={() => _deleteMenu(data)}
					acceptLabel='Accept'
				/>
				<LoadingSpinner
					isLoading={isLoading}
					label='Loading menus...'
				/>
				<div className="row-header">
					<div>
						<header>Menus</header>
					</div>
					<div>
						<Link
							to='detail'
							state={{ menu: { businessId, locationId, status: true }, action: 'create', userType }}>
							<BsPlusCircleFill className='plus-icon color-cloche-red' />
						</Link>
					</div>
				</div>
				<div className="menu-table-container">
					<div className='menu-header'>
						<div className='menu-name'>NAME</div>
						<div className='menu-description'>DESCRIPTION</div>
						{userType === 'manager' &&
							<div className='menu-isGlobal'>LEVEL</div>
						}
						<div className='menu-days'>DAYS</div>
						<div className='menu-startingHour'>STARTING HOUR</div>
						<div className='menu-endingHour'>ENDING HOUR</div>
						<div className='menu-status'>STATUS</div>
						<div className='menu-actions'>ACTIONS</div>
					</div>
					<div className='crud'>
						{menusList.map(menu => (
							<div
								className='menu-crud-row'
								key={menu.id}>
								<div className='menu-name'>{menu.name}</div>
								<div className='menu-description' title={menu.description}>
									{truncateDescription(menu.description)}
								</div>							{userType === 'manager' &&
									<div className='menu-isGlobal'>{menu.isGlobal ? 'Global' : 'Local'}</div>
								}
								<div className='menu-days'>{menu.selectedDays?.join(', ')}</div>
								<div className='menu-startingHour'>{formatTime(menu.startingHour)}</div>
								<div className='menu-endingHour'>{formatTime(menu.endingHour)}</div>
								<div className={`menu-status ${!menu.status && 'color-cloche-red'}`}>{menu.status ? 'ACTIVE' : 'INACTIVE'}</div>
								<div className='menu-actions'>
									<Link
										to='detail'
										state={{
											menu: {
												...menu,
												businessId: menu.businessId ?? businessId,
												locationId: menu.locationId ?? locationId,
											},
											action: 'edit',
											userType
										}}>
										<Image className='action-icon' src={Pencil} />
									</Link>
									{(userType === 'admin' || !menu.isGlobal) &&
										<Image
											className='action-icon color-cloche-red'
											src={TrashCan}
											onClick={() => openModal({ id: menu.id })}
										/>
									}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default Menu;
