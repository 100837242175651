import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { setThankYouModalState } from '../../Redux/slices/ModalsSlices';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GlobalColors } from '../../assets/GlobalColors';
import { Button } from 'react-bootstrap';


const ThankYouModal = (props) => {
  const dispatch = useDispatch();
  const isMobileS = useMediaQuery('(max-width: 320px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptop = useMediaQuery('(max-width: 1024px)');

  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setThankYouModalState(false));
    }, 1500);
    return () => clearTimeout(timer);
  }, [dispatch]);

  return (
    <Modal
      className="help-modal"
      {...props}
      size={isMobileM || isMobileL || isTablet
          ? 'lg'
          : 'sm'
      }
      centered
    >
      <div
        style={{
          height: isMobileS || isMobileM || isLaptop
            ? '250px'
            : '300px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          borderRadius: '30px',
          backgroundColor: GlobalColors.GRAY_MODAL_BACKGROUND,
          padding: '10px',
        }}
      >
        <div
          style={{
            fontSize: '20px',
          }}
          className="text-title"
        >
          Thank you for your order!
        </div>

        <div
          style={{
            fontSize: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="text-normal">Your <span style={{fontWeight: 700}}>status order</span> will change to <span style={{fontWeight: 700}}>Ready</span> </div>
          <div className="text-normal">when your items become </div>
          <div className="text-normal">available for <span style={{fontWeight: 700}}>pick-up</span>.</div>

        </div>
        <Button className='Welcome-Button'
          onClick={(e) => {
            e.preventDefault();
            dispatch(setThankYouModalState(false));
          }}
          style={{
          backgroundColor: GlobalColors.CLOCHE_RED_SECONDARY,
          color: GlobalColors.WHITE,
          borderRadius: '30px',
          
        }}>
          <div className="text-title">Got it</div>
        </Button>
      </div>
    </Modal>
  );
};

export default ThankYouModal;
