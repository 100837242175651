import React from 'react';
import { Outlet } from 'react-router-dom';

import NavBar from '../NavBar/NavBar';

const FooterLayout = () => {
  return (
    <div>
      <Outlet />
      <NavBar />
    </div>
  );
};

export default FooterLayout;
