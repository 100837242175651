import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import Logo from '../../../assets/KitchenSideBar/Logo.svg';
import Buisness from '../../../assets/QuickStart/businessInfo.svg';
import Dotted from '../../../assets/QuickStart/dotted.svg';
import Lined from '../../../assets/QuickStart/line.svg';
import Location from '../../../assets/QuickStart/location.svg';
import Users from '../../../assets/QuickStart/users.svg';


import { Outlet } from 'react-router-dom';
import './QuickStart.css';

const Header = (props) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div>
			<div className="header-header">
				<Image src={Logo} className='header-logo' />
				<div>
					{isMobile ? (<div className='stepperContainer'>
						{props.currentStep === 1 &&
							<>
								<div className='businessStep'>
									<div className='businessImageContainer'>
										<Image src={Buisness} className="businessImage" />
									</div>
									<div className='dottedImageContainer'>
										<Image src={Dotted} className="dottedImage" />
									</div>
								</div>
								<div className="quickStart-title">BUSINESS INFORMATION</div>
							</>
						}
						{props.currentStep === 2 &&
							<>
								<div className='locationStep'>
									<div>
										<Image src={Lined} />
									</div>
									<div className='businessImageContainer'>
										<Image src={Location} className="businessImage" />
									</div>
									<div>
										<Image src={Dotted} />
									</div>
								</div>
								<div className="quickStart-title">LOCATION INFORMATION</div>
							</>
						}
						{props.currentStep === 3 &&
							<>
								<div className='businessStep'>
									<div className='dottedImageContainer'>
										<Image src={Dotted} />
									</div>
									<div className='locationImageContainer'>
										<Image src={Users} className="businessImage" />
									</div>
								</div>
								<div className="quickStart-title">USERS & STATIONS </div>
							</>
						}
						<div className='stepperText'> In progress</div>
					</div>
					) : (
						<div className="header-stepper">
							<div className="step">
								<div className={`circle ${props.currentStep === 1 ? 'step-active' : 'step-inactive'}`}>1</div> Business information
							</div>
							<div className="step">
								<div className={`circle ${props.currentStep === 2 ? 'step-active' : 'step-inactive'}`}>2</div> Location information
							</div>
							<div className="step">
								<div className={`circle ${props.currentStep === 3 ? 'step-active' : 'step-inactive'}`}>3</div> Users & stations
							</div>
						</div>
					)}
				</div>
			</div>
			<Outlet className="content" />
		</div>
	);
};

export default Header;
