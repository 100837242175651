import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setWelcomeModalState } from '../../Redux/slices/ModalsSlices';
import { GlobalColors } from '../../assets/GlobalColors';
import customImages from '../../utils/customImages'
import { Button, Image } from 'react-bootstrap';
import './Modals.css'
import { selectFee } from '../../Redux/slices/ProductsSlices';

const WelcomeModal = (props) => {
  const dispatch = useDispatch();
  const fee = useSelector(selectFee);

  return (
    <Modal className='Modal-Holder'
      {...props}
      size={'lg'}

      centered
      style={{
        backgroundColor: 'none',
      }}
    >
      <div className='Welcome-Modal-Container'>
        <div className='Icons-Container'>
          <Image
            src={customImages.welcomeBackground}
            style={{
              width: '60%',
            }}
          />
        </div>
        <div className='Welcome-Text-Container pt-3'>
          <h3>Welcome to a new ordering experience with no wait</h3>
          <p>{`*${fee}% service charge included`}</p>
        </div>
        <Button className='Welcome-Button'
          onClick={(e) => {
            e.preventDefault();
            dispatch(setWelcomeModalState(false));
            window.location.reload();
          }}
          style={{
            backgroundColor: GlobalColors.CLOCHE_RED_SECONDARY,
            color: GlobalColors.WHITE,
          }}>
          <div className="text-title">I am in!</div>
        </Button>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
