import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Button } from 'react-bootstrap';
import './HeaderLayout.css';
import { GlobalColors } from '../../../assets/GlobalColors';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  setkindOfFoodSelected,
  selectMenuSelection,
  selectKindsOfFood,
  setTableNumber,
  setIsFoodTruck,
  setLocationType,
  setRestaurantName,
  setBusinessId,
  setLocationId,
  fetchProducts,
  selectIsFoodTruck,
  setFeePercentage,
  selectBusinessId,
  selectLocationId,
  setBusinessImage,
  selectBusinessImage,
  resetProductsModal,
  selectTableId,
  setTableId,
} from '../../../Redux/slices/ProductsSlices';
import NavBar from '../NavBar/NavBar';
import { RxDoubleArrowUp } from 'react-icons/rx';
import { setHelpModalState, resetModal, setWelcomeModalState } from '../../../Redux/slices/ModalsSlices';
import { MdOutlineContactSupport } from 'react-icons/md'
import { getNameAndLogic } from '../../../axios/table';
import { REACT_APP_API_URL } from '../../../utils/envConfig';



const HeaderLayout = () => {
  const dispatch = useDispatch();

  const isMobileS = useMediaQuery('(max-width: 320px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptop = useMediaQuery('(max-width: 1024px)');
  const urlParams = useParams();
  const kindsOfFood = useSelector(selectKindsOfFood);
  const foodMenuSelected = useSelector(selectMenuSelection)
  const [radioValue, setRadioValue] = useState(0);
  const [showButton, setShowButton] = useState('none');
  const businessId = useSelector(selectBusinessId);
  const businessImage = useSelector(selectBusinessImage);
  const isFoodTruck = useSelector(selectIsFoodTruck);
  const locationId = useSelector(selectLocationId)
  const tableId = useSelector(selectTableId);
  const fontSizes = () => {
    return isMobileS ? ['11px', '13px'] :
      isMobileM ? ['12px', '14px'] :
        isMobileL ? ['15px', '17px'] :
          isTablet ? ['16px', '18px'] :
            ['18px', '20px']
  }
  const handleClick = (value) => {
    setRadioValue(value);
    const kindOfFoodSelected = kindsOfFood.find((kindOfFood) => kindOfFood.value === value);
    dispatch(setkindOfFoodSelected(kindOfFoodSelected.kind));
    const element = document.getElementById(kindOfFoodSelected.kind);

    if (element) {
      let headerOffset = 90;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleAskAssistance = () => {
    dispatch(setHelpModalState(true));
  };

  window.onscroll = function () {
    myFunction();
  };

  const header = document.getElementById('myHeader');

  function myFunction() {
    if (header !== null) {
      setShowButton(window.pageYOffset !== 0 ? 'flex' : 'none');
    }
  }

  useEffect(() => {
    if (foodMenuSelected) {
      let value = kindsOfFood.find(el => el.name === foodMenuSelected)?.value
      setRadioValue(value)
      dispatch(setkindOfFoodSelected('all'));
    }
  }, [dispatch, foodMenuSelected]);

  const handleTableNumberLogic = async () => {
    try {
      const initialUrlParams = JSON.parse(localStorage.getItem('initialUrlParams')) || urlParams;
      if (!localStorage.getItem('initialUrlParams')) {
        localStorage.setItem('initialUrlParams', JSON.stringify(urlParams));
      }

      dispatch(setLocationId(initialUrlParams.locationId));
      dispatch(setBusinessId(initialUrlParams.businessId));
      dispatch(setTableId(initialUrlParams.tableId));

      const lastIndex = REACT_APP_API_URL.lastIndexOf('cloche-');
      const env = REACT_APP_API_URL.substring(lastIndex).replace('-', 'web').replace('testing', 'testin');
      const imageUrl = `https://${env}.blob.core.windows.net/${businessId}/coverImage.png`;
      let coverImage = '';
      if (await imageExists(imageUrl)) {
        coverImage = imageUrl;
      }

      async function imageExists(url) {
        try {
          const res = await fetch(url, { method: 'HEAD' });
          return res.status === 200 ? true : false
        } catch (error) {
          return false;
        }
      }

      dispatch(setBusinessImage(coverImage));

      const urlIds = {
        businessId: businessId,
        tableId: initialUrlParams.tableId,
        locationId: initialUrlParams.locationId,
      };

      const namesAndLogic = await getNameAndLogic(urlIds);
      dispatch(setFeePercentage(namesAndLogic.businessFeePercentage));
      dispatch(setRestaurantName(namesAndLogic.businessName));
      dispatch(setIsFoodTruck(namesAndLogic.isToGoLogic || namesAndLogic.locationType === 'pre-paid-restaurant'));
      dispatch(setLocationType(namesAndLogic.locationType));
      setCurrentTableNumber();
    } catch (error) {
      console.log(error);
    }
  };


  const setCurrentTableNumber = () => {
    const initialUrlParams = JSON.parse(localStorage.getItem('initialUrlParams'));
    try {
      dispatch(setTableNumber(initialUrlParams.tableId ?? 0));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (tableId && urlParams.tableId && (tableId !== urlParams.tableId)) {
      dispatch(resetModal());
      dispatch(resetProductsModal());
      dispatch(setWelcomeModalState(true));
      localStorage.setItem('initialUrlParams', JSON.stringify(urlParams));
    }

    handleTableNumberLogic();

  }, [urlParams, dispatch]);

  useEffect(() => {
    if (locationId && businessId) {
      dispatch(() => {
        if (locationId && businessId) {
          const query = {
            locationId,
            businessId,
          };
          dispatch(fetchProducts(query));
        }
      });
    }
  }, []);


  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          height: 'auto',
          width: isMobileM || isMobileL || isTablet
            ? '100%'
            : isLaptop
              ? '70%'
              : '60%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Image
            src={businessImage}
            alt="Logo"
            style={{
              width: '100%',
              maxHeight: '40vh',
            }}
            id="logo"
          />
        </div>


        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '1px',
            overflowX: 'scroll',
            borderBottom: `2px solid ${GlobalColors.GRAY_1}`,
            padding: '5px',
            backgroundColor: GlobalColors.WHITE,
            position: 'sticky',
            top: '0px'
          }}
          id="myHeader"
        >
          {kindsOfFood?.map((kindOfFood, idx) => {
            return (
              <button
                key={idx}
                value={kindOfFood.value}
                checked={radioValue === kindOfFood.value}
                onClick={(e) => { handleClick(kindOfFood.value) }}
                style={{

                  height: '80%',
                  fontSize: radioValue === kindOfFood.value ? fontSizes()[1] : fontSizes()[0],
                  border: 'none',
                  backgroundColor: 'transparent',
                  borderBottom: radioValue === kindOfFood.value ? `2px solid ${GlobalColors.CLOCHE_RED}` : 'none',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  flex: '1 1 auto',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: GlobalColors.BLACK,
                }}
                className={radioValue === kindOfFood.value ? 'text-title' : 'text-normal'}
              >
                <div style={{
                  whiteSpace: 'nowrap',
                  width: 'auto',
                  flexBasis: 'fit-content',
                }}>
                  {kindOfFood.name}
                </div>
              </button>
            );
          })}
        </div>

        <Outlet className="content" />
        <NavBar />

      </div>
      {!isFoodTruck &&
        <Button
          onClick={handleAskAssistance}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            position: 'fixed',
            bottom: '85px',
            right: '10px',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            backgroundColor: GlobalColors.CLOCHE_RED,
            transition: 'all 0.5s ease'
          }}
        >
          <MdOutlineContactSupport
            style={{
              width: '25px',
              height: '25px',
            }}
          />
        </Button>
      }
      <Button
        onClick={handleScrollToTop}
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          position: 'fixed',
          bottom: '145px',
          right: '14px',
          display: showButton,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          border: 'none',
          backgroundColor: GlobalColors.WHITE,
          transition: 'all 0.5s ease',
          color: GlobalColors.BLACK,
          boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.2)'
        }}
      >
        <RxDoubleArrowUp
          style={{
            width: '30px',
            height: '30px',
          }}
        />
      </Button>
    </div>
  );
};

export default HeaderLayout;
