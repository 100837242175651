import React, { useEffect } from 'react';
import { useState } from 'react';
import PhoneInput from 'react-phone-number-input'
import DragAndDrop from '../../../components/General/DragAndDrop';
import 'react-phone-number-input/style.css'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { addBusiness, uploadCoverImage, deleteBusiness } from '../../../axios/business';
import { addLocation, deleteLocation } from '../../../axios/location';
import { addTable, updateTable, deleteTable } from '../../../axios/table'
import { defaultPermissions } from '../../../utils/defaultPermissions';
import './../../../components/General/General.css'
import './QuickStart.css'
import { addStation, deleteStation } from '../../../axios/station';
import { addRole, deleteRole } from '../../../axios/role';
import { addUser, deleteUser } from '../../../axios/user';
import { useNavigate } from 'react-router'
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import { notify } from '../../../utils/notify';
import { encryptPassword } from '../../../utils/encryptPassword';
import { BusinessStatus } from '../Business/BusinessStatus';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import StripeNextSteps from './StripeNextSteps';

let userType;
const userLocalStorage = localStorage.getItem('user');
if (userLocalStorage) {
	({ userType } = JSON.parse(userLocalStorage));
} else {
	userType = 'externalClient';
}

const Content = (props) => {
	const { referralCode: referralCodeFromParams } = useParams();
	const referralCodeFromStorage = localStorage.getItem('referralCode');
	const referralCode = referralCodeFromParams || referralCodeFromStorage;
	const [business, setBusiness] = useState({
		name: '',
		ownerName: '',
		ownerLastName: '',
		phoneNumber: '',
		email: '',
		country: '',
		state: '',
		city: '',
		address: '',
		postalCode: '',
		feePercentage: (referralCode === 'CHE2023' || referralCode === 'ChefEddie' || referralCode === 'chefeddie') ? 3 : 2,
		referredBy: referralCode || 'N/A',
		status: BusinessStatus.STRIPE_VERIFICATION
	});
	const [location, setLocation] = useState({
		name: '',
		type: '',
		country: '',
		state: '',
		city: '',
		address: '',
		postalCode: '',
	});
	const [stations, setStations] = useState([]);
	const [roles, setRoles] = useState([
		{
			userType: 'admin',
			name: 'Admin',
			description: 'Admin role',
			permissions: defaultPermissions['admin']
		},
		{
			userType: 'manager',
			name: 'Manager',
			description: 'Manager role',
			permissions: defaultPermissions['manager']
		}
	]);
	const [users, setUsers] = useState([
		{
			auxType: 'admin',
			username: '',
			name: '',
			status: false,
			password: '',
			userType: ''
		},
		{
			auxType: 'manager',
			username: '',
			name: '',
			status: false,
			password: '',
			userType: ''
		}
	]);
	const [coverImage, setCoverImage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [currentStep, setCurrenStep] = useState(1);
	const [tableQuantity, setTableQuantity] = useState(1);
	const [stripeLink, setStripeLink] = useState('');
	const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
	const navigate = useNavigate()
	const [rollbackData, setRollbackData] = useState({
		businessId: '',
		locationId: '',
		tablesId: [],
		stationsId: [],
		rolesId: [],
		usersId: [],
	});

	useEffect(() => {
		if (referralCodeFromParams) {
			localStorage.setItem('referralCode', referralCodeFromParams);
			setBusiness((currentBusiness) => ({
				...currentBusiness,
				feePercentage: (referralCodeFromParams === 'CHE2023' || referralCodeFromParams === 'ChefEddie') ? 3 : 2,
				referredBy: referralCodeFromParams
			}));
		}
	}, [referralCodeFromParams]);

	useEffect(() => {
		const isFirstVisit = localStorage.getItem('firstVisitToQuickstart') === null;

		if (isFirstVisit) {
			localStorage.setItem('firstVisitToQuickstart', 'visited');
			navigate('/welcome');
		}
	}, []);

	useEffect(() => {
		props.setCurrenStep(currentStep);
	}, [currentStep]);

	const stationTypes = [
		{ value: 'kitchen', name: 'Kitchen' },
		{ value: 'bar', name: 'Bar' },
		{ value: 'service', name: 'Service' },
	];

	const locationType = [
		{ name: 'Restaurant', value: 'restaurant' },
		{ name: 'Foodtruck', value: 'foodtruck' },
	];

	const updateRollbackData = (newData) => {
		setRollbackData((prevData) => ({
			...prevData,
			...newData,
		}));
	};

	const addTableId = (tableId) => {
		setRollbackData((prevData) => ({
			...prevData,
			tablesId: [...prevData.tablesId, tableId],
		}));
	};

	const addStationId = (stationId) => {
		setRollbackData((prevData) => ({
			...prevData,
			stationsId: [...prevData.stationsId, stationId],
		}));
	};

	const addRoleId = (roleId) => {
		setRollbackData((prevData) => ({
			...prevData,
			rolesId: [...prevData.rolesId, roleId],
		}));
	};

	const addUserId = (userId) => {
		setRollbackData((prevData) => ({
			...prevData,
			usersId: [...prevData.usersId, userId],
		}));
	};

	const handleBusinessData = async ({ value, field, event }) => {
		let _value = value
		if (field === 'email') {
			const _users = [...users]
			_users[0] = {
				auxType: 'admin',
				username: value,
				name: value,
				status: true,
				password: value,
				userType: 'admin',
			}
			setUsers(_users)
		} else if (field === 'postalCode') {
			_value = validateNumber(event) ? value : business.postalCode
		}
		setBusiness({
			...business,
			[field]: _value,
		});
	}

	const validateNumber = event => {
		const value = event.target.value
		return (event.target.validity.valid && value >= 0)
	};

	const setTables = event => {
		const value = validateNumber(event) ? event.target.value : tableQuantity
		setTableQuantity(value)
	}

	const handleLocationData = async ({ value, field, event }) => {
		let _value = value
		if (field === 'postalCode') {
			_value = validateNumber(event) ? _value : location.postalCode
		}

		if (field === 'type') {
			let _stations = []
			let _roles = []
			let _users = []
			if (value === 'foodtruck') {
				_stations = [...stations, { name: 'Kitchen', type: 'kitchen', status: true }]
				_roles = [...roles, {
					userType: 'operation',
					name: 'Cook',
					description: 'Cook role for kitchen',
					permissions: defaultPermissions['operation']
				}]
				_users = [...users,
				{
					userType: 'operation',
					auxType: 'kitchen',
					username: '',
					name: '',
					status: false,
					password: '',
				}]
			} else {
				_stations = stations.filter(station => station.type !== 'kitchen')
				_roles = roles.filter(role => role.userType !== 'kitchen')
				_users = users.filter(user => user.auxType !== 'kitchen')
			}

			setStations(_stations)
			setRoles(_roles)
			setUsers(_users)
		}
		setLocation({
			...location,
			[field]: _value,
		});
	}

	const handleStationData = (event) => {
		const value = event.target.value
		const isChecked = event.target.checked
		const type = {
			kitchen: 'Cook',
			bar: 'Bartender',
			service: 'Runner'
		}

		const _stations = isChecked
			? [...stations, { name: value.charAt(0).toUpperCase() + value.slice(1), type: value, status: true }]
			: stations.filter(station => station.type !== value)

		const _roles = isChecked
			? [...roles, {
				userType: 'operation',
				name: type[value],
				description: `${type[value]} role for ${value}`,
				permissions: defaultPermissions['operation']
			}]
			: roles.filter(role => role.userType !== value)

		const _users = isChecked
			? [...users,
			{
				userType: 'operation',
				auxType: value,
				username: '',
				name: '',
				status: false,
				password: '',
			}]
			: users.filter(user => user.auxType !== value)

		setStations(_stations)
		setRoles(_roles)
		setUsers(_users)
	}

	const handleUserData = async ({ value, type, auxType }) => {
		const index = users.findIndex(x => x.auxType === auxType)
		const _users = [...users]
		_users[index] = {
			auxType,
			username: value,
			name: value,
			status: true,
			password: value,
			userType: type,
		}
		setUsers(_users)
	}

	const validateBusiness = () => {
		const requiredData = [business.name, business.ownerName, business.email];
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (requiredData.some(x => x === '')) {
			notify('Please fill required fields', 'warning');
			return;
		}

		if (!emailPattern.test(business.email)) {
			notify('Please enter a valid email address', 'warning');
			return;
		}

		setCurrenStep(2);
	};

	const validateLocation = () => {
		const requiredData = [location.name, location.type]
		if (requiredData.some(x => x === '')) {
			notify('Please fill required fields', 'warning')
			return;
		}
		setCurrenStep(3)
	};

	const validateData = () => {
		if (!stations.length ||
			Object.values(users).some(user => Object.values(user).some(x => x === ''))) {
			notify('Please fill required fields', 'warning')
			return;
		}
		return true
	};

	const rollback = async () => {
		await Promise.all([
			deleteBusiness({ id: rollbackData.businessId }),
			deleteLocation({ id: rollbackData.locationId }),
			rollbackData.tablesId.map(tableId => deleteTable({ id: tableId })),
			rollbackData.stationsId.map(stationId => deleteStation({ id: stationId })),
			rollbackData.rolesId.map(roleId => deleteRole({ id: roleId })),
			rollbackData.usersId.map(userId => deleteUser({ id: userId })),
		])
	}

	const saveData = async () => {
		if (!validateData()) {
			return;
		}

		setIsLoading(true)
		try {
			const businessSaved = await addBusiness(business)
			if (businessSaved.stripeLink && stripeLink === '') setStripeLink(businessSaved.stripeLink);
			updateRollbackData({ businessId: businessSaved.id });
			if (coverImage) await uploadCoverImage({ businessId: businessSaved.id, coverImage })
			await saveLocation(businessSaved.id)
		} catch (error) {
			notify('Error saving business data', 'error')
			rollback()
			setIsLoading(false)
			console.log(error)
		}
	}

	const createTables = async (locationId, businessId) => {
		try {

			for (let i = 0; i < tableQuantity; i++) {
				const newTable = {
					tableId: '',
					name: `${i + 1}`,
					isAvailable: true,
					locationId,
					businessId,
					needsAssistance: false,
					QRurl: ''
				}

				await addTable(newTable)
					.then((res) => {
						const tableId = res.id
						addTableId(tableId);
						const environment = res.environment

						const QRurl = `https://${environment}.blob.core.windows.net/${businessId}/${locationId}/${tableId}.png`
						updateTable({ ...newTable, tableId, QRurl })
					})
					.catch(error => {
						notify('Error saving table data', 'error')
						console.log(error)
					})
			}
		} catch (error) {
			notify('Error saving tables data', 'error')
			rollback()
			setIsLoading(false)
			console.log(error)
		}
	}

	const saveLocation = async businessId => {
		try {
			const locationSaved = await addLocation({ ...location, businessId })
			updateRollbackData({ locationId: locationSaved.id });
			await saveStations(businessId, locationSaved.id)
		} catch (error) {
			notify('Error saving business data', 'error')
			rollback()
			setIsLoading(false)
			console.log(error)
		}
	}

	const saveStations = async (businessId, locationId) => {
		try {
			const _stationsSaved = await Promise.all(stations.map(async station => {
				const stationSaved = await addStation({ ...station, locationId })
				addStationId(stationSaved.id)
				return stationSaved
			}))
			const stationsSaved = _stationsSaved.map(station => ({ id: station.id, type: station.type }))
			await createTables(locationId, businessId)
			await saveRoles(businessId, locationId, stationsSaved)
		} catch (error) {
			notify('Error saving stations data', 'error')
			rollback()
			setIsLoading(false)
			console.log(error)
		}
	}

	const saveRoles = async (businessId, locationId, stationsSaved) => {
		try {
			let rolesCopy = roles
			const roleTypes = {
				Admin: 'admin',
				Manager: 'manager',
				Cook: 'kitchen',
				Bartender: 'bar',
				Runner: 'service',
			}
			const rolesToSave = rolesCopy.map(role => {
				let _role = { ...role, businessId }
				if (_role.userType === 'manager') {
					_role.locationId = locationId
				} else if (_role.userType === 'operation') {
					const station = stationsSaved.find(x => x.type === roleTypes[_role.name])
					_role.station = [station.id]
					_role.locationId = locationId
				}
				return _role
			})
			const rolesSaved = await Promise.all(rolesToSave.map(async role => {
				const roleSaved = await addRole(role)
				addRoleId(roleSaved.id)
				return roleSaved
			}))
			const rolesData = rolesSaved.map(role => ({ id: role.id, type: roleTypes[role.name] }))
			await saveUsers(businessId, locationId, rolesData)
		} catch (error) {
			notify('Error saving roles data', 'error')
			rollback()
			setIsLoading(false)
			console.log(error)
		}
	}

	const saveUsers = async (businessId, locationId, rolesSaved) => {
		await Promise.all(users.map(async user => {
			const role = rolesSaved.find(x => x.type === user.auxType)
			const passwordEncrypted = encryptPassword(user.password)
			let _user = { ...user, businessId, roleId: role.id, password: passwordEncrypted }

			if (_user.userType === 'manager' || _user.userType === 'operation') {
				_user.locationId = locationId
			}
			const userSaved = await addUser(_user)
			addUserId(userSaved.id)
			return userSaved
		})).then(() => {
			setIsLoading(false)
			notify(`Data added successfully`, 'success')
			setCurrenStep(4);
		}).catch(error => {
			notify(error.response.data ?? 'Error saving users data', 'error')
			rollback()
			setIsLoading(false)
			console.log(error)
		})
	}

	return (
		<>
			<div className='quickStart-main'>
				<LoadingSpinner
					isLoading={isLoading}
					label="Your data is being saved. You will be redirected to the login screen once it's completed"
					width="400px"
					height="170px"
				/>
				{currentStep === 1 ?
					<div className="quickStart-content">
						<div className="quickStart-form">
							<div className="quickStart-title">Business information</div>
							<div className="quickStart-row">
								<div className="quickStart-col">
									Business name*
									<input
										type="text"
										className='input-lg'
										defaultValue={business.name}
										placeholder="e.g. The Central Laguna, The Urban Fox, etc"
										onChange={event =>
											handleBusinessData({ value: event.target.value, field: 'name' })
										}
									/>
								</div>
								<div className="quickStart-col">
									First Name(s)*
									<input
										type="text"
										className='input'
										defaultValue={business.ownerName}
										placeholder="Your name"
										onChange={event =>
											handleBusinessData({ value: event.target.value, field: 'ownerName' })
										}
									/>
								</div>
								<div className="quickStart-col">
									Last Name(s)
									<input
										type="text"
										className='input'
										defaultValue={business.ownerLastName}
										placeholder="Your last name"
										onChange={event =>
											handleBusinessData({ value: event.target.value, field: 'ownerLastName' })
										}
									/>
								</div>
								<div className="quickStart-col">
									Phone number
									<PhoneInput
										className='phone-input'
										defaultValue={business.phoneNumber}
										defaultCountry="US"
										international={true}
										limitMaxLength="10"
										placeholder='Number'
										onChange={event =>
											handleBusinessData({ value: event, field: 'phoneNumber' })
										}
									/>
								</div>
								<div className="quickStart-col">
									Email*
									<input
										type="text"
										className='input'
										defaultValue={business.email}
										placeholder="Your email"
										onChange={event =>
											handleBusinessData({ value: event.target.value, field: 'email' })
										}
									/>
								</div>
								<div className="quickStart-col">
									Country
									<CountryDropdown
										className='input'
										value={business.country}
										onChange={event =>
											handleBusinessData({ value: event, field: 'country' })}
									/>
								</div>
								<div className="quickStart-col">
									State/Province
									<RegionDropdown
										className='input'
										value={business.state}
										country={business.country}
										onChange={event =>
											handleBusinessData({ value: event, field: 'state' })}
									/>
								</div>
								<div className="quickStart-col">
									City
									<input
										type="text"
										className='input'
										defaultValue={business.city}
										placeholder="City"
										onChange={event =>
											handleBusinessData({ value: event.target.value, field: 'city' })
										}
									/>
								</div>
								<div className="quickStart-col">
									Address
									<input
										type="text"
										className='input'
										defaultValue={business.address}
										placeholder="Address"
										onChange={event =>
											handleBusinessData({ value: event.target.value, field: 'address' })
										}
									/>
								</div>
								<div className="quickStart-col">
									ZIP/Postal Code
									<input
										type="text"
										className='input'
										pattern='[0-9]{0,13}'
										maxLength="9"
										value={business.postalCode}
										placeholder="e.g. 77007"
										onChange={event =>
											handleBusinessData({ value: event.target.value, field: 'postalCode', event })
										}
									/>
								</div>
								<div className="quickStart-col">
									Cover photo, please attach an image with dimensions of 700px (width) by 200px (height) for the best display.
									<DragAndDrop
										width={isMobile ? "350px" : "700px"}
										height={isMobile ? "100px" : '200px'}
										image={coverImage}
										handlerSetter={setCoverImage}
										isFromContent={true}
									/>
								</div>
							</div>
							<div className="quickStart-button">
								{userType != 'externalClient' &&
									<button
										className="btn btn-cancel"
										onClick={() => navigate('/businesses')}
									> &lt;- Back</button>
								}
								<button
									className="btn btn-confirm"
									onClick={() => validateBusiness()}
								> Continue -&gt; </button>
							</div>
						</div>
					</div>
					: <></>
				}
				{currentStep === 2 ?
					<div className="quickStart-content">
						<div className="quickStart-form">
							<div className="quickStart-title">Location information</div>
							<div className="quickStart-row">
								<div className="quickStart-col">
									Location name*
									<input
										type="text"
										className='input-lg'
										defaultValue={location.name}
										placeholder="e.g. Houston, TX"
										onChange={event =>
											handleLocationData({ value: event.target.value, field: 'name' })
										}
									/>
								</div>
								<div className="quickStart-col">
									<div className="quickStart-row">
										Location type*
									</div>
									<div className="quickStart-row">
										{locationType.map(type => (
											<div>
												<input
													type='radio'
													className='m-2'
													checked={location.type === type.value}
													value={type.value}
													name='locationType'
													onChange={event => handleLocationData({ value: event.target.value, field: 'type' })}
												/>
												{type.name}
											</div>
										))}
									</div>
								</div>
								<div className="quickStart-col">
									Country
									<CountryDropdown
										className='input'
										value={location.country}
										onChange={event =>
											handleLocationData({ value: event, field: 'country' })}
									/>
								</div>
								<div className="quickStart-col">
									State/Province
									<RegionDropdown
										className='input'
										value={location.state}
										country={location.country}
										onChange={event =>
											handleLocationData({ value: event, field: 'state' })}
									/>
								</div>
								<div className="quickStart-col">
									City
									<input
										type="text"
										className='input'
										defaultValue={location.city}
										placeholder="City"
										onChange={event =>
											handleLocationData({ value: event.target.value, field: 'city' })
										}
									/>
								</div>
								<div className="quickStart-col">
									Address
									<input
										type="text"
										className='input'
										defaultValue={location.address}
										placeholder="Address"
										onChange={event =>
											handleLocationData({ value: event.target.value, field: 'address' })
										}
									/>
								</div>
								<div className="quickStart-col">
									ZIP/Postal Code
									<input
										type="text"
										className='input'
										pattern='[0-9]{0,13}'
										maxLength="9"
										value={location.postalCode}
										placeholder="e.g. 77007"
										onChange={event =>
											handleLocationData({ value: event.target.value, field: 'postalCode', event })
										}
									/>
								</div>
							</div>
							<div className="quickStart-button">
								<button
									className="btn btn-cancel"
									onClick={() => setCurrenStep(1)}
								> &lt;- Back  </button>
								<button
									className="btn btn-confirm"
									onClick={() => validateLocation()}
								> Continue -&gt; </button>
							</div>
						</div>
					</div>
					: <></>
				}
				{currentStep === 3 ?
					<div className="quickStart-content">
						<div className="quickStart-form">
							<div className="quickStart-row">
								<div className="quickStart-title">Stations</div>
								<div className='quickStart-row'>
									<div className='quickStart-col'>
										Enter number of tables
										<input
											type="text"
											className='m-2'
											pattern='[0-9]+'
											maxLength='2'
											value={tableQuantity}
											name="numTables"
											onChange={setTables}
										/>
									</div>
								</div>
								{location.type === 'restaurant' &&
									<div className="quickStart-row">
										<div className="quickStart-col">
											Select the stations you want to include in your business
											{stationTypes.map(type => (
												<div key={type.value}>
													<input
														type='checkbox'
														className='m-2'
														value={type.value}
														name='stations'
														onChange={event => handleStationData(event)}
													/>
													{type.name}
												</div>
											))}
										</div>
									</div>
								}
							</div>
							<div className="quickStart-row">
								<div className="quickStart-title">Users</div>
								<div className="quickStart-row">
									<div className="quickStart-col">
										<div className="quickStart-subtitle">Admin</div>
										Username
										<input
											type="text"
											className='input'
											placeholder="Username"
											defaultValue={business.email}
											onChange={event =>
												handleUserData({ value: event.target.value, type: 'admin', auxType: 'admin' })
											}
										/>
									</div>
									<div className="quickStart-col">
										Password
										<input
											type="text"
											className='input'
											value={users[users.findIndex(x => x.auxType === 'admin')].password}
										/>
									</div>
								</div>
								<div className="quickStart-row">
									<div className="quickStart-col">
										<div className="quickStart-subtitle">Manager</div>
										Username
										<input
											type="text"
											className='input'
											placeholder="Username"
											onChange={event =>
												handleUserData({ value: event.target.value, type: 'manager', auxType: 'manager' })
											}
										/>
									</div>
									<div className="quickStart-col">
										Password
										<input
											type="text"
											className='input'
											value={users[users.findIndex(x => x.auxType === 'manager')].password}
										/>
									</div>
								</div>
								{stations?.some(x => x.type === 'kitchen') ?
									<div className="quickStart-row">
										<div className="quickStart-col">
											<div className="quickStart-subtitle">Cook</div>
											Username
											<input
												type="text"
												className='input'
												placeholder="Username"
												onChange={event =>
													handleUserData({ value: event.target.value, type: 'operation', auxType: 'kitchen' })
												}
											/>
										</div>
										<div className="quickStart-col">
											Password
											<input
												type="text"
												className='input'
												value={users[users.findIndex(x => x.auxType === 'kitchen')]?.password}
											/>
										</div>
									</div>
									: <></>}
								{stations?.some(x => x.type === 'bar') ?
									<div className="quickStart-row">
										<div className="quickStart-col">
											<div className="quickStart-subtitle">Bartender</div>
											Username
											<input
												type="text"
												className='input'
												placeholder="Username"
												onChange={event =>
													handleUserData({ value: event.target.value, type: 'operation', auxType: 'bar' })
												}
											/>
										</div>
										<div className="quickStart-col">
											Password
											<input
												type="text"
												className='input'
												value={users[users.findIndex(x => x.auxType === 'bar')].password}
											/>
										</div>
									</div>
									: <></>}
								{stations?.some(x => x.type === 'service') ?
									<div className="quickStart-row">
										<div className="quickStart-col">
											<div className="quickStart-subtitle">Runner</div>
											Username
											<input
												type="text"
												className='input'
												placeholder="Username"
												onChange={event =>
													handleUserData({ value: event.target.value, type: 'operation', auxType: 'service' })
												}
											/>
										</div>
										<div className="quickStart-col">
											Password
											<input
												type="text"
												className='input'
												value={users[users.findIndex(x => x.auxType === 'service')].password}
											/>
										</div>
									</div>
									: <></>}
								<div className="quickStart-button">
									<button
										className="btn btn-cancel"
										onClick={() => setCurrenStep(2)}
									> &lt;- Back  </button>
									<button
										className="btn btn-confirm"
										onClick={() => saveData()}
									> Save </button>
								</div>
							</div>
						</div>
					</div>
					: <></>
				}
				{currentStep === 4 ?
					<StripeNextSteps setCurrenStep={setCurrenStep} stripeLink={stripeLink} rollBack={rollback} />
					: <></>
				}
			</div>
		</>
	);
};

export default Content;
