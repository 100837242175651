import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useDispatch } from 'react-redux';
import { setOrdersListTip } from  '../../Redux/slices/ProductsSlices';
import { GlobalColors } from '../../assets/GlobalColors';
import PriceFormat from '../../components/General/PriceFormat';
import { setAddTipModalState } from '../../Redux/slices/ModalsSlices';

const CheckoutInfo = ({ kind, price, showButton, tip, isPaid }) => {

  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const handleClick = () => {
    
    setShow(!show);
  }
  useEffect(() => {
    setTimeout(() => setShow(false),4000)
  },[show])

  const handleTipClick = () => {
    setOrdersListTip(price)
    dispatch(setAddTipModalState(true))
  }
  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '10px',
        marginBottom: '10px',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: `1px solid ${GlobalColors.BORDER_BOTTOM_GRAY}`,
      }}
    >
      <div
        style={{
          width: 'auto',
          height: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <div
          className="text-normal"
          style={{
            color: GlobalColors.BLACK,
            fontSize: '14px',
          }}
        >
          {kind}
        </div>

        {showButton ? (
          <OverlayTrigger
            style={{backgroundColor: GlobalColors.GRAY_MODAL_BACKGROUND}}
            trigger="click"
            key="top"
            placement="top"
            show={show}
            onHide={() => setShow(false)}
            overlay={
              <Popover
                id={`popover-positioned-top`}
                style={{
                  maxWidth: '95%',
                  width: '100%',
                  height: 'auto',
                  border: 'none',
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
                  backgroundColor: GlobalColors.BORDER_BOTTOM_GRAY_2
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px',
                    gap: '10px',
                  }}
                >
                  <div
                    className="text-title"
                    style={{
                      color: GlobalColors.BLACK,
                      fontSize: '20px',
                      width: '100%',
                      height: 'auto',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'left',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: 'auto',
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 'none',
                        padding: '0px',
                        backgroundColor: 'transparent',
                        color: GlobalColors.CLOCHE_RED,
                      }}
                    >
                    <div
                      className="text-title"
                      style={{
                        width: '20px',
                        height: '20px',
                        fontSize: '16px',
                        border: '2px solid ' + GlobalColors.CLOCHE_RED,
                        borderRadius: '50%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      i
                    </div>
                  </div>
                  </div>

                  <div
                    className="text-normal"
                    style={{
                      color: GlobalColors.BLACK,
                      fontSize: '14px',
                      width: '100%',
                      height: 'auto',
                      textAlign: 'left',
                    }}
                  >
                    <div className="d-inline-flex">Cloche charges a &nbsp;<div className="d-inline-flex" style={{fontWeight: 600}}>2% service fee &nbsp;</div> to continue</div>
                    <div className="d-inline-flex">growing and improving our technology</div>
                  </div>
                </div>
              </Popover>
            }
          >
            <Button
              onClick={handleClick}
              style={{
                width: 'auto',
                height: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                padding: '0px',
                backgroundColor: 'transparent',
                color: GlobalColors.CLOCHE_RED,
              }}
            >
              <div
                className="text-title"
                style={{
                  width: '14px',
                  height: '14px',
                  fontSize: '12px',
                  border: '1px solid' + GlobalColors.CLOCHE_RED,
                  borderRadius: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                i
              </div>
            </Button>
          </OverlayTrigger>
        ) : null}
      </div>
        <div className='d-inline-flex'>
        {tip && isPaid &&
            <Button
              onClick={() => handleTipClick()}
              style={{
                backgroundColor: GlobalColors.CLOCHE_RED,
                border: 'none',
                fontSize: '12px',
                borderRadius: '20px',
                marginRight: '10px',
                paddingBottom: '4px',
                paddingTop: '4px',
              }}
            >
              Change
            </Button>
          }
        <div
          style={{
            width: 'auto',
            height: 'auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <div
            className="text-title"
            style={{
              color: GlobalColors.BORDER_BOTTOM_GRAY,
              fontSize: '14px',
            }}
          >
            <PriceFormat price={price} />
          </div>


        </div>
      </div>
    </div>
  );
};

export default CheckoutInfo;
