import { REACT_APP_API_URL } from '../utils/envConfig';
import axios from 'axios';

export async function getGlobalProductOptionLists (data) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getGlobalProductOptionLists`, JSON.stringify(data))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function getGlobalProductOptionList (globalProductOptionListId) {
	return new Promise(async (resolve, reject) => {
		await axios
			.post(`${REACT_APP_API_URL}/getGlobalProductOptionList`, JSON.stringify(globalProductOptionListId))
			.then(async response => {
				resolve(response.data);
			})
			.catch(error => {
				reject(error);
			});
	});
}

export async function addGlobalProductOptionList(globalProductOptionList) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/globalProductOptionList`, JSON.stringify(globalProductOptionList))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}

export async function updateGlobalProductOptionList(globalProductOptionList) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/updateGlobalProductOptionList`, JSON.stringify(globalProductOptionList))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}

export async function deleteGlobalProductOptionList (globalProductOptionListId) {
  return new Promise(async (resolve, reject) => {
    await axios
		.post(`${REACT_APP_API_URL}/deleteGlobalProductOptionList`, JSON.stringify(globalProductOptionListId))
		.then(async (response) => {
			resolve(response.data);
		})
		.catch((error) => {
			reject(error);
		});
  });
}
