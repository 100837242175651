import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { 
  selectClientSecret,
  selectTotal, 
  selectOrdersListTip,
  selectOrdersListSubTotal, 
  selectRestaurantName,
  selectBusinessId
} from "../../Redux/slices/ProductsSlices";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Image } from 'react-bootstrap';
import { GlobalColors } from '../../assets/GlobalColors';
import { FiChevronLeft } from 'react-icons/fi';
import PriceFormat from '../../components/General/PriceFormat';
import { setStripeModalState } from '../../Redux/slices/ModalsSlices';
import { useNavigate } from 'react-router-dom';
import  customImages  from '../../utils/customImages'
import { REACT_STRIPE_SK } from "../../utils/envConfig";

import './Modals.css'
function StripeModal() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const clientSecretRedux = useSelector(selectClientSecret);
  const [tipState, setTipState ] = useState(0)
  const total = useSelector(selectTotal);
  const resName = useSelector(selectRestaurantName);
  const businessId = useSelector(selectBusinessId);
  const tip = useSelector(selectOrdersListTip);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDone, setIsDone] = useState(false)
  
  useEffect(() => {
    setTipState(tip)
  },[])

  const getStripeAccount = (businessId) => {
    if(businessId == "20e5b53b-d5d2-4f82-aa69-65e5f5dfed87" || businessId == "9ff68a6a-1ed2-435c-a20f-d531ffa3ef3b" || businessId == "48b14f68-011a-4555-b50a-e30059b72edd") {
      return "acct_1MfSJmHk9hOY8CNV"
    } else if(businessId == "bb0f3196-0c26-487b-b544-577aad107814") {
      return "acct_1MJnGBFA4ovAcgwF"
    } else if (businessId == "7eb1ff68-fb7b-4e3e-914b-966d14a6f0a0"){
      return "acct_1MovOdDIH9t1LDKA"
    }
  }

  const isDoneFunction = (args) => {
    setIsDone(args)
  }
  const totalPayState = total + tipState 
  useEffect(() => {
    let key;

    const publishableKey = "pk_live_51MEa44AXcTZHUHtoieizE0Ts2riVXnOnaa0tWoVbEP0wuiegS3xJWvst0gFu5pG23aSllCP0N15NOMViVXQR1hMT00PMvzsbZ3";

    loadStripe(publishableKey, {
      stripeAccount: getStripeAccount(businessId),
    }).then(response =>{
      key = response
      setStripePromise(key);
    })
  }, []);

  useEffect(() => {
    let clientSecretAux  = clientSecretRedux
    setClientSecret(clientSecretAux);
  }, []);

  const goBack = () => {
    dispatch(setStripeModalState(false))
    navigate('/your-order');
  }

  return (
    <>
    <div className="Back-Button-Container">
          <Button
            className="Button"
            onClick={goBack}
          >
            <FiChevronLeft size={22} color={GlobalColors.BLACK} style={{ marginRight: '10px' }} />
            <Image
              src={customImages.clocheBack}
              style={{
                width: '30%',
                height: '35%',
              }}
            />
          </Button>
        </div>
      <h3>Total</h3>
      <h2><PriceFormat price={isDone ? 0 : totalPayState} /></h2>
      {clientSecret && stripePromise && (
        <div className="Checkout-Form">
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm isDoneFunction={isDoneFunction}/>
          </Elements>
        </div>
      )}
    </>
  );
}

export default StripeModal;
