import React from 'react';
import { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useModal } from '../../../hooks/useModal';
import Popup from '../../../components/Modals/Popup';
import { deleteGlobalProductOption, getGlobalProductOptions } from '../../../axios/globalProductOption';
import { deleteLocalProductOption, getLocalProductOptions } from '../../../axios/localProductOption';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import './Product.css';
import { notify } from '../../../utils/notify';
import { Image } from 'react-bootstrap';
import OptionDetail from './Detail/OptionDetail'
import Pencil from '../../../assets/NavBar Icons/Pencil.svg'
import TrashCan from '../../../assets/NavBar Icons/TrashCan.svg'

const ProductOption = (props) => {
	const [productOptions, setProductOptions] = useState([]);
	const [isOpenModal, openModal, closeModal, data] = useModal();
	const [isLoading, setIsLoading] = useState(false);
	const { userType, businessId, locationId } = JSON.parse(localStorage.getItem('user'));
	const [currentOption, setCurrentOption] = useState({ option: [], action: '' });
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	useEffect(() => {
		_getProductOptions();
	}, []);

	useEffect(() => {
		props.popupOpen(isPopupOpen)
	}, [isPopupOpen])

	const _getProductOptions = async () => {
		setIsLoading(true)
		await Promise.all([
			getGlobalProductOptions({ businessId }),
			getLocalProductOptions({ locationId }),
		]).then(response => {
			const localOptions = response[1].map(item => ({
				...item,
				isGlobal: false
			}))
			const globalOptions = response[0].map(item => ({
				...item,
				isGlobal: true
			}))
				.filter(globalOption => localOptions.every(localOption => localOption.menuId !== globalOption.id))
			const optionsList = globalOptions.concat(localOptions)

			setProductOptions(optionsList);
			setIsLoading(false)
		}).catch(error => {
			notify('Unable to read data from database', 'error')
			console.log(error)
			setIsLoading(false)
		})
	};

	const handleEditOption = item => {
		setCurrentOption(item)
		setIsPopupOpen(true)
	}

	const deleteProductOption = async _data => {
		await (userType === 'admin' ? deleteGlobalProductOption : deleteLocalProductOption)(_data)
			.then(() => {
				closeModal()
				_getProductOptions()
				notify('Product option data deleted successfully', 'success')
			}).catch(error => {
				notify('Error deleting product option data', 'error')
				console.log(error)
			})
	};

	return (
		<>
			<div className='main'>
				<Popup
					isOpen={isOpenModal}
					title='Delete productOption'
					label='Are you sure do you want to delete'
					onCancel={closeModal}
					cancelLabel='Cancel'
					onAccept={() => deleteProductOption(data)}
					acceptLabel='Accept'
				/>
				<LoadingSpinner
					isLoading={isLoading}
					label='Loading product options...'
				/>
				{currentOption && isPopupOpen && <OptionDetail
					handleClosePopup={() => setIsPopupOpen(false)}
					productOption={currentOption.option}
					action={currentOption.action}
					userType={userType}
					isMenuCollapsed={props.isMenuCollapsed}
					response={_getProductOptions}
				/>}
				<div className="row-header">
					<div>
						<header>Options</header>
					</div>
					<div>
						<BsPlusCircleFill
							className='plus-icon color-cloche-red'
							onClick={() => handleEditOption({ option: { isAvailable: true, businessId, locationId }, action: 'create' })}
						/>

					</div>
				</div>
				<div className='header'>
					<div className='productOption-name'>NAME</div>
					<div className='productOption-type'>TYPE</div>
					{userType === 'manager' &&
						<div className='product-isGlobal'>LEVEL</div>
					}
					<div className='productOption-isAvailable'>STATUS</div>
					<div className='productOption-actions'>ACTIONS</div>
				</div>
				<div className='crud'>
					{productOptions.map(productOption => (
						<div
							className='crud-row'
							key={productOption.id}>
							<div className='productOption-name'>{productOption.name}</div>
							<div className='productOption-type'>{productOption.optionType}</div>
							{userType === 'manager' &&
								<div className='productOption-isGlobal'>{productOption.isGlobal ? 'Global' : 'Local'}</div>
							}
							<div className={`productOption-isAvailable ${!productOption.isAvailable && 'color-cloche-red'}`}>
								{productOption.isAvailable ? 'AVAILABLE' : 'NOT AVAILABLE'}
							</div>
							<div className='productOption-actions'>
								<Image
									className='action-icon'
									src={Pencil}
									onClick={() => handleEditOption({
										option: {
											...productOption,
											businessId: productOption.businessId ?? businessId,
											locationId: productOption.locationId ?? locationId,
										},
										action: 'edit'
									})}
								/>
								{(userType === 'admin' || !productOption.isGlobal) &&
									<Image
										className='action-icon color-cloche-red'
										src={TrashCan}
										onClick={() => openModal({ id: productOption.id, idSections: productOption.idSections })}
									/>
								}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default ProductOption;
