import React from 'react';
import { motion } from 'framer-motion';
import { FiChevronLeft } from 'react-icons/fi';
import OrderPaymentItem from '../OrderInfo/OrderPaymentItem';
import { useDispatch } from 'react-redux';
import {
  setOrderPaymentMethodState,
  setOrderCheckoutModalState,
  selectOrderPaymentMethodState,
} from '../../Redux/slices/ModalsSlices';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { GlobalColors } from '../../assets/GlobalColors';
import { AiOutlinePlus } from 'react-icons/ai';

const OrderPaymentMethod = (props) => {
  const dispatch = useDispatch();

  const orderPaymentState = useSelector(selectOrderPaymentMethodState);

  const divWidth = () => {
    return '100%'
  };

  return (
    <div 
      style={{
        backgroundColor: GlobalColors.GRAY_MODAL_BACKGROUND,
        display: orderPaymentState ? 'flex' : 'none',
        position: 'fixed',
        top: '0',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
      }}
    >
      <motion.div
        style={{
          position: 'fixed',
          bottom: '0',
          overflow: 'hidden',
          width: '100%',
          height: 'auto',
          backgroundColor: GlobalColors.WHITE,
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          display: orderPaymentState ? 'flex' : 'none',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          transition: 'all 5s ease-in-out',
          border: `1px solid ${GlobalColors.BORDER_BOTTOM_GRAY_2}`,
          padding: '15px',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
      >
        <div
          style={{
            width: divWidth(),
            height: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <Button
            onClick={() => {
              dispatch(setOrderPaymentMethodState(false));
              dispatch(setOrderCheckoutModalState(true));
            }}
            style={{
              border: 'none',
              backgroundColor: 'transparent',
            }}
          >
            <FiChevronLeft size={22} color={GlobalColors.BLACK} />
          </Button>
        </div>

        <div
          style={{
            width: divWidth(),
            height: '50px',
            textAlign: 'left',
          }}
        >
          <div
            style={{
              fontSize: '16px',
              color: GlobalColors.BLACK,
            }}
            className="text-title"
          >
            Select your payment method
          </div>
        </div>

        <div
          style={{
            width: divWidth(),
            height: 'auto',
            textAlign: 'left',
          }}
        >
          <OrderPaymentItem />
        </div>

        <div
          style={{
            width: divWidth(),
            height: '50px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '15px',
          }}
        >
          <AiOutlinePlus color={GlobalColors.CLOCHE_RED} />
          <div
            style={{
              fontSize: '14px',
              color: GlobalColors.BLACK,
            }}
            className="text-title"
          >
            {' '}
            Add credit/debit card
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default OrderPaymentMethod;
