import React from 'react';
import { useEffect, useState } from 'react';
import { addTable, updateTable } from '../../../../axios/table';
import { ToggleSwitch } from '../../../../components/General/ToggleSwitch';
import { saveAs } from 'file-saver';
import { Button } from 'react-bootstrap';
import { GlobalColors } from '../../../../assets/GlobalColors';
import LoadingSpinner from '../../../../components/Modals/LoadingSpinner';
import { notify } from '../../../../utils/notify';

const TableDetail = (props) => {
  const {
    handleClosePopup,
    table,
    action,
    isMenuCollapsed,
    response
  } = props

  const [newTable, setNewTable] = useState({
    tableId: '',
    name: '',
    isAvailable: true,
    locationId: '',
    businessId: '',
    needsAssistance: false,
    isToGo: false,
    QRurl: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const downloadImage = (imageUrl) => {
    saveAs(imageUrl, imageUrl);
  };

  const { locationId, businessId } = JSON.parse(localStorage.getItem('user'));

  let url = `https://${table.environment}.z13.web.core.windows.net/${businessId}/${locationId}/${table.id}`;
  const QRurl = `https://${table.environment}.blob.core.windows.net/${businessId}/${locationId}/${table.id}.png`;
  useEffect(() => {
    if (table.environment === 'prod') {
      url = `https://${table.environment}/${businessId}/${locationId}/${table.id}.png`;
    } else {
      url = `https://${table.environment}.z13.web.core.windows.net/${businessId}/${locationId}/${table.id}`;
    }
  }, [url, table, locationId]);

  useEffect(() => {
    setNewTable({ ...newTable, ...table });
  }, []);

  const handleTableData = ({ value, field }) => {
    setNewTable({
      ...newTable,
      [field]: value,
    });
  };

  const validateData = () => {
    let forTest = { ...newTable.name, ...newTable.needsAssistance };
    if (Object.values(forTest).some((x) => x === '')) {
      notify('Please fill all fields', 'warning')
      return;
    }
    return true;
  };

  const _updateTable = async (data) => {
    updateTable(data)
      .then(() => {
        response(data)
        handleClosePopup()
      })
      .catch((error) => console.log(error));
  };

  const saveTable = async () => {
    if (!(validateData())) {
      return;
    }

    setIsLoading(true)
    try {
      if (!table.id) {
        await addTable(newTable)
          .then(async (res) => {
            const tableId = res.id;
            handleTableData({ value: tableId, field: 'tableId' });

            const newQRurl = `https://${table.environment}.blob.core.windows.net/${businessId}/${locationId}/${tableId}.png`;
            await _updateTable({ ...newTable, tableId, newQRurl });
          })

      } else {
        const newQRurl = `https://${table.environment}.blob.core.windows.net/${businessId}/${locationId}/${table.id}.png`;
        await _updateTable({ ...newTable, tableId: table.id, newQRurl });
      }
      notify(`Table ${action === 'create' ? 'created' : 'edited'} successfully`, 'success')
      response()
      handleClosePopup()
      setIsLoading(false)
    } catch (error) {
      notify('Error saving data', 'error')
      console.log(error);
      setIsLoading(false)
    }
  };

  return (
    <>
      <LoadingSpinner
        isLoading={isLoading}
        label='Saving table...'
      />
      <div className="pop-up-modal">
        <div className={`pop-up-container ${isMenuCollapsed && 'collapsed'}`}>
          <div className="pop-up-header">{action === 'create' ? 'New Table' : 'Edit Table'}</div>
          <div className="pop-up-row-wrap">
            <div className="pop-up-col">
              <div className="pop-up-row">Name</div>
              <div className="pop-up-row">
                <input
                  className="input"
                  defaultValue={newTable.name}
                  onChange={(event) =>
                    handleTableData({ value: event.target.value, field: 'name' })
                  }
                />

              </div>
            </div>
          </div>
          {table.name &&
            <>
              <div className="pop-up-row-wrap">
                <div className="pop-up-col">
                  <div className="pop-up-row">QR url</div>
                  <div className="pop-up-row">
                    <a href={url}>{url}</a>
                  </div>
                </div>
              </div>
              <div className="pop-up-row-wrap justify-center">
                <div className="pop-up-col">
                  <div className="pop-up-row">QR</div>
                  <div className="pop-up-row">
                    <img src={QRurl} alt="QR-table" />
                  </div>
                  <div className="pop-up-row justify-center">
                    <Button
                      className="qr-download mt-2"
                      style={{ backgroundColor: GlobalColors.CLOCHE_RED }}
                      onClick={() => downloadImage(QRurl)}
                    >
                      Download QR
                    </Button>
                  </div>
                </div>
              </div>
            </>
          }

          <div className="pop-up-row-wrap">
            <div className="pop-up-col">
              <div className="pop-up-row">Status</div>
              <div className="pop-up-row">
                <ToggleSwitch
                  label="Status"
                  handleCheckBox={(value) => handleTableData({ value, field: 'isAvailable' })}
                  defaultChecked={table.isAvailable}
                />
              </div>
            </div>
            <div className="pop-up-col">
              <div className="pop-up-row">To Go</div>
              <div className="pop-up-row">
                <ToggleSwitch
                  label='To Go'
                  handleCheckBox={value => handleTableData({ value, field: 'isToGo' })}
                  defaultChecked={table.isToGo}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="pop-up-row-wrap justify-space-between">
            <div className="pop-up-col">
              <button
                className='btn-lg btn-cancel'
                onClick={handleClosePopup}>
                Cancel
              </button>
            </div>
            <div className="pop-up-col">
              <button
                className='btn-lg btn-confirm'
                onClick={saveTable}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableDetail;