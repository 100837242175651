import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { selectIsOrdersListAvailable } from '../../Redux/slices/ProductsSlices';

const RestrictYourOrder = () => {
  const caseToResctrict = useSelector(selectIsOrdersListAvailable);

  return caseToResctrict ? <Outlet /> : <Navigate to="/Home" />;
};

export default RestrictYourOrder;
