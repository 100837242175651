import React from 'react';
import { useEffect, useState } from 'react';
import { ToggleSwitch } from '../../../../components/General/ToggleSwitch';
import { getOptionTypes } from '../../../../axios/optionType';
import { getGlobalProductOptions } from '../../../../axios/globalProductOption';
import { getLocalProductOptions } from '../../../../axios/localProductOption';
import { addGlobalProductOptionList, updateGlobalProductOptionList } from '../../../../axios/globalProductOptionList';
import { addLocalProductOptionList, updateLocalProductOptionList } from '../../../../axios/localProductOptionList';
import LoadingSpinner from '../../../../components/Modals/LoadingSpinner';
import { notify } from '../../../../utils/notify';

const ProductOptionDetail = (props) => {
	const {
		handleClosePopup,
		productOptionList,
		action,
		userType,
		isMenuCollapsed,
		response
	} = props
	const { locationId, businessId } = productOptionList;
	const [newProductOptionList, setNewProductOptionList] = useState({
		name: '',
		isRequired: true,
		isMultipleChoice: true,
		isAvailable: true,
		productOptions: [],
		isGlobal: true,
	});
	const [optionTypeList, setOptionTypeList] = useState();

	const [productOptions, setProductOptions] = useState();
	const [productOptionsFiltered, setProductOptionsFiltered] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [loadingLabel, setLoadingLabel] = useState('Loading...');

	useEffect(() => {
		setNewProductOptionList({ ...newProductOptionList, ...productOptionList })
		getData()
	}, []);

	const getData = async () => {
		setIsLoading(true)
		await Promise.all([
			getOptionTypes({ businessId }),
			getGlobalProductOptions({ businessId }),
			getLocalProductOptions({ locationId }),
		]).then(data => {
			setOptionTypeList(data[0])
			const optionType = data[0][0].name
			const globalProductOptions = data[1].map(item => ({
				...item,
				isGlobal: true
			}))
			const localProductOptions = data[2].map(item => ({
				...item,
				isGlobal: false
			}))
			const concatenatedProductOptions = globalProductOptions.concat(localProductOptions)
			setProductOptions(concatenatedProductOptions)
			const productOptionFiltered = concatenatedProductOptions.filter(
				x => x.optionType === (productOptionList.optionType ?? optionType)
			)
			setProductOptionsFiltered(productOptionFiltered)
			setIsLoading(false)
		}).catch(error => {
			notify('Unable to read data from database', 'error')
			console.log(error)
			setIsLoading(false)
		})
	}

	const validateData = () => {
		if (Object.values(newProductOptionList).some(x => x === '') ||
			!newProductOptionList.productOptions.length) {
			notify('Please fill all fields', 'warning')
			return;
		}

		return true;
	};

	const handleProductOption = ({ event, isGlobal }) => {
		const value = event.target.value
		const isChecked = event.target.checked

		const productOptions = isChecked
			? [...newProductOptionList.productOptions, {
				optionId: value,
				isGlobal
			}]
			: newProductOptionList.productOptions.filter(
				produtOption => produtOption.optionId !== value
			)

		const _newProductOptionList = { ...newProductOptionList, productOptions }
		setNewProductOptionList(_newProductOptionList);
	};

	const handlePrices = ({ event, optionId, toFix = false }) => {
		const _newProductOptionList = { ...newProductOptionList }
		let value = parseFloat(event.target.value)
		value = value < 0 ? 0 : value
		value = toFix ? (value || 0).toFixed(2) : value

		const index = newProductOptionList.productOptions.findIndex(
			x => x.optionId === optionId
		)
		_newProductOptionList.productOptions[index].price = value

		setNewProductOptionList(_newProductOptionList);
	}

	const handleKeyPress = event => {
		const regex = /^[0-9\b.]+$/
		if (!regex.test(event.key)) {
			event.preventDefault()
		}
	}

	const handleSwitches = ({ event, field }) => {
		let _newProductOptionList = { ...newProductOptionList }
		_newProductOptionList[field] = event

		setNewProductOptionList(_newProductOptionList);
	};

	const handlerOptionType = ({ value }) => {
		const _productOptionsFiltered = productOptions.filter(x => x.optionType === value)
		setNewProductOptionList({ ...newProductOptionList, optionType: value })
		setProductOptionsFiltered(_productOptionsFiltered)
	};

	const saveProductOptionList = async () => {
		if (!validateData()) {
			return;
		}

		setLoadingLabel('Saving option list...')
		setIsLoading(true)
		try {
			const _productOptionList = newProductOptionList.isGlobal && userType === 'manager'
				? {
					...newProductOptionList,
					listId: newProductOptionList.id,
					locationId,
					isGlobal: false,
					businessId: null,
				}
				: newProductOptionList;

			if (userType === 'admin') {
				await (action === 'create' ? addGlobalProductOptionList : updateGlobalProductOptionList)(_productOptionList);
			} else {
				await (newProductOptionList.isGlobal ? addLocalProductOptionList : updateLocalProductOptionList)(_productOptionList);
			}
			notify(`Option list ${action === 'create' ? 'created' : 'edited'} successfully`, 'success')
			response()
			handleClosePopup()
			setIsLoading(false)
		} catch (error) {
			notify('Error saving data', 'error')
			console.log(error);
			setIsLoading(false)
		}
	};

	return (
		<>
			{newProductOptionList &&
				<>
					<LoadingSpinner
						isLoading={isLoading}
						label={loadingLabel}
					/>
					<div className="pop-up-modal">
					<div className={`pop-up-container ${isMenuCollapsed && 'collapsed'}`}>
							<div className="pop-up-header">{action === 'create' ? 'New Option List' : 'Edit Option List'}</div>
							<div className="pop-up-row-wrap">
								<div className="pop-up-col">
									<div className="pop-up-row">Name</div>
									<div className="pop-up-row">
										<input
											className='input'
											defaultValue={newProductOptionList.name}
											onChange={event => setNewProductOptionList({
												...newProductOptionList,
												name: event.target.value
											})}
										/>
									</div>
								</div>
						</div>
						<div className="pop-up-row-wrap">
								<div className="pop-up-col">
									<div className="pop-up-row">Required</div>
									<div className="pop-up-row">
										<ToggleSwitch
											label='Required'
											handleCheckBox={event => handleSwitches({ event, field: 'isRequired' })}
											defaultChecked={productOptionList.isRequired}
										/>
									</div>
								</div>
								<div className="pop-up-col">
									<div className="pop-up-row">Multiple Choice</div>
									<div className="pop-up-row">
										<ToggleSwitch
											label='Multiple Choice'
											handleCheckBox={event => handleSwitches({ event, field: 'isMultipleChoice' })}
											defaultChecked={productOptionList.isMultipleChoice}
										/>
									</div>
								</div>
								<div className="pop-up-col">
									<div className="pop-up-row">Available</div>
									<div className="pop-up-row">
										<ToggleSwitch
											label='Status'
											handleCheckBox={event => handleSwitches({ event, field: 'isAvailable' })}
											defaultChecked={productOptionList.isAvailable}
										/>
									</div>
								</div>
							</div>
							<hr />
							<div className='pop-up-row-wrap justify-center'>
								<div className="pop-up-col">
									Option Type
									<select
										className='input-select'
										onChange={event => handlerOptionType({ value: event.target.value })}>
										{optionTypeList?.map(type => (
											<option
												key={type.id}
												selected={type.name === newProductOptionList.optionType}
											>
												{type.name}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className='pop-up-row-wrap justify-center'>
								<div className="pop-up-col option-checkbox-div">
								Select Options
								{(!newProductOptionList.isRequired && !newProductOptionList.isMultipleChoice) &&
									<div>
										<input
											type='checkbox'
											className='m-2'
											value='None'
											defaultChecked={productOptionList.productOptions?.some(x => x.optionId === 'None')}
											onChange={event => handleProductOption({ event })}
										/>
										None
									</div>
								}
									{productOptionsFiltered?.map((option, key) => (
										<>
											<div key={key} className="row-options">
												<div>
													<input
														type='checkbox'
														className='m-2'
														value={option.id}
														defaultChecked={productOptionList.productOptions?.some(
															x => x.optionId === option.id
														)}
														name='productOptions'
														onChange={event => handleProductOption({
															event, isGlobal: option.isGlobal
														})}
													/>
													{option.name}
												</div>
												{newProductOptionList.productOptions?.some(x => x.optionId === option.id) &&
													<div>
														<div className='money-container'>
															<span className="dollar-prefix">$</span>
															<input
																className='input money'
																type='number'
																step='any'
																value={newProductOptionList.productOptions?.find(x => x.optionId === option.id).price ?? (0).toFixed(2)}
																onChange={event => handlePrices({
																	event, optionId: option.id
																})}
																onBlur={event => handlePrices({
																	event, optionId: option.id, toFix: true
																})}
																onKeyPress={handleKeyPress}
															/>
														</div>
													</div>
												}
											</div>
										</>
									))
									}
								</div>
							</div>
							<div className='pop-up-row-wrap'>
								<button
									className='btn-lg btn-cancel'
									onClick={handleClosePopup}>
									Cancel
								</button>
								<button
									className='btn-lg btn-confirm'
									onClick={saveProductOptionList}>
									Save
								</button>
							</div>
						</div>
					</div>

				</>
			}
		</>
	);
};

export default ProductOptionDetail;
