import { REACT_APP_API_URL } from '../utils/envConfig';
import axios from 'axios';



function postOrder(newOrder) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_URL}/addkitchenOrder`, JSON.stringify(newOrder))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function postOrderItem(newItem) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${REACT_APP_API_URL}/addkitchenOrderDetail`, JSON.stringify(newItem))
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function handleAddOrder(newOrder) {
  try {
    let orderData = {
      ...newOrder,
      createdBy: "admin",
    };
    const orderResponse = await postOrder(orderData);

    return orderResponse;
  } catch (error) {
    // Handle errors
    console.error("Error adding order:", error);
    throw new Error("Failed to add order");
  }
}

export async function getActiveOrders(data) {
  let orders = []
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${REACT_APP_API_URL}/listKitchenOrders`, JSON.stringify(data))
      .then(async (response) => {
        orders = response.data
        resolve(orders);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getHistoricOrders(data) {
  let orders = []
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${REACT_APP_API_URL}/listkitchenOrderHistory`, JSON.stringify(data))
      .then(async (response) => {
        orders = response.data
        resolve(orders);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function updateOrder(orderToUpdate) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${REACT_APP_API_URL}/updateKitchenOrder`, JSON.stringify(orderToUpdate))
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function updateOrderItemStatus(orderToUpdate) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${REACT_APP_API_URL}/updatekitchenOrderDetail`, JSON.stringify(orderToUpdate))
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getKitchenOrder(orderId) {
  return new Promise(async (resolve, reject) => {
    await axios
      .get(`${REACT_APP_API_URL}/getkitchenOrder`, { params: { id: orderId } })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function updateOrderDetail(kitchenOrderDetail) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${REACT_APP_API_URL}/updatekitchenOrderDetail`, JSON.stringify(kitchenOrderDetail))
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function createPaymentIntent(paymentData) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${REACT_APP_API_URL}/createPaymentIntent`, JSON.stringify(paymentData))
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getPercentageForTaxes(orderData) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${REACT_APP_API_URL}/getPercentageForTaxes`, JSON.stringify(orderData))
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function updatePaymentIntent(paymentData) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${REACT_APP_API_URL}/updatePaymentIntent`, JSON.stringify(paymentData))
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getLastOrderNumber(locationId) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${REACT_APP_API_URL}/getLastOrderNumber`, JSON.stringify(locationId))
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function updateUnsubscribeFlag(query) {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(`${REACT_APP_API_URL}/updateUnsubscribeFlag`, JSON.stringify(query))
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}