import React from 'react';

import useMediaQuery from '../../hooks/useMediaQuery';
import { GlobalColors } from '../../assets/GlobalColors';

const Title = ({ title }) => {
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');

  const titleSize = () => {
    return isMobileM
      ? '20px'
      : isMobileL
      ? '1rem'
      : '1.5rem';
  };

  return (
    <div
      style={{
        width: '100%',
        height: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '20px',
        marginBottom: '20px',
        color: GlobalColors.BLACK,
      }}
    >
      <div
        style={{
          fontSize: titleSize(),
        }}
        className="text-title"
      >
        {title}
      </div>
    </div>
  );
};

export default Title;
