import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { GlobalColors } from '../../../assets/GlobalColors';
import {
  selectQuantityOrdersItems,
  selectQuantityProductsInCloche,
  selectIsOrdersListAvailable,
} from '../../../Redux/slices/ProductsSlices';
import Menu from '../../../assets/NavBar Icons/Menu.svg';
import MenuSelected from '../../../assets/NavBar Icons/MenuSelected.svg';
import YourClocheSelected from '../../../assets/NavBar Icons/YourCloche.png';
import YourCloche from '../../../assets/NavBar Icons/YourCloche.png';
import OrdersSelected from '../../../assets/NavBar Icons/OrdersSelected.svg';
import Orders from '../../../assets/NavBar Icons/Orders.svg';
import './NavBar.css';


const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileS = useMediaQuery('(max-width: 320px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isIphoneXR = useMediaQuery('(max-width: 390px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptop = useMediaQuery('(max-width: 1024px)');
  const isLaptopL = useMediaQuery('(max-width: 1440px)');
  const quantityProductsInCloche = useSelector(selectQuantityProductsInCloche);
  const quantityOrders = useSelector(selectQuantityOrdersItems);
  const isOrdersListAvailable = useSelector(selectIsOrdersListAvailable);
  const [buttonState, setButtonState] = useState('0');

  const buttonStyle = {
    width: '100px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    backgroundColor: GlobalColors.WHITE,
    border: 'none',
  };

  const buttonFontStyle = {
    whiteSpace: 'nowrap',
    color: GlobalColors.BORDER_BOTTOM_GRAY,
    fontSize: isMobileS
      ? '10px'
      : isMobileM
      ? '12px'
      : '15px',
  };

  const handleClick = (value) => {
    setButtonState(value);
    switch (value) {
      case '0':
        navigate('/Home');
        break;
      case '1':
        navigate('/YourCloche');
        break;
      case '2':
        navigate('/your-order');
        break;
      default:
        navigate('/Home');
        break;
    }
  };

  const closeButtonPositionRight = () => {
    return isMobileS
      ? '42.5%'
      : isMobileM
      ? '43.5%'
      : isMobileL
      ? '44%'
      : isTablet
      ? '46.7%'
      : isLaptop
      ? '47.5%'
      : isLaptopL
      ? '48.2%'
      : '48.7%';
  };

  const closeButtonPositionTop = () => {
    return '14px';
  };

  React.useEffect(() => {
    if (location.pathname === '/Home') {
      setButtonState('0');
    } else if (location.pathname === '/YourCloche') {
      setButtonState('1');
    } else if (location.pathname === '/your-order') {
      setButtonState('2');
    }
  }, [location]);

  return (
    <div
      style={{
        width: '100%',
        height: '80px',
        backgroundColor: GlobalColors.WHITE,
        position: 'fixed',
        bottom: '0',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: `2px solid ${GlobalColors.CLOCHE_RED}`,
      }}
    >
      <div
        style={{
          width: isMobileM || isMobileL || isTablet
            ? '95%'
            : '50%',
          height: '103%',
          backgroundColor: GlobalColors.WHITE,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: isMobileM ? '4%' : isMobileL ? '8%' : '10%',
        }}
      >
        <div
          style={{
            width: '100px',
            height: '98%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '6px',
              backgroundColor: buttonState === '0' ? GlobalColors.CLOCHE_RED : 'white',
            }}
            className="button-case"
          />
          <button onClick={() => handleClick('0')} style={buttonStyle}>
            <Image
              src={buttonState === '0' ? MenuSelected : Menu}
              alt="Menu"
              style={{ width: '28px', height: '20px' }}
            />
            <div style={buttonFontStyle} className="text-normal">
              Menu
            </div>
          </button>
        </div>

        <div
          style={{
            width: '100px',
            height: '98%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              
              position: 'absolute',
              top: closeButtonPositionTop(),
              right: closeButtonPositionRight(),
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              border: 'none',
              display: quantityProductsInCloche === 0 ? 'none' : 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: '',
              color: GlobalColors.WHITE,
              backgroundColor: GlobalColors.YOUR_CLOCHE_NOTIFICATION,
            }}
            className="text-normal"
          >
            {quantityProductsInCloche}
          </div>

          <div
            style={{
              width: '100%',
              height: '6px',
              backgroundColor: buttonState === '1' ? GlobalColors.CLOCHE_RED : 'white',
            }}
            className="button-case"
          />

          <button
            onClick={(e) => {
              e.preventDefault();
              handleClick('1');
            }}
            style={buttonStyle}
          >
            <Image
              src={buttonState === '1' ? YourClocheSelected : YourCloche}
              alt="Your Cloche"
              style={{ width: '28px', height: '20px' }}
            />
            <div style={buttonFontStyle} className="text-normal">
              Your Cloche
            </div>
          </button>
        </div>

        <div
          style={{
            width: '100px',
            height: '98%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '14px',
              right: 
                isMobileS
                ? '9%'
                : isIphoneXR
                ? '12%'
                : isMobileM || isMobileL
                ? '14%'
                : isTablet
                ? '24.5%'
                : isLaptop
                ? '33.5%'
                : isLaptopL
                ? '36.5%'
                : '38.5%',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              border: 'none',
              display: quantityOrders === 0 ? 'none' : 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: '',
              color: GlobalColors.WHITE,
              backgroundColor: GlobalColors.YOUR_CLOCHE_NOTIFICATION,
            }}
            className="text-normal"
          >
            {quantityOrders}
          </div>

          <div
            style={{
              width: '100%',
              height: '6px',
              backgroundColor: buttonState === '2' ? GlobalColors.CLOCHE_RED : 'white',
            }}
            className="button-case"
          />

          <button
            onClick={(e) => {
              e.preventDefault();
              handleClick('2');
            }}
            style={buttonStyle}
            disabled={!isOrdersListAvailable}
          >
            <Image
              src={buttonState === '2' ? OrdersSelected : Orders}
              alt="Order"
              style={{ width: '28px', height: '20px' }}
            />
            <div style={buttonFontStyle} className="text-normal">
              Order
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
