import React from 'react';
import DataTable from './DataTable';
const OrderHistory = () => {

  return <div>
    <div>
      <div
        className="text-title"
        style={{
          width: '90%',
          height: '10%',
          fontSize: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginLeft: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          Order History
        </div>
      </div>
      <div>
        <DataTable/>
      </div>
    </div>
  </div>;
};

export default OrderHistory;
