import React from 'react';
import { Button } from 'react-bootstrap';
import { GlobalColors } from '../../../assets/GlobalColors'; 
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const CompressionButton = ({ buttonDisabled, buttonClick, isCompressed, marginRight }) => {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        buttonClick();
      }}
      style={{
        width: '32px',
        height: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: '100px',
        backgroundColor: GlobalColors.KITCHEN_CARDBG_COLOR,
        border: 'none',
        position: 'absolute',
        bottom: '10%',
        right: '0',
        marginRight: marginRight,
        padding: '0px',
      }}
      disabled={buttonDisabled ? buttonDisabled : false}
    >
    {
        isCompressed ? 
        <FiChevronRight
            style={{
                color: GlobalColors.CLOCHE_RED,
            }}
            size={20}
        />
        : 
        <FiChevronLeft
            style={{
            color: GlobalColors.CLOCHE_RED,
            }}
            size={20}
        />
    }
    </Button>
  );
};

export default CompressionButton;
