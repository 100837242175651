import {useEffect, useState} from 'react'
import {Form, Button} from 'react-bootstrap'
import { GlobalColors } from '../../assets/GlobalColors';
import PriceFormat from '../../components/General/PriceFormat';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectFoodSelected,
  setFoodSelected,
  addProduct,
} from '../../Redux/slices/ProductsSlices';
import './General.css'
const MenuCheckbox = ({quantity, menuItems, handleCloseAndNavigate, notes, setNotes}) => {
  const dispatch = useDispatch();
  const foodSelected = useSelector(selectFoodSelected);
  const [extraItems, setExtraItems] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [itemsMenu, setItemsMenu] = useState([]);
 
  useEffect(() => {
    setTotalPrice(foodSelected.totalPrice)
    let dataAux = {}
    for(let extra of menuItems){
      dataAux[extra.typeName] = []
    }
    setExtraItems(dataAux)
    const _menuItems = menuItems.map(item => {
      let menuItem = {...item}
      if (!item.isMultipleChoice && !item.isRequired) {
        menuItem.options = [{ name: 'None', price: 0 }, ...item.options ]
      }
      return menuItem
    })
    setItemsMenu([..._menuItems])
  }, []);

  useEffect(() => {
    calculateTotalPrice({...extraItems})
  }, [extraItems, foodSelected.quantity])


  const canRenderNum = (item) => {
    return item.price > 0
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault()
    handleAddItem()
  };

  const isRequired = (extra) => {
    let aux = extra.isRequired && !extraItems[extra.typeName].length
    return aux
  }

  const handleClick = (e, itemName, extra, price) => {
    const checked = e.target.checked
    const dataAux = { ...extraItems }
    const typeName = extra.typeName
    const newItemData = {
      name: itemName,
      price: price,
    }

    if (extra.isMultipleChoice) {
      if (checked) {
        dataAux[typeName].push(newItemData)
      } else {        
        dataAux[typeName] = dataAux[typeName].filter(item => item.name !== itemName)
      }
    } else {
      dataAux[typeName] = [newItemData]
    }

    setExtraItems(dataAux)
  };

  const calculateTotalPrice = dataAux => {
    const flatDataAux = Object.values(dataAux).flat()
      .map(item => ({ ...item, price: item.price ?? 0 }))    
    const totalPerItem = flatDataAux.reduce((price, total) => {
      return (price ? parseFloat(price) + parseFloat(total.price) : parseFloat(total.price))
    }, 0) + parseFloat(foodSelected.price)
    
    dispatch(setFoodSelected({ ...foodSelected, totalPrice: totalPerItem}))
    setTotalPrice(totalPerItem * quantity)
  }

  const handleAddItem = () => {
    dispatch(addProduct({ ...foodSelected, notes: notes, extraItems, totalPrice }));
    setNotes('')
    handleCloseAndNavigate();
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
      {itemsMenu?.length > 0 &&  itemsMenu.map((extra, index) => {
        return(
          <div 
          key={`name-${index}`}
          >
            <Form.Group key={`fg-${index}`}>
              <div className='Title-Container'>
                <div>{extra.typeName}</div>
                {extra.isRequired && <div className='Required-Text'>Required </div>}
                {extra.isMultipleChoice && <div className='MultipleChoice-Text'>Choose up to {extra.options.length} </div>}
                </div>
              <div
                key={`container-${index}`}
                style={{
                  border: '1px solid',
                  borderColor: GlobalColors.BORDER_BOTTOM_GRAY_2,
                  borderRadius: '15px', 
                  padding: '5px'
                }}
              >
                {extra.options?.map((item, subindex) => (
                <div key={`inline-${index}-${subindex}`}  
                    className={subindex > 0 ? "After-First-Child" : "First-Child"}
                >
                    <div 
                      key={`price-${index}-${subindex}`} 
                      style={{
                        justifySelf: 'flex-end'
                      }}>
                        <div className='Name-Text'>{item.name}</div>
                        <div className='Price-Container'> {item && canRenderNum(item) && <PriceFormat price={item.price} />}  </div>
                    </div>

                    <Form.Check
                        style={{borderRadius: extra.isRequired ? '50%' : '0.25em'}}
                        className={`Checkbox-Container ${extra.isMultipleChoice ? 'Multiple-Checkbox' : 'Unique-Checkbox'}` }
                        required={isRequired(extra)}
                        name={extra.typeName}
                        type={extra.isMultipleChoice ? 'checkbox' : 'radio'}
                        id={`check-${index}-${subindex}-${extra.id}`}
                        defaultChecked={extraItems[extra.typeName].includes(item.name)}
                        onChange={(e) => {
                          handleClick(e, item.name, extra, item.price)
                        }}
                      />
                  </div>
                  ))}
                </div>
          </Form.Group>
        </div>
      )})}
        <Button
          className="Add-Button"
          style={{
            backgroundColor: GlobalColors.CLOCHE_RED,
            color: GlobalColors.WHITE,
          }} 
          type="submit">
              Add {foodSelected.quantity} to cloche - <PriceFormat price={totalPrice} />
        </Button>
      </Form>
    </div>
  )
};

export default MenuCheckbox;