import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getStations } from '../../axios/station';
import { GlobalColors } from '../../assets/GlobalColors';
import DoneWhite from '../../assets/KitchenItems/DoneWhite.svg';
import CancelWhite from '../../assets/KitchenItems/CancelWhite.svg';
import TinyDone from '../../assets/KitchenItems/TinyDone.svg';
import TinyGreenDone from '../../assets/KitchenItems/TinyGreenDone.svg';
import TinyCancel from '../../assets/KitchenItems/TinyCancel.svg';
import { setItemStatus } from '../../Redux/slices/KitchenSlices';
import ConfirmationModal from '../Modals/ConfirmationModal';

const OrderItem = ({ item, isFoodTruck }) => {

  const { locationId } = JSON.parse(localStorage.getItem('user'));

  const dispatch = useDispatch();

  const [itemSelected, setItemSelected] = useState(false);
  const [canShowButtons, setCanShowButtons] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [hasService, setHasService] = useState(false);

  const { stationType } = JSON.parse(localStorage.getItem('station')) ?? { stationType: undefined };
  const boardLogic = {
    kitchen: "kitchen",
    service: "service",
    bar: "bar",
  }

  useEffect(() => {
    getStations({ locationId: locationId }).then((res) => setHasService(res.some(station => station.type === boardLogic.service)));
  }, [])

  const handleSelectItem = (itemKind) => {
    if (stationType === boardLogic.service && itemKind !== "DRINKS") {
      return
    }
    setCanShowButtons(true)
    if (item.status === 'In preparation') {
      setItemSelected(!itemSelected);
    }
  };
  const handleDoneItem = () => {
    dispatch(setItemStatus({ id: item.id, status: 'Done', hasService: hasService, isFoodTruck: isFoodTruck }));
    setItemSelected(false);
    setCanShowButtons(false)
  };

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleCancelItem = (clickedYes) => {
    if (clickedYes) {
      dispatch(setItemStatus({ id: item.id, status: 'Cancelled', hasService: hasService, isFoodTruck: isFoodTruck }));
    }
    setItemSelected(false);
    setCanShowButtons(false)
  };

  const styles = {
    containerMod: {
      width: 'auto',
      height: '50px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '15px',
      borderBottom: '1px solid' + GlobalColors.BORDER_BOTTOM_GRAY_2,
      marginLeft: '-135px',
      transition: 'margin-left 0.4s',
    },
    container: {
      width: '90%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '15px',
      marginLeft: '0px',
      transition: 'margin-left 0.4s',
      paddingBottom: '5px',
      paddingTop: '5px'
    },
    quantityChip: {
      width: '25px',
      height: '25px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      fontSize: '14px',
      color: GlobalColors.BLACK,
      backgroundColor: 'rgb(40,40,40, 0.15)',
    },
    itemName: {
      width: '160px',
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      fontSize: '14px',
      lineHeight: '22px',
      color: GlobalColors.BLACK,
      textAlign: 'left',
      whiteSpace: 'normal',
    },
    status: {
      width: '25px',
      height: '25px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      fontSize: '14px',
      color: GlobalColors.BLACK,
      backgroundColor: 'transparent',
    },
    buttons: {
      width: '120px',
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },

  };

  return (
    <>
      <div style={itemSelected === false ? styles.container : styles.containerMod}>

        <ConfirmationModal
          style={styles.modalConfirmation}
          isOpen={isOpen}
          handleCancelItem={handleCancelItem}
          setIsOpen={(_isOpen) => setIsOpen(_isOpen)}
        />

        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <div className="text-title" style={styles.quantityChip}>
            {item.quantity}
          </div>

          <div className="text-normal" style={styles.itemName}>
            {item.name}
          </div>
          <div className="text-normal" style={styles.status}>
            {item.status === 'In preparation' ? (
              <div className="text-normal" onClick={(e) => {
                e.stopPropagation();
                handleDoneItem();
              }} style={styles.status}>
                <Image src={TinyDone} />
              </div>
            ) : (
              item.status === 'Done' ? (
                <Image onClick={(e) => {
                  e.stopPropagation();
                  handleDoneItem();
                }} src={TinyGreenDone} />
              ) : (
                <Image src={TinyCancel} />
              )
            )}
          </div>
        </div>

        {canShowButtons &&
          <div className="text-normal" style={styles.buttons}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDoneItem();
              }}
              style={{
                width: isFoodTruck ? '100%' : '50%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'none',
                backgroundColor: GlobalColors.KITCHEN_DONE_ITEM,
              }}
            >
              <Image
                src={DoneWhite}
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            </button>
            {!isFoodTruck &&
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpen();
                }}
                style={{
                  width: '50%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 'none',
                  backgroundColor: GlobalColors.KITCHEN_NOT_DONE_ITEM,
                }}
              >
                <Image
                  src={CancelWhite}
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              </button>
            }
          </div>
        }
      </div>
    </>
  );
};

export default OrderItem;
