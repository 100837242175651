import React from 'react';
import { useEffect, useState } from 'react'
import { addRole, updateRole } from '../../../../axios/role';
import { getStations } from '../../../../axios/station';
import { getLocations } from '../../../../axios/location';
import { defaultPermissions } from '../../../../utils/defaultPermissions';
import LoadingSpinner from '../../../../components/Modals/LoadingSpinner';
import { notify } from '../../../../utils/notify';

const RoleDetail = (props) => {
    const {
        handleClosePopup,
        role,
        action,
        userType,
        isMenuCollapsed,
        response
    } = props
    const [newRole, setNewRole] = useState({
        name: '',
        description: '',
        userType,
        permissions: {},
        station: []
    })
    const { locationId, businessId } = JSON.parse(localStorage.getItem('user'));
    const [userTypeList, setUserTypeList] = useState([])
    const [stationList, setStationList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingLabel, setLoadingLabel] = useState('Loading...');

    const userTypes = {
        superAdmin: [
            { name: 'Super Admin', value: 'superAdmin' },
            { name: 'Admin', value: 'admin' }
        ],
        admin: [
            { name: 'Admin', value: 'admin' },
            { name: 'Manager', value: 'manager' }
        ],
        manager: [
            { name: 'Manager', value: 'manager' },
            { name: 'Operation', value: 'operation' }
        ]
    }

    useEffect(() => {
        setNewRole({ ...newRole, ...role })
        setUserTypeList(userTypes[userType])
        getData()
    }, [])

    useEffect(() => {
        if (userType === 'admin' && newRole.userType === 'manager') {
            setNewRole({
                ...newRole,
                locationId: locationList[0]?.id ?? ''
            })
        }
    }, [newRole.userType])

    const getData = async () => {
        setIsLoading(true);

        const promises = [getLocations({ businessId })];

        if (locationId) promises.push(getStations({ locationId }));

        await Promise.all(promises)
            .then(response => {
                setLocationList(response[0]);
                if (locationId) {
                    setStationList(response[1]);
                }
                setIsLoading(false);
            })
            .catch(error => {
                notify('Unable to read data from database', 'error');
                console.log(error);
                setIsLoading(false);
            });
    };

    const handleRoleData = ({ event, field }) => {
        const value = event.target.value
        let _newRole = { ...newRole }
        if (field === 'station') {
            if (event.target.checked) {
                _newRole.station.push(value)
            } else {
                _newRole.station = newRole.station.filter(station => station !== value);
            }
        } else if (userType === 'admin' && value === 'manager') {
            _newRole.userType = value
            _newRole.locationId = locationList[0].id
        } else if (field === 'locationId') {
            const currentLocation = locationList.find(x => x.name === value)
            _newRole.locationId = currentLocation.id;
        } else {
            _newRole[field] = value
        }
        setNewRole({ ..._newRole })
    }

    const validateData = () => {
        if (Object.values(newRole).some(x => x === '')) {
            notify('Please fill all fields', 'warning')
            return;
        }
        return true
    };

    const saveRole = async () => {
        if (!validateData()) {
            return;
        }
        
        setLoadingLabel('Saving role...')
        setIsLoading(true)
        const permissions = defaultPermissions[newRole.userType];

        const _newRole = {
            ...newRole,
            permissions,
        };

        if (!_newRole.station.length) delete _newRole.station
        try {
            await (action === 'create' ? addRole : updateRole)(_newRole)
            notify(`Role ${action === 'create' ? 'created' : 'edited'} successfully`, 'success')
            response()
            handleClosePopup()
            setIsLoading(false)
        } catch (error) {
            notify('Error saving data', 'error')
            console.log(error);
            setIsLoading(false)
        }
    }

    return (
        <>
            <LoadingSpinner
                isLoading={isLoading}
                label={loadingLabel}
            />
            <div className="pop-up-modal">
                <div className={`pop-up-container ${isMenuCollapsed && 'collapsed'}`}>
                    <div className="pop-up-header">{action === 'create' ? 'New Role' : 'Edit Role'}</div>
                    <div className="pop-up-row-wrap">
                        <div className="pop-up-col">
                            <div className="pop-up-row">Name</div>
                            <input
                                className='input'
                                defaultValue={newRole.name}
                                onChange={event => handleRoleData({ event, field: 'name' })}
                            />
                        </div>
                        <div className="pop-up-col">
                            <div className="pop-up-row">Description</div>
                            <div className="pop-up-row">
                                <input
                                    className='input-lg'
                                    defaultValue={newRole.description}
                                    onChange={event => handleRoleData({ event, field: 'description' })}
                                />
                            </div>
                        </div>

                        <div className="pop-up-col" style={{ width: '300px' }}>
                            <div className="radio-row">User Type</div>
                            <div className="radio-row">
                                {userTypeList?.map(_userType => (
                                    <div className='radio-div' key={_userType.id}>
                                        <div >
                                            <input
                                                type='radio'
                                                className='radio-input'
                                                defaultValue={_userType.value}
                                                checked={newRole.userType === _userType.value}
                                                name='userType'
                                                onChange={event => handleRoleData({ event, field: 'userType' })}
                                            />
                                        </div>
                                        <div>
                                            {_userType.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {userType === 'admin' && newRole.userType === 'manager' && (
                            <div className="pop-up-col">
                                <div className="radio-row">Location</div>
                                <div className="radio-row">
                                    <select
                                        className='input-select'
                                        onChange={event => handleRoleData({ event, field: 'locationId' })}>
                                        {locationList.map(location => (
                                            <option
                                                key={location.id}
                                                selected={location.id === role.locationId}>
                                                {location.name}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                            </div>
                        )}

                        {newRole.userType === 'operation' && (
                            <div className='pop-up-col'>
                                <div className="radio-row">Stations</div>
                                <div className='checkbox-div'>
                                    {stationList.map(station => (
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='checkbox-input'
                                                value={station.id}
                                                defaultChecked={newRole.station?.includes(station.id)}
                                                name='station'
                                                onChange={event =>
                                                    handleRoleData({
                                                        event,
                                                        field: 'station',
                                                    })
                                                }
                                            />
                                            {station.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <br />
                    <div className="pop-up-row-wrap justify-space-between">
                        <div className="pop-up-col">
                            <button
                                className='btn-lg btn-cancel'
                                onClick={handleClosePopup}>
                                Cancel
                            </button>
                        </div>
                        <div className="pop-up-col">
                            <button
                                className='btn-lg btn-confirm'
                                onClick={saveRole}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoleDetail;
