import React from 'react';
import { useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { addUser, updateUser } from '../../../../axios/user';
import { getRoles } from '../../../../axios/role';
import { getLocations } from '../../../../axios/location';
import { ToggleSwitch } from '../../../../components/General/ToggleSwitch';
import LoadingSpinner from '../../../../components/Modals/LoadingSpinner';
import { notify } from '../../../../utils/notify';
import { encryptPassword, decryptPassword } from '../../../../utils/encryptPassword';

const UserDetail = (props) => {
	const {
		handleClosePopup,
		user,
		action,
		userType: currentUserType,
		isMenuCollapsed,
		response
	} = props
	const [showPassword, setShowPassword] = useState([false, false])
	const [newPassword, setNewPassword] = useState('')
	const [oldPassword, setOldPassword] = useState('')
	const [newUser, setNewUser] = useState({
		username: '',
		name: '',
		roleId: '',
		status: true,
		password: '',
		userType: ''
	});

	const [rolesList, setRolesList] = useState([]);
	const [locationList, setLocationList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingLabel, setLoadingLabel] = useState('Loading...');

	useEffect(() => {
		getData()
	}, []);

	const handleClose = () => {
		handleClosePopup();
	}

	const getData = async () => {
		setIsLoading(true)

		await Promise.all([
			getRoles({ userType: currentUserType, locationId: user.locationId, businessId: user.businessId }),
			getLocations({ businessId: user.businessId })
		]).then(response => {
			const _rolesList = response[0]
			let _newUser = { ...newUser, ...user, };
			if (action === 'edit') _newUser.password = decryptPassword(user.password)

			const currentRole = _rolesList.find(x => x.id === newUser.roleId) || _rolesList[0];
			_newUser.roleId = currentRole.id
			_newUser.userType = currentRole.userType

			setRolesList(_rolesList);
			setLocationList(response[1]);

			setNewUser({ ..._newUser });
			setIsLoading(false)
		}).catch(error => {
			notify('Unable to read data from database', 'error')
			console.log(error)
			setIsLoading(false)
		})
	};

	const handleUserData = async ({ value, field }) => {
		let _newUser = { ...newUser };
		if (field === 'locationId') {
			const currentLocation = locationList.find(x => x.name === value);
			_newUser.locationId = currentLocation.id;
		} else if (field === 'roleId') {
			const currentRole = rolesList.find(x => x.name === value);
			_newUser.roleId = currentRole.id;
			_newUser.userType = currentRole.userType
		} else {
			_newUser[field] = value;
		}
		setNewUser({ ..._newUser });
	};

	const validateData = () => {
		if (Object.values(newUser).some(x => x === '')) {
			notify('Please fill all fields', 'warning')
			return
		}
		if (!newPassword && !oldPassword) return true
		if (oldPassword !== newPassword) {
			notify('Password are not equal', 'warning')
			return false
		}

		return true
	}

	const confirmPassword = async event => {
		setNewPassword(event.target.value)
		handleUserData({ value: oldPassword, field: 'password' })
	}

	const saveUser = async () => {
		if (!(validateData())) {
			return
		}
		setLoadingLabel('Saving user...')
		setIsLoading(true)
		try {
			const passwordEncrypted = encryptPassword(newUser.password)
			const _newUser = { ...newUser, password: passwordEncrypted }
			await (action === 'create' ? addUser : updateUser)(_newUser)
			notify(`User ${action === 'create' ? 'created' : 'edited'} successfully`, 'success')
			response()
			handleClosePopup()
			setIsLoading(false)
		} catch (error) {
			notify(error.response.data ?? 'Error saving users data', 'error')
			console.log(error);
			setIsLoading(false)
		}
	}


	return (
		<>
			{newUser &&
				<>
					<LoadingSpinner
						isLoading={isLoading}
						label={loadingLabel}
					/>
					<div className="pop-up-modal">
					<div className={`pop-up-container ${isMenuCollapsed && 'collapsed'}`}>
							<div className="pop-up-header">{action === 'create' ? 'New User' : 'Edit User'}</div>
							<div className="pop-up-row-wrap">
								<div className="pop-up-col">
									<div className="pop-up-row">User name</div>
									<div className="pop-up-row">
										<input
											className="input"
											defaultValue={newUser.username}
											onChange={event =>
												handleUserData({ value: event.target.value, field: 'username' })
											}
										/>
									</div>
								</div>
								<div className="pop-up-col">
									<div className="pop-up-row">Full Name</div>
									<div className="pop-up-row">
										<input
											className="input"
											defaultValue={newUser.name}
											onChange={event => handleUserData({ value: event.target.value, field: 'name' })}
										/>
									</div>
								</div>
								<div className="pop-up-col">
									<div className="pop-up-col">
										<div className="pop-up-row">Password</div>
										<div className='password-div'>
											<input
												type={showPassword[0] ? 'text' : 'password'}
												className="input"
											defaultValue={newUser.password}
												onChange={event => setOldPassword(event.target.value)}
											/>
											{showPassword[0] ?
												<FaEye
													className='eye-icon'
													onClick={() => setShowPassword([!showPassword[0], showPassword[1]])}
												/> :
												<FaEyeSlash
													className='eye-icon'
													onClick={() => setShowPassword([!showPassword[0], showPassword[1]])}
												/>
											}
										</div>
									</div>
								</div>
								{oldPassword && (
									<div className="pop-up-col">
										<div className="pop-up-row">Confirm password</div>
										<div className='password-div'>
											<input
												type={showPassword[1] ? 'text' : 'password'}
												className="input"
												onBlur={event => confirmPassword(event)}
											/>
											{showPassword[1] ?
												<FaEye
													className='eye-icon'
													onClick={() => setShowPassword([showPassword[0], !showPassword[1]])}
												/> :
												<FaEyeSlash
													className='eye-icon'
													onClick={() => setShowPassword([showPassword[0], !showPassword[1]])}
												/>
											}
										</div>
									</div>
								)}
								<div className="pop-up-col">
									<div className="pop-up-row">Role</div>
									<div className="pop-up-row">
										<select
											className='input-select'
											onChange={event => handleUserData({ value: event.target.value, field: 'roleId' })}
										>
											{rolesList.map(role => (
												<option
													key={role.id}
													selected={role.id === user.roleId}>
													{role.name}
												</option>
											))}
										</select>
									</div>
								</div>
								{currentUserType === 'admin' && (newUser.userType === 'manager' || newUser.userType === 'operation') && (
									<div className="pop-up-col">
										<div className="pop-up-row">Location</div>
										<select
											className='input-select'
											onChange={event => handleUserData({ value: event.target.value, field: 'locationId' })}
										>
											{locationList.map(location => (
												<option
													key={location.id}
													selected={location.id === user.locationId}>
													{location.name}
												</option>
											))}
										</select>
									</div>
								)}
								<div className="pop-up-col">
									Status
									<ToggleSwitch
										label='Status'
										handleCheckBox={e => handleUserData({ value: e, field: 'status' })}
										defaultChecked={user.status}
									/>
								</div>
							</div>
							<br />
							<div className="pop-up-row-wrap justify-space-between">
								<div className="pop-up-col">
									<button
										className='btn-lg btn-cancel '
										onClick={handleClose}>
										Cancel
									</button>
								</div>
								<div className="pop-up-col">
									<button
										className="btn-lg btn-confirm"
										onClick={saveUser}>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
				</>
			}
		</>
	);
};

export default UserDetail;
