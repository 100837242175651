import React from 'react';
import { useEffect, useState } from 'react';
import { ToggleSwitch } from '../../../../components/General/ToggleSwitch';
import { GiChiliPepper, GiPeanut, GiFruitBowl } from 'react-icons/gi';
import { MdOutlineRecommend } from 'react-icons/md';
import { HiOutlineBell } from 'react-icons/hi';
import { BiDrink } from 'react-icons/bi';
import { TbPlant2Off, TbCandyOff } from 'react-icons/tb';
import DragAndDrop from '../../../../components/General/DragAndDrop';
import { BsPlusCircleFill, BsDashCircleFill } from 'react-icons/bs';
import { addGlobalProduct, updateGlobalProduct, uploadProductImage } from '../../../../axios/globalProduct';
import { addLocalProduct, updateLocalProduct } from '../../../../axios/localProduct';
import { getGlobalProductOptionLists } from '../../../../axios/globalProductOptionList';
import { getLocalProductOptionLists } from '../../../../axios/localProductOptionList';
import LoadingSpinner from '../../../../components/Modals/LoadingSpinner';
import { REACT_APP_API_URL } from '../../../../utils/envConfig';
import { notify } from '../../../../utils/notify';

const ProductDetail = (props) => {
	const {
		handleClosePopup,
		product, action,
		userType,
		isMenuCollapsed,
		response
	} = props
	const { locationId, businessId } = product;
	const [newProduct, setNewProduct] = useState({
		name: '',
		description: '',
		type: 'plates',
		price: 0,
		isAvailable: true,
		optionLists: [{ optionListId: '' }],
		isGlobal: true,
		isHidden: false,
		tags: []
	});
	const [productImage, setProductImage] = useState([]);
	const [optionLists, setOptionLists] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingLabel, setLoadingLabel] = useState('Loading...');

	const producType = [
		{ name: 'Plates' },
		{ name: 'Drinks' }
	]

	const tags = [
		{ name: 'Has Alcohol', value: 'hasAlcohol', icon: <BiDrink /> },
		{ name: 'Has Peanut', value: 'hasPeanut', icon: <GiPeanut /> },
		{ name: 'Is Gulten Free', value: 'isGultenFree', icon: < TbPlant2Off /> },
		{ name: 'Is Spicy', value: 'isSpicy', icon: <GiChiliPepper /> },
		{ name: 'Is Vegan', value: 'isVegan', icon: <GiFruitBowl /> },
		{ name: 'Is Sugar Free', value: 'isSugarFree', icon: <TbCandyOff /> },
		{ name: 'Is New', value: 'isNew', icon: <HiOutlineBell /> },
		{ name: 'Recommended', value: 'recommended', icon: <MdOutlineRecommend /> },
	]


	useEffect(() => {
		setNewProduct({ ...newProduct, ...product });
		getOptionList()
		getProductImage()
	}, []);

	const handleClose = () => {
		handleClosePopup();
	}

	const getProductImage = async () => {
		const lastIndex = REACT_APP_API_URL.lastIndexOf('cloche-');
		const env = REACT_APP_API_URL.substring(lastIndex).replace('-', 'web').replace('testing', 'testin')
		const imageUrl = `https://${env}.blob.core.windows.net/${businessId}/${product.isGlobal ? '' : locationId + '/'}products/${product.id}`;

		const imageExtensions = ['.png'];
		for (const extension of imageExtensions) {
			const imageWithExtension = `${imageUrl}${extension}`;
			if (await imageExists(imageWithExtension)) {
				setProductImage([imageWithExtension, false])
				break;
			}
		}
	}

	const imageExists = async url => {
		try {
			const res = await fetch(url, { method: 'HEAD' });
			return res.status === 200 ? true : false
		} catch (error) {
			return false;
		}
	}
	const getOptionList = async () => {
		setIsLoading(true)
		await Promise.all([
			getGlobalProductOptionLists({ businessId }),
			getLocalProductOptionLists({ locationId }),
		]).then(productOption => {
			const globalOptionLists = productOption[0].map(item => ({
				...item,
				isGlobal: true
			}))
			const localOptionLists = productOption[1].map(item => ({
				...item,
				isGlobal: false
			}))
			const concatenatedOptionLists = globalOptionLists.concat(localOptionLists)
			setOptionLists([{ optionListId: '', name: 'None' }, ...concatenatedOptionLists])
			setIsLoading(false)
		}).catch(error => {
			notify('Unable to read data from database', 'error')
			console.log(error)
			setIsLoading(false)
		})
	}

	const validateData = () => {
		if (Object.values(newProduct).some(x => x === '')) {
			notify('Please fill all fields', 'warning')
			return;
		}

		return true;
	};

	const handleProduct = ({ event, field }) => {
		let value = event.target?.value || event
		let _newProduct = { ...newProduct }

		if (field === 'price') {
			value = parseFloat(value)
			_newProduct.price = value < 0 ? 0 : value
		} else if (field === 'tags') {
			const isChecked = event.target.checked

			isChecked
				? _newProduct.tags.push(value)
				: newProduct.tags.filter(product => product.tags !== value)

		} else if (field === 'type') {
			_newProduct[field] = value.toLowerCase()
		} else {
			_newProduct[field] = value
		}

		setNewProduct(_newProduct);
	};

	const handleKeyPress = event => {
		const regex = /^[0-9\b.]+$/
		if (!regex.test(event.key)) {
			event.preventDefault()
		}
	}

	const handleOptionLists = (event, key) => {
		const value = event.target.value
		const newOptionLists = [...newProduct.optionLists]
		const currentOption = optionLists.find(x => x.id === value)

		if (currentOption) {
			newOptionLists[key] = {
				...newOptionLists[key],
				optionListId: currentOption.id,
				isGlobal: currentOption.isGlobal
			}
		} else {
			delete newOptionLists[key].optionListId
			delete newOptionLists[key].isGlobal
		}

		setNewProduct({ ...newProduct, optionLists: newOptionLists });
	}

	const modifySections = ({ action, position }) => {
		const optionLists = [...newProduct.optionLists]

		action === 'add'
			? optionLists.splice(position + 1, 0, { optionListId: '' })
			: optionLists.splice(position, 1);

		setNewProduct({ ...newProduct, optionLists })
	};

	const saveProduct = async () => {
		if (!validateData()) {
			return;
		}

		setLoadingLabel('Saving product...')
		setIsLoading(true)
		try {
			const _product = newProduct.isGlobal && userType === 'manager'
				? {
					...newProduct,
					productId: newProduct.id,
					locationId,
					isGlobal: false,
				}
				: newProduct
			if (_product.optionLists) {
				_product.optionLists = newProduct.optionLists.filter(x => x.optionListId !== '' && optionLists.find(ol => ol.id === x.optionListId))
				if (!_product.optionLists.length) delete _product.optionLists
			}
			if (userType === 'admin') {
				const productSaved = await (action === 'create' ? addGlobalProduct : updateGlobalProduct)(_product);
				if (productImage[1]) await uploadProductImage({ businessId, productImage: productImage[0], id: productSaved.id })
			} else {
				const productSaved = await (newProduct.isGlobal ? addLocalProduct : updateLocalProduct)(_product);
				if (productImage[1]) await uploadProductImage({ businessId, locationId, productImage: productImage[0], id: productSaved.id })
			}
			response(_product)
			notify(`Product ${action === 'create' ? 'created' : 'edited'} successfully`, 'success')
			handleClosePopup()
			setIsLoading(false)
		} catch (error) {
			notify('Error saving data', 'error')
			console.log(error);
			setIsLoading(false)
		}
	};

	return (
		<>
			<LoadingSpinner
				isLoading={isLoading}
				label={loadingLabel}
			/>
			<div className='pop-up-modal'>
				<div className={`pop-up-container ${isMenuCollapsed && 'collapsed'}`}>
					<div className='pop-up-header'>{action === 'create' ? 'New Product' : 'Edit Product'}</div>
					<div className='pop-up-row-wrap'>
						<div className='pop-up-col'>
							<div className='pop-up-row'>Name</div>
							<div className='pop-up-row'>
								<input
									className='input-md'
									defaultValue={newProduct.name}
									onChange={event => handleProduct({ event, field: 'name' })}
								/>
							</div>
						</div>
						<div className='pop-up-col'>
							<div className='pop-up-row'>Price</div>
							<div className='pop-up-row'>
								<span className="dollar-prefix">$</span>
								<input
									className='input money'
									type='number'
									step='any'
									value={newProduct.price}
									onChange={event => handleProduct({ event, field: 'price' })}
									onBlur={() => setNewProduct({
										...newProduct,
										price: parseFloat(newProduct.price || 0).toFixed(2)
									})}
									onKeyPress={handleKeyPress}
								/>
							</div>
						</div>
						<div className='pop-up-col'>
							<div className='pop-up-row'>Type</div>
							<div className='pop-up-row'>
								<select
									className='input-sm'
									onChange={event => handleProduct({ event, field: 'type' })}>
									{producType.map(type => (
										<option
											selected={type.name.toUpperCase() === newProduct.type.toUpperCase()}>
											{type.name.charAt(0).toUpperCase() + type.name?.slice(1)}
										</option>
									))}
								</select>
							</div>
						</div>
						<div style={{ display: 'flex' }}>
							<div className='pop-up-col'>
								<div className='pop-up-row'>Available</div>
								<div className='pop-up-row'>
									<ToggleSwitch
										label=' '
										handleCheckBox={event => handleProduct({ event, field: 'isAvailable' })}
										defaultChecked={product.isAvailable}
									/>
								</div>
							</div>
							<div className='pop-up-col'>
								<div className='pop-up-row'>Hidden</div>
								<div className='pop-up-row'>
									<ToggleSwitch
										label=' '
										handleCheckBox={event => handleProduct({ event, field: 'isHidden' })}
										defaultChecked={product.isHidden}
									/>
								</div>
							</div>
						</div>
						<div className='pop-up-col'>
							<div className='pop-up-row'>Description</div>
							<div className='pop-up-row'>
								<textarea
									className='input-lg'
									style={{ height: '95px' }}
									defaultValue={newProduct.description}
									onChange={event => handleProduct({ event, field: 'description' })}
								/>
							</div>
						</div>
						<div className='pop-up-row justify-center'>
							<div className='pop-up-col'>
								<div className='pop-up-row'>Image</div>
								<div className='pop-up-row'>
									<DragAndDrop
										width='620px'
										height='104px'
										handlerSetter={setProductImage}
										image={productImage[0] || ''}
										isProduct={true}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='pop-up-row justify-center'>
						<div className='pop-up-col'>
							Option Lists
							{newProduct.optionLists?.map((productOptionList, key) => (
								<>
									<div
										className='option-list'
										key={productOptionList.optionListId}
									>
										<div>
											<select
												className='input-select'
												onChange={event => handleOptionLists(event, key)}
											>
												{optionLists?.map(optionList => (
													<option
														key={optionList.id}
														value={optionList.id}
														selected={optionList.id === productOptionList.optionListId}
													>
														{optionList.name}
													</option>
												))}
											</select>
										</div>
										<div className='menu-col'>
											<BsPlusCircleFill
												className='option-list-icon color-cloche-red'
												onClick={() => modifySections({ action: 'add', position: key })}
											/>
										</div>
										<div className='menu-col'>
											<BsDashCircleFill
												className='option-list-icon color-cloche-red'
												hidden={newProduct.optionLists.length <= 1}
												onClick={() => modifySections({ action: 'delete', position: key })}
											/>
										</div>
									</div>
								</>
							))}
						</div>
					</div>
					<div style={{ 'margin': '10px' }}>
						Product Tags
					</div>
					<div className='product-tags-row'>
						{tags.map(tag => (
							<div className='col-tags'>
								<input
									type='checkbox'
									className='checkbox-input'
									value={tag.value}
									defaultChecked={product.tags?.includes(tag.value)}
									name='productType'
									onChange={event => handleProduct({ event, field: 'tags' })}
								/>
								<div className='tag-icon'>{tag.icon}</div>
								<div>{tag.name}</div>
							</div>
						))}
					</div>
					<br />
					<div className="pop-up-row-wrap justify-space-between">
						<div className='pop-up-col'>
							<button
								className='btn-lg btn-cancel'
								onClick={handleClose}>
								Cancel
							</button>
						</div>
						<div className='pop-up-col'>
							<button
								className='btn-lg btn-confirm'
								onClick={saveProduct}>
								Save
							</button>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductDetail;
