import { createSlice } from '@reduxjs/toolkit';
import { askForAssistance } from '../../axios/table';

const initialState = {
  WelcomeModalState: true,
  OrderOverviewModalState: false,
  HelpModalState: false,
  OrderCheckoutModalState: false,
  OrderPaymentMethodState: false,
  AddTipModalState: false,
  OrderConfirmationModalState: false,
  ConfirmationModalProps : {},
  StripeModalState: false,
  ThankYouModalState: false,
};

export const ModalsSlices = createSlice({
  name: 'Modals',
  initialState,
  reducers: {
    setOrderOverviewModalState: (state, action) => {
      state.OrderOverviewModalState = action.payload;
    },
    setHelpModalState: (state, action) => {
      state.HelpModalState = action.payload;
    },
    setOrderCheckoutModalState: (state, action) => {
      state.OrderCheckoutModalState = action.payload;
    },
    setOrderPaymentMethodState: (state, action) => {
      state.OrderPaymentMethodState = action.payload;
    },
    setAddTipModalState: (state, action) => {
      state.AddTipModalState = action.payload;
    },
    setOrderConfirmationModalState: (state, action) => {
      state.OrderConfirmationModalState = action.payload;
    },
    setConfirmationModalProps:(state, action) => {
      state.ConfirmationModalProps = action.payload
    },
    setStripeModalState: (state, action) => {
      state.StripeModalState = action.payload;
    },
    setWelcomeModalState: (state, action) => {
      state.WelcomeModalState = action.payload;
    },
    setThankYouModalState: (state, action) => {
      state.ThankYouModalState = action.payload;
    },
    resetModal: (state) => {
      state = initialState;
    },
    resetCache: () => {

    },
    getAssistance: (state, action) => {
      const { tableId } = action.payload
      const tableQuery = {
        id: tableId,
        toggleValue: true
      }
      askForAssistance(tableQuery)
    },
  },
});

export const {
  setOrderOverviewModalState,
  setHelpModalState,
  setOrderCheckoutModalState,
  setOrderPaymentMethodState,
  setAddTipModalState,
  setOrderConfirmationModalState,
  setConfirmationModalProps,
  setStripeModalState,
  setWelcomeModalState,
  setThankYouModalState,
  resetModal,
  resetCache,
  getAssistance,
} = ModalsSlices.actions;


export const selectWelcomeModalState = (state) => state.Modals.WelcomeModalState;
export const selectOrderOverviewModalState = (state) => state.Modals.OrderOverviewModalState;
export const selectHelpModalState = (state) => state.Modals.HelpModalState;
export const selectOrderCheckoutModalState = (state) => state.Modals.OrderCheckoutModalState;
export const selectOrderPaymentMethodState = (state) => state.Modals.OrderPaymentMethodState;
export const selectAddTipModalState = (state) => state.Modals.AddTipModalState;
export const selectOrderConfirmationModalState = (state) => state.Modals.OrderConfirmationModalState;
export const selectConfirmationModalProps = (state) => state.Modals.ConfirmationModalProps;
export const selectStripeModalState = (state) => state.Modals.StripeModalState;
export const selectThankYouModalState = (state) => state.Modals.ThankYouModalState;

export default ModalsSlices.reducer;
