import React from 'react';
import { MdOutlineContactSupport } from 'react-icons/md'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '../../hooks/useMediaQuery';
import Title from '../../components/General/Title';
import { GlobalColors } from '../../assets/GlobalColors';
import { Button, Image, Spinner } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import { createPaymentIntent, getKitchenOrder, getPercentageForTaxes } from '../../axios/order';
import { selectOrderCheckoutModalState, selectStripeModalState, setStripeModalState, selectAddTipModalState, setHelpModalState } from '../../Redux/slices/ModalsSlices';
import {
  selectOrdersList,
  selectRestaurantName,
  selectOrdersListTotal,
  selectOrdersListSubTotal,
  selectOrdersListSubTotalWithTaxes,
  selectOrdersListSubTotalWithFee,
  selectOrdersListTip,
  selectTotal,
  setClientSecret,
  setPaymentIntentId,
  setOrderStatus,
  setOrderDetailStatus,
  selectIsFoodTruck,
  resetProductsInClocheOrder,
  selectTipSelection,
  setOrdersListTip,
  selectTAXES,
  selectLocationId,
  setTAXES,
  selectBusinessId,
  selectFee,
} from '../../Redux/slices/ProductsSlices';
import './OrderInfo.css';
import PriceFormat from '../../components/General/PriceFormat';
import BottomBotton from '../../components/General/BottomBotton';
import check from '../../assets/images/YourCloche/check.svg';
import CheckoutInfo from '../../components/OrderInfo/CheckoutInfo';
import customImages from '../../utils/customImages'



const YourOrder = () => {
  const MINUTE_MS = 10 * 1000;
  const isFoodTruck = useSelector(selectIsFoodTruck)
  const taxesPercentage = useSelector(selectTAXES);
  const locationId = useSelector(selectLocationId);
  const dispatch = useDispatch();
  const isMobileS = useMediaQuery('(max-width: 320px)');
  const isMobileM = useMediaQuery('(max-width: 375px)');
  const isMobileL = useMediaQuery('(max-width: 425px)');
  const isTablet = useMediaQuery('(max-width: 768px)');
  const isLaptop = useMediaQuery('(max-width: 1024px)');

  const orders = useSelector(selectOrdersList);
  const fee = useSelector(selectFee);
  const restaurantName = useSelector(selectRestaurantName);
  const businessId = useSelector(selectBusinessId);
  const ordersTotal = useSelector(selectOrdersListTotal);
  const orderCheckoutModalState = useSelector(selectOrderCheckoutModalState);
  const stripeModalState = useSelector(selectStripeModalState);
  const navigate = useNavigate();
  const ordersSubTotal = useSelector(selectOrdersListSubTotal);
  const ordersSubTotalWithTaxes = useSelector(selectOrdersListSubTotalWithTaxes);
  const ordersSubTotalWithFee = useSelector(selectOrdersListSubTotalWithFee);
  const ordersTip = useSelector(selectOrdersListTip);
  const addTipModalState = useSelector(selectAddTipModalState);
  const tipSelected = useSelector(selectTipSelection);
  let total = useSelector(selectTotal);
  let subTotal = useSelector(selectOrdersListSubTotal);
  const [subTotalState, setSubTotalState] = useState(0);
  const [subTotalWithTaxes, setSubTotalWithTaxes] = useState(0);
  const [subTotalWithFee, setSubTotalWithFee] = useState(0);
  const [pickupName, setPickupName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [canSubmit, setCanSubmit] = useState(false)
  const [areFoodTruckOrdersPaid, setAreFoodTruckOrdersPaid] = useState(false)
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [buttonContent, setButtonContent] = useState(
    <div
      style={{
        color: 'white',
        fontSize: '16px',
      }}
    >
      {isFoodTruck ? "Pay and Submit Order" : "Checkout"}
    </div>
  );

  const optionCatalog = {
    option1: 0,
    option2: 5,
    option3: 8,
    option4: 10,
    option5: 15,
    option6: 20
  }
  const [resetButton, setResetButton] = useState(false);
  const [percentTipAux, setPercentTipAux] = useState(optionCatalog[tipSelected.selectedOption]);
  const [percentTip, setPercentTip] = useState("Server Tip (0%)")
  const [orderTipState, setOrderTipState] = useState(0 / 100 * subTotal);
  const [isInitial, setIsInitial] = useState(true);
  const clearCacheData = () => {
    dispatch(resetProductsInClocheOrder());
  };
  useEffect(() => {
    dispatch(setOrdersListTip(0.0 * subTotal))
  }, [subTotal])

  useEffect(() => {
    if (total === 0) {
      dispatch(setOrdersListTip(0))
      setPercentTip("Server Tip (0%)")
    }
  }, [total, subTotal])

  const getPercentage = async () => {

    try {
      const data = {
        locationId: locationId,
        total: subTotalState

      }
      const res = await getPercentageForTaxes(data);
      const temp = res.percentage ? parseFloat(res.percentage) : 0;
      dispatch(setTAXES(temp))
    } catch (error) {
      console.log(error)
    }
  }

  function getOrderIds(orders) {
    const filteredOrders = isFoodTruck
      ? orders.filter(order => order.status === "Waiting for payment")
      : orders;

    return filteredOrders.map(order => order.id);
  }


  const handleCreateCheckoutSession = async () => {
    if (isInitial) total += 0.0 * subTotal;
    if (orderTipState && !isInitial) total += orderTipState;

    let sessionData = {
      total: (total * 100).toFixed(0),
      fee: (subTotalWithFee * 100).toFixed(0),
      orderIds: getOrderIds(orders),
      businessName: restaurantName,
      name: pickupName,
      taxAmount: subTotalWithTaxes,
      tipAmount: orderTipState || 0,
      businessId: businessId,
      locationId: locationId,
      taxesPercentage: taxesPercentage,
      tipPercentage: percentTipAux,
      serviceChargePercentage: fee,
      serviceChargeAmount: ordersSubTotalWithFee,
      subTotalAmount: subTotalState,
      totalAmount: total,
      phoneNumber: phone,
      clientEmail: email,
    };

    try {
      const session = await createPaymentIntent(sessionData);
      dispatch(setPaymentIntentId(session.id))
      window.location.href = session.url;
    } catch (error) {
      console.error('Error redirecting to checkout:', error);
    }
  };



  const handleAskAssistance = () => {
    dispatch(setHelpModalState(true));
  };

  const isNameValid = (name) => {
    if (!isFoodTruck) {
      return true
    } else {
      return name.trim() !== '';
    }
  };


  const handleButtonContent = () => {
    if (!validateEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    if (!validatePhone(phone)) {
      alert("Please enter a 10 digit phone number.");
      return;
    }

    if (canSubmit) {
      handleCreateCheckoutSession();
      setButtonContent(
        <Spinner size="sm" animation="border" style={{ width: '1rem', height: '1rem', color: 'white' }} />
      );
      setOrderTipState(0);
    }
  };

  const validateEmail = (email) => {
    if (!email) return true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    if (!phone) return true;
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };


  useEffect(() => {
    setOrderTipState(parseInt(ordersTip / subTotal * 100 + 0.49))
    setPercentTip("Server Tip (" + (isNaN(orderTipState) ? 0 : orderTipState).toString() + "%)")
  }, [])

  const checkForUpdates = () => {

    orders.forEach(async order => {

      if (order.status === "In preparation" || order.status === 'Waiting for payment') {
        let orderAux = await getKitchenOrder(order.id)
        for (let orderDetailAux of orderAux.items) {
          if (orderDetailAux.status === 'Cancelled') {
            let newOrderDetailData = {
              id: orderDetailAux.idProduct,
              orderId: orderDetailAux.idKitchenOrder,
              status: orderDetailAux.status,
              price: orderDetailAux.price
            }
            dispatch(setOrderDetailStatus(newOrderDetailData))
          }
        }
        if (orderAux.status !== order.status) {
          let newStatusData = {
            id: order.id,
            status: orderAux.status
          }
          dispatch(setOrderStatus(newStatusData))
        }
      }
    });
  };


  useEffect(() => {
    if (orderCheckoutModalState === false) {
      setButtonContent(
        <div
          style={{
            color: 'white',
            fontSize: '16px',
          }}
        >
          {isFoodTruck ? "Pay and Submit Order" : "Checkout"}
        </div>
      );
    }
  }, [orderCheckoutModalState, ordersTotal]);

  useEffect(() => {
    if (stripeModalState) {
      navigate("/Checkout")
    }
  }, [stripeModalState])

  useEffect(() => {
    if (addTipModalState) {
      setIsInitial(false)

      setPercentTipAux(parseInt(ordersTip / subTotal * 100 + 0.49))
      setOrderTipState(parseInt(ordersTip / subTotal * 100 + 0.49))
      if (total === 0) setOrderTipState(0)
      setPercentTip("Server Tip (" + (isNaN(percentTipAux) ? 0 : percentTipAux).toString() + "%)")
    }
  }, [addTipModalState, orderTipState, percentTipAux, percentTip])


  useEffect(() => {
    setResetButton(isFoodTruck && (orders.every(order => order.status === "Waiting for payment")))
    setCanSubmit(isFoodTruck || (orders.every(order => (order.status === 'Delivered' || order.status === 'Cancelled'))));
    setAreFoodTruckOrdersPaid(isFoodTruck && orders.every(order => (order.status === 'In preparation')));
    checkForUpdates();
    setButtonDisabled(areFoodTruckOrdersPaid || ordersSubTotal === 0 || !canSubmit || (isFoodTruck && pickupName.trim() === ''))
    setSubTotalState(ordersSubTotal)
    setSubTotalWithFee(ordersSubTotalWithFee)
    setSubTotalWithTaxes(ordersSubTotalWithTaxes)
    setOrderTipState(ordersTip)
    getPercentage();
  }, [checkForUpdates, orders, pickupName])

  useEffect(() => {
    const interval = setInterval(() => {
      setCanSubmit(isFoodTruck || (orders.every(order => (order.status === 'Delivered' || order.status === 'Cancelled'))));
      setAreFoodTruckOrdersPaid(isFoodTruck && orders.every(order => (order.status === 'In preparation')));
      checkForUpdates();
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, [MINUTE_MS, checkForUpdates, orders]);

  const getBackgroundColor = (status) => {
    switch (status) {
      case 'Waiting for payment':
        return GlobalColors.ORDER_STATUS_INPREPARATION_BG
      case 'In preparation':
        return GlobalColors.ORDER_STATUS_INPREPARATION_BG
      case 'Delivered':
        return GlobalColors.ORDER_STATUS_DELIVERED_BG
      case 'Cooked':
        return GlobalColors.ORDER_STATUS_DELIVERED_BG
      case 'Cancelled':
        return GlobalColors.ORDER_STATUS_CANCELLED_BG
      default:
        return GlobalColors.WHITE;
    }
  }

  const getColor = (status) => {
    switch (status) {
      case 'Waiting for payment':
        return GlobalColors.ORDER_STATUS_INPREPARATION_COLOR
      case 'In preparation':
        return GlobalColors.ORDER_STATUS_INPREPARATION_COLOR
      case 'Delivered':
        return GlobalColors.ORDER_STATUS_DELIVERED_COLOR
      case 'Cooked':
        return GlobalColors.ORDER_STATUS_DELIVERED_COLOR
      case 'Cancelled':
        return GlobalColors.test
      default:
        return GlobalColors.BLACK;
    }
  }

  return (

    <div
      style={{
        height: '100%',
        width: isMobileS || isMobileM || isMobileL || isTablet
          ? '100%'
          : isLaptop
            ? '70%'
            : '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '200px',
      }}
    >
      <Title title="Your Order" />

      <div className="your-order-header">
        <div className="your-order-header-info">
          <div className="text-title">{restaurantName === 'wolff' ? 'Dough Boys' : restaurantName}</div>
        </div>

        <div className="your-order-header-info">
          <div
            className="text-normal"
            style={{
              color: GlobalColors.BLACK,
              fontSize: '14px',
            }}
          >
            {orders.length} {orders.length > 1 ? 'orders' : 'order'}
          </div>
        </div>
      </div>
      {orders && orders.map((order, idx) => (
        <div key={idx} className={'order-container'}
          style={{
            marginBottom: '20px',
          }}
        >
          <div
            style={{
              width: '100%',
              height: '30px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <div className={'order-item'}>Order {order.orderNumber}</div>

            <div
              className={'status-string'}
              style={{
                backgroundColor: getBackgroundColor(order.status),
                color: getColor(order.status)
              }}
            >
              {isFoodTruck && order.status === 'Delivered' ? 'Ready' : order.status}
            </div>
          </div>

          <div className="text-title">
            {order.items?.length} {order.items?.length > 1 ? 'items' : 'item'}
          </div>

          <div className="items-container">
            {order.items?.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="item-container"
                  style={{
                    borderBottom: '1px solid' + GlobalColors.BORDER_BOTTOM_GRAY_2,
                  }}
                >
                  <div className="item-chip">
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '8px',
                        padding: '4px 8px 4px 8px',
                        gap: '10px',
                        fontSize: '14px',
                        color: GlobalColors.BLACK,
                        backgroundColor: GlobalColors.GRAY_CHECKOUT_BACKGROUND,
                      }}
                      className="text-title"
                    >
                      {item.quantity}
                    </div>
                  </div>

                  <div className="item-info">
                    <div
                      className="text-title"
                      style={{
                        width: '100%',
                        height: '40%',
                        textAlign: 'left',
                        color: GlobalColors.BLACK,
                        fontSize: '14px',
                      }}
                    >
                      <div className={item.status === 'Cancelled' ? 'cancelled-text-name' : 'text-normal'}>
                        {item.name}
                      </div>

                      <div className='cancelled-text'> {item.status === 'Cancelled' && "Cancelled"} </div>
                      <div style={{
                        width: '100%',
                        height: 'auto',
                        textAlign: 'left',
                        color: GlobalColors.GRAY,
                        fontSize: '10px',

                      }}>
                        {
                          item?.extraItems && Object.keys(item?.extraItems).map((type, typeIndex) =>
                            <ul key={typeIndex} className=" py-0 my-0" style={{
                              listStyleType: 'none',
                              paddingLeft: '0px'
                            }}>
                              {item?.extraItems[type].map((item, itemIndex) => {
                                return (
                                  <li className="my-0 py-0" key={itemIndex}>
                                    {item.name} &nbsp;
                                    <div
                                      style={{
                                        display: 'inline',
                                      }}>
                                      {item.price > 0 && <PriceFormat price={item.price} />}
                                    </div>
                                  </li>
                                )
                              })
                              }
                            </ul>
                          )
                        }
                      </div>
                      <div
                        className="text-normal"
                        style={{
                          width: '100%',
                          height: '50%',
                          textAlign: 'left',
                          color: GlobalColors.GRAY_1,
                          fontSize: '14px',
                          marginBottom: '14px',
                        }}
                      >
                        <PriceFormat price={item.status === 'Cancelled' ? 0 : item.totalPrice} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}

      <div
        style={{
          width: '90%',
          height: 'auto',
          marginTop: '25px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='Cloche-Experience-Container'
          style={{
            backgroundColor: GlobalColors.BORDER_BOTTOM_GRAY,
          }}
        >
          <img alt="cloche" src={customImages.cloche} style={{ height: '16%', width: '16%' }} />

          <div
            style={{
              paddingLeft: '10px',
              color: GlobalColors.WHITE,
              fontSize: '14px',
              fontWeight: 550,
              textAlign: 'left',
              lineHeight: '18px'
            }}
          >
            The Cloche experience allows you to save on tips
          </div>
        </div>
        <CheckoutInfo kind="Subtotal" price={subTotalState} />
        <CheckoutInfo kind={`Tax (${taxesPercentage}%)`} price={subTotalWithTaxes} />
        <CheckoutInfo kind={`Service Charge (${fee}%)`} price={subTotalWithFee} showButton={true} />
        <CheckoutInfo showButton={subTotalState > 0} kind={percentTip} price={orderTipState} tip={true} isPaid={!areFoodTruckOrdersPaid} />
        <div
          style={{
            width: '100%',
            height: 'auto',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '10px',
            marginBottom: '10px',
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
          }}
        >
          <div
            className="text-title"
            style={{
              color: GlobalColors.BLACK,
              fontSize: '14px',
            }}
          >
            Total
          </div>

          <div
            className="text-title"
            style={{
              color: GlobalColors.BLACK,
              fontSize: '14px',
            }}
          >
            <PriceFormat price={isInitial ? (total + 0.0 * subTotal) : orderTipState ? orderTipState + total : total} />
          </div>
        </div>
        {!canSubmit && !isFoodTruck && <div className='text-normal' style={{ fontWeight: 700 }}>Almost there! You can continue to the checkout page once all of your orders are fullfilled.</div>}
        {isFoodTruck && !areFoodTruckOrdersPaid && <div className='text-normal' style={{ fontWeight: 700 }}>Please proceed with Checkout. The order will be prepared once the payment is confirmed</div>}
        <div>{areFoodTruckOrdersPaid}</div>
        {resetButton && <div
          onClick={(e) => {
            e.preventDefault();
            clearCacheData();
          }}
          style={{
            color: GlobalColors.CLOCHE_RED_SECONDARY,
          }}>reset</div>}
        {isFoodTruck && subTotalState > 0 && (
          <>
            <div className="parent-input-container">
              <div className="row">
                <div className="label label-required">Enter name for pickup* (This field is required)</div>
              </div>
              <div className="input-container input-container-required" style={{ border: `1px solid '#E5E5E5'` }}>
                <input
                  className="input-field"
                  placeholder='Your name'
                  name="myInput"
                  value={pickupName}
                  onChange={(e) => setPickupName(e.target.value)}
                />
              </div>
            </div>

            <div className="parent-input-container">
              <div className="row">
                <div className="label label-optional">Enter email for notification (Optional)</div>
              </div>
              <div className="input-container input-container-optional" style={{ border: `1px solid '#E5E5E5'` }}>
                <input
                  className="input-field"
                  placeholder='Your email'
                  name="emailInput"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="parent-input-container">
              <div className="row">
                <div className="label label-optional">Enter phone for notification (Optional)</div>
              </div>
              <div className="input-container input-container-optional" style={{ border: `1px solid  '#E5E5E5'` }}>
                <input
                  className="input-field"
                  placeholder='Your phone'
                  name="phoneInput"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
          </>
        )}
      </div>

      {!isFoodTruck &&
        <Button
          onClick={handleAskAssistance}
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            position: 'fixed',
            bottom: '165px',
            right: '10px',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'none',
            backgroundColor: GlobalColors.CLOCHE_RED,
            transition: 'all 0.5s ease'
          }}
        >
          <MdOutlineContactSupport
            style={{
              width: '25px',
              height: '25px',
            }}
          />
        </Button>
      }

      <BottomBotton buttonClick={handleButtonContent} buttonWidth="95%" buttonDisabled={isButtonDisabled}>
        {buttonContent}
      </BottomBotton>
    </div>
  );
};

export default YourOrder;
