import React from 'react';
import { useEffect, useState } from 'react';
import { BsPlusCircleFill } from 'react-icons/bs';
import { useModal } from '../../../hooks/useModal';
import Popup from '../../../components/Modals/Popup';
import { getTables } from '../../../axios/table';
import { deleteTable } from '../../../axios/table';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import './Tables.css'
import { notify } from '../../../utils/notify';
import { Image } from 'react-bootstrap';
import TablesDetail from './TableDetail/TableDetail'
import Pencil from '../../../assets/NavBar Icons/Pencil.svg'
import TrashCan from '../../../assets/NavBar Icons/TrashCan.svg'

const Tables = (props) => {
	const [tableList, setTableList] = useState([])
	const [isOpenModal, openModal, closeModal, data] = useModal()
	const [isLoading, setIsLoading] = useState(false);
	const [currentItem, setCurrentItem] = useState({ table: [], action: '' });
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const {locationId, businessId} = JSON.parse(localStorage.getItem('user'));

	useEffect(() =>  {
		_getTables();
	}, [])
	
	useEffect(() => {
		props.popupOpen(isPopupOpen)
	}, [isPopupOpen])

	const _getTables = async () => {
		setIsLoading(true)
		await getTables({ locationId, businessId }).then(response => {
			setIsLoading(false)
			setTableList(response);
		}).catch(error => {
			notify('Unable to read data from database', 'error')
			console.log(error)
			setIsLoading(false)
		})
	};

	const handleEditTable = item => {
		setCurrentItem(item)
		setIsPopupOpen(true)
	}

	const _deleteTable = async _data => {
		await deleteTable(_data).then(() => {
			closeModal()
			_getTables();
			notify('Tables data deleted successfully', 'success')
		}).catch(error => {
			notify('Error deleting tables data', 'error')
			console.log(error)
		})
	}

	return (
		<>
			<div className='main'>
				<Popup
					isOpen={isOpenModal}
					title='Delete Table'
					label='Are you sure do you want to delete'
					onCancel={closeModal}
					cancelLabel='Cancel'
					onAccept={() => _deleteTable(data)}
					acceptLabel='Accept'
				/>
				<LoadingSpinner
					isLoading={isLoading}
					label='Loading tables...'
				/>
				{currentItem && isPopupOpen && <TablesDetail
					handleClosePopup={() => setIsPopupOpen(false)}
					table={currentItem.table}
					action={currentItem.action}
					isMenuCollapsed={props.isMenuCollapsed}
					response={_getTables}
				/>}
				<div className="row-header">
					<div>
						<header>Tables</header>
					</div>
					<div>
						<BsPlusCircleFill
							className='plus-icon color-cloche-red'
							onClick={() => handleEditTable({ table: { isAvailable: true, businessId, locationId }, action: 'create' })}
						/>
					</div>
				</div>
				<div className='header'>
					<div className='table-name'>NAME</div>
					<div className='table-status'>STATUS</div>
					<div className='table-actions'>ACTIONS</div>
				</div>
				<div className='crud'>
					{tableList.map(table => (
						<div
							className='crud-row' key={table.id}>
							<div className='table-name'>{table.name}</div>
							<div className={`table-status ${!table.isAvailable && 'color-cloche-red'}`}>{table.isAvailable ? 'ACTIVE' : 'INACTIVE'}</div>
							<div className='table-actions'>
								<Image
									className='action-icon'
									src={Pencil}
									onClick={() => handleEditTable({ table, action: 'edit' })}
								/>
								<Image
									className='action-icon color-cloche-red'
									src={TrashCan}
									onClick={() => openModal({ id: table.id })}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Tables;
