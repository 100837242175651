import React, { useEffect } from 'react';
import './ClocheWelcome.css';
import Bell from '../../../assets/General/RedBellLogo.svg';
import Cloche from '../../../assets/General/RedClocheLogo.svg';
import Cover from '../../../assets/QuickStart/cover.svg';
import Foot from '../../../assets/QuickStart/footPrint.svg';
import Building from '../../../assets/QuickStart/building.svg';
import Qr from '../../../assets/QuickStart/qrCode.svg';
import Next from '../../../assets/QuickStart/next.svg';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';

function ClocheWelcome() {
  const navigate = useNavigate();
	const { referralCode } = useParams();

  useEffect(() => {
    if (referralCode) {
      localStorage.setItem('referralCode', referralCode);
    }
  }, [referralCode]);

  return (
    <div className="welcome-container">
      <div className="cover-photo">
        <div className='welcome-icons-container'>
          <Image className='bell-icon' src={Bell} />
          <Image className='cloche-icon' src={Cloche} />
        </div>
        <div className="text-container">
          <div className="welcome-title">Welcome to Cloche!</div>
          <div className="subtitle">A simpler way to manage your business, at no cost!</div>
        </div>
      </div>
      <div style={{ marginTop: '3%', marginBottom: '4%' }} className="divider"></div>
      <div className="outer-container">
        <div className="inner-text">
          <span>don’t know how to start? </span>
          <span className="break-on-small bold">we got you!</span>
        </div>
      </div>
      <div className="steps-container">
        <div className="icon-text-wrapper">
          <div className="icon-container">
            <Image style={{ width: '100%' }} src={Foot} />
          </div>
          <div>
            <span className="regular-text">Enter your business data for your </span>
            <span className="bold-text">Cloche account</span>
            <span className="regular-text"> to take orders.</span>
          </div>
        </div>
        <div className="subtext-wrapper">
          <div className="subtext">Just fill in the form, in 3 simple steps, and that’s all!</div>
        </div>
        <div style={{ marginTop: '2%', marginBottom: '2%' }} className="divider"></div>
      </div>
      <div className="steps-container">
        <div className="icon-text-wrapper">
          <div className="icon-container">
            <Image style={{ width: '100%' }} src={Building} />
          </div>
          <div>
            <span className="regular-text">Enter your business and bank data to create your linked </span>
            <span className="bold-text">Stripe account.</span>
          </div>
        </div>
        <div className="subtext-wrapper">
          <div className="subtext">We know it’s sensitive info, we’ll take good care of it!</div>
        </div>
        <div style={{ marginTop: '2%', marginBottom: '2%' }} className="divider"></div>
      </div>
      <div className="steps-container">
        <div className="icon-text-wrapper">
          <div className="icon-container">
            <Image style={{ width: '100%' }} src={Qr} />
          </div>
          <div>
            <span className="regular-text">Login to the portal, </span>
            <span className="bold-text">print your QR codes</span>
            <span className="regular-text"> and start taking orders on any tablet.</span>
          </div>
        </div>
        <div className="subtext-wrapper">
          <div className="subtext">The easiest part: getting into business!</div>
        </div>
        <div style={{ marginTop: '2%', marginBottom: '2%' }} className="divider"></div>
      </div>
      <div onClick={() => navigate('/quickStart')} class="next-steps-button-container">
        <div className="button-text-wrapper">
          <div class="button-text">Let’s get started!</div>
        </div>
        <div class="button-icon-container">
          <Image style={{ width: '100%' }} src={Next} />
        </div>
      </div>
      <div class="footer-text">(c) by Cloche, 2024</div>
    </div>
  );
}

export default ClocheWelcome;
