import React from 'react';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useLocation } from 'react-router';
import { getTables } from '../../../axios/table';
import { getRunners, updateUser } from '../../../axios/user';
import LoadingSpinner from '../../../components/Modals/LoadingSpinner';
import { notify } from '../../../utils/notify';
import './Tables.css'

const TablesManagement = () => {
	const location = useLocation();
	const [runners, setRunners] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [loadingLabel, setLoadingLabel] = useState('Loading...');
	const [containerHeader, setContainerHeader] = useState();
	const businessId = location?.state?.businessId || JSON.parse(localStorage.getItem('user')).businessId;
	const locationId = location?.state?.locationId || JSON.parse(localStorage.getItem('user')).locationId;

	const [state, setState] = useState([]);

	useEffect(() => {
		getData()
	}, []);

	const getData = async () => {
		setIsLoading(true)
		await Promise.all([
			getTables({ businessId, locationId }),
			getRunners({ locationId }),
		]).then(response => {
			const _state = [response[0].map(table => ({ id: table.id, name: table.name }))]
			_state[0].push({ name: '' })
			let _containerHeader = ['Unassigned tables']

			setRunners(response[1])

			response[1].map((runner, index) => {
				_containerHeader[index + 1] = runner.name
				if (runner.tables) {
					const tables = runner.tables.map(table => {
						const _table = _state[0].find(item => item.id === table)
						_state[0] = _state[0].filter(x => x.id !== table)
						return _table
					})
					tables.push([''])
					_state.push(tables)
				} else _state.push([''])
			})
			setContainerHeader(_containerHeader)
			setState(_state)
			setIsLoading(false)
		}).catch(error => {
			const message = error.response?.data ?? 'Unable to read data from database'
			notify(message, 'error')
			setIsLoading(false)
		})
	}

	const move = (source, destination, droppableSource, droppableDestination) => {
		const sourceClone = Array.from(source);
		const destClone = Array.from(destination);
		const [removed] = sourceClone.splice(droppableSource.index, 1);

		destClone.splice(droppableDestination.index, 0, removed);

		const result = {};
		result[droppableSource.droppableId] = sourceClone;
		result[droppableDestination.droppableId] = destClone;

		return result;
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = result => {
		const { source, destination } = result;
		if (!destination) return

		const sourceId = +source.droppableId;
		const destinationId = +destination.droppableId;

		if (sourceId === destinationId) {
			const items = reorder(state[sourceId], source.index, destination.index);
			const newState = [...state];
			newState[sourceId] = items;
			setState(newState);
		} else {
			const result = move(state[sourceId], state[destinationId], source, destination);
			const newState = [...state];
			newState[sourceId] = result[sourceId];
			newState[destinationId] = result[destinationId];

			setState(newState.filter(group => group.length));
		}
	}

	const saveTableAssignment = async () => {
		setLoadingLabel('Saving table assignment')
		setIsLoading(true)
		const _runners = []

		runners.map((runner, index) => {
			let tables = []
			state[index + 1].map(item => {
				if (item && item.id) tables.push(item.id)
			})
			_runners.push({
				...runner,
				tables
			})
		})

		await Promise.all(_runners.map(runner => {
			if (runner) {
				return updateUser(runner)
			}
		})).then(() => {
			notify(`Tables assigned successfully`, 'success')
			setIsLoading(false)
		}).catch(error => {
			notify('Error saving data', 'error')
			console.log(error);
			setIsLoading(false)
		})
	};

	return (
		<>
			<LoadingSpinner
				isLoading={isLoading}
				label={loadingLabel}
			/>
			<div className='main'>
				<div className='tableManagemnetHeader'>Tables Management</div>
				<div className='tableDragDropContext'>
					<DragDropContext onDragEnd={onDragEnd} >
						{state?.map((el, ind) => (
							<Droppable key={ind} droppableId={`${ind}`} direction="horizontal">
								{(provided, snapshot) => (
									<div className={ind === 0 ? 'tables' : 'runners'}>
										{containerHeader[ind]}
										<div
											className={`tableDroppableList
											${snapshot.isDraggingOver && 'isDraggingOver'} `}
											ref={provided.innerRef}
											{...provided.droppableProps}
										>
											{el.map((item, index) => (
												<>
													{item?.id &&
														<Draggable
															key={item.id}
															draggableId={item.id}
															index={index}
															className='row1'
														>
															{(provided, snapshot) => (
																<div
																	title={item.name}
																	className={`tableDraggableItem ${snapshot.isDragging && 'isDragging'} `}
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																>
																	{item.name.length > 7 ? `${item.name.substring(0, 7)}...` : item.name}
																</div>
															)}
														</Draggable>
													}
												</>
											))}
											{provided.placeholder}
										</div>
									</div>
								)}
							</Droppable>
						))}
					</DragDropContext>
				</div>
				<div className='row detail-row center'>
					<button
						className='btn-lg btn-confirm m-3'
						onClick={saveTableAssignment}>
						Save
					</button>
				</div>
			</div>
		</>
	);
};

export default TablesManagement;
