import React from 'react';
import PropTypes from 'prop-types';
import { updateBusinessStatus } from '../../../axios/business';
import { BusinessStatus } from '../../../screens/Plataform/Business/BusinessStatus';

const { userType = null, businessId = null } = JSON.parse(localStorage.getItem('user')) || {};
const RedirectPopUp = ({ stripeUrl }) => {

  const handleLinkClick = async (event) => {
    event.preventDefault();

    if (!businessId || !userType) {
      console.error('No user type or business ID found');
      return;
    }

    try {
      const response = await updateBusinessStatus({ businessId, status: userType === 'superAdmin' ? BusinessStatus.APPROVED : BusinessStatus.PENDING });

      window.open(stripeUrl, "_blank");
    } catch (error) {
      console.error('Failed to update business status:', error);
    }
  }

  return (
    <div className="pop-up-modal">
      <div className="pop-up-container">
        <h2>Almost there!</h2>
        <p>Please proceed to Stripe to complete missing payment fields. Thank you.</p>
        <a href={stripeUrl} onClick={handleLinkClick} target="_blank" rel="noopener noreferrer">{stripeUrl}</a>
      </div>
    </div>
  );
};

RedirectPopUp.propTypes = {
  stripeUrl: PropTypes.string.isRequired,
};

export default RedirectPopUp;
