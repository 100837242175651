import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { BsCheckCircleFill } from 'react-icons/bs';
import { GlobalColors } from '../../assets/GlobalColors';
import  customImages   from '../../utils/customImages.js';

const OrderPaymentItem = () => {
    
  return (
    <Row
      style={{
        width: '100%',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '10px',
        borderBottom: `1px solid ${GlobalColors.BORDER_BOTTOM_GRAY_2}`,
      }}>
      
      <Col
        style={{
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '80%',
          fontFamily: 'Manrope',
          fontSize: '14px',
          fontWeight: 700,
          fontStyle: 'normal',
          color: `${GlobalColors.BLACK}`,
        }}>
        <div>
          <img alt='moneycash' src={customImages.moneyCash} />
          &nbsp; Request physical payment
        </div>
      </Col>
      <Col
        xs={1}
        style={{
          color: `${GlobalColors.CLOCHE_RED}`,
          marginLeft: '8px',
          alignContent: 'inline',
        }}
      >
        <BsCheckCircleFill />
      </Col>
    </Row>
  );
};

export default OrderPaymentItem;
